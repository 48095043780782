import React from 'react'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'
import { i18n } from '../../utils/translates/i18n'

/**
 * props:
 * - data
 * - onEditClick
 */
export default function OrderRow({ o, editOrder, rule }) {
  return (
    <>
      <tr>
        <td>{o.id}</td>
        <td>{FormateDate(o.createdAt, true)}</td>
        <td>
          <a className={`btn-default btn-sm m-0 p-0`} href="#!">
            <span className="fw-bold">{o.user?.nick?.toUpperCase()}</span>

            <p style={{ whiteSpace: 'pre-line' }}>
              <small>{o.user?.name}</small>
            </p>
          </a>
        </td>
        <td>
          {i18n.t(o.type)} <br />
          <div className={`badge bg-${o.statuses?.bg}`}>{i18n.t(o.statuses.status)}</div>
        </td>
        <td>{FormateFiatCurrency(o.total)}</td>
        <td>{FormateFiatCurrency(o.remaining)}</td>
        <td>{o.payments?.length > 0 && o.payments?.filter(f => f.cryptoSymbol === 'eur').length > 0 ? '€' : ''}</td>
        <td>
          {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
            <>
              {o.status === 1 || o.status === 2 ? (
                <button
                  type="button"
                  className={`btn btn-${o.statuses?.bg} btn-sm me-3`}
                  title="Edit this Order"
                  data-bs-toggle="modal"
                  data-bs-target="#modalOrder"
                  onClick={() => editOrder(o)}>
                  <i className="fas fa-dollar me-2"></i> {i18n.t('pay')}
                </button>
              ) : (
                <></>
              )}
            </>
          )}
          {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
            <>
              {o.payments?.length ? (
                <button
                  type="button"
                  className={`btn btn-primary btn-sm`}
                  title="Edit this Order"
                  data-bs-toggle="modal"
                  data-bs-target="#modalPayments"
                  onClick={() => editOrder(o)}>
                  <i className="fas fa-eye me-2"></i> {`${i18n.t('form_of_payment')}`}
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </td>
      </tr>
    </>
  )
}
