/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { i18n } from '../../utils/translates/i18n'
import LoaderPage from '../../utils/LoaderPage'
import { getRaffels, addRaffel, editRaffel, addPrize, getTicketsByRaffleId } from '../../services/RaffelsService'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { FormateDate } from '../../utils/CustomFormatations'
import ModalRaffel from './modalRaffel'

export default function Raffels() {
  const DEFAULT_DATA = {
    id: 0,
    lotteryName: '',
    contest: '',
    priceConsultant: 1,
    priceConsumer: 2,
    maxTickets: 10000,
    drawDate: '',
    drawHours: 0,
    drawMinutes: 0,
    drawDue: '',
    dueHours: 0,
    dueMinutes: 0,
    prizes: [],
  }

  const defaultLocation = useLocation()
  const history = useHistory()

  const [show, setShow] = useState(false)
  const [raffels, setRaffels] = useState([])
  const [newRaffel, setNewRaffel] = useState(DEFAULT_DATA)
  const [modalAddRaffel, setModalAddRaffel] = useState(false)
  const [modalAddPrize, setModalAddPrize] = useState(false)
  const [modalCloseRaffel, setModalCloseRaffel] = useState(false)
  const [resultRaffel, setResultRaffel] = useState('')
  const [ticketsFilter, setTicketsFilter] = useState([])
  const [ticketsView, setTicketsView] = useState([])

  useEffect(() => {
    if (resultRaffel)
      setTicketsFilter(() =>
        ticketsView.filter(
          tf =>
            tf.number.toString().padStart((parseInt(newRaffel.maxTickets) - 1).toString().length, '0') ===
            resultRaffel.toString().padStart((parseInt(newRaffel.maxTickets) - 1).toString().length, '0')
        )
      )
  }, [resultRaffel])

  const doCloseRaffle = async () => {
    setShow(false)
    const success = await editRaffel({ ...newRaffel, result: resultRaffel, status: 4 })
    if (success.status) {
      doGetRaffels()
      toast.success(i18n.t(success.msg))
      setNewRaffel(DEFAULT_DATA)
      setModalCloseRaffel(false)
      setShow(true)
    }
  }

  const [postPrize, setPostPrize] = useState({
    raffelId: newRaffel?.id,
    prizeName: '',
    prizeAmount: 1,
    prizeDescription: '',
  })

  const doAddPrize = async () => {
    setShow(false)
    // console.log({ postPrize })
    const success = await addPrize(postPrize)
    if (success.status) {
      doGetRaffels()
      if (postPrize.id)
        setNewRaffel(p => ({ ...p, prizes: p.prizes?.map(p => (p.id === postPrize.id ? success.prize : p)) }))
      else setNewRaffel(p => ({ ...p, prizes: p.prizes?.length > 0 ? [...p.prizes, success.prize] : [success.prize] }))
      toast.success(i18n.t(success.msg))
      setPostPrize({ raffelId: newRaffel?.id, prizeName: '', prizeDescription: '', prizeAmount: 1 })
      setModalAddPrize(false)
      setShow(true)
    }
  }

  // PARA PAGINAÇÃO
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [ordenation, setOrdenation] = useState('desc')
  const [status, setStatus] = useState('')

  const doGetRaffels = async () => {
    const success = await getRaffels(search, page, lim, status, ordenation)
    if (success.status) {
      setRaffels(success.list.rows)
      setCount(success.list.count)
      setShow(true)
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    doGetRaffels()
  }, [page, lim, status, ordenation])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const openModalAdd = data => {
    setNewRaffel(DEFAULT_DATA)
    if (data.id) setNewRaffel(data)
    setModalAddRaffel(true)
    // console.log(newRaffel)
  }

  const openModalClose = async data => {
    const response = await getTicketsByRaffleId(data.id)
    if (response.status) {
      setTicketsView(response.tickets)
      setNewRaffel(data)
      setModalCloseRaffel(true)
    }
  }

  const doAddRaffle = async () => {
    setShow(false)
    if (
      !newRaffel.lotteryName ||
      !newRaffel.contest ||
      !newRaffel.priceConsultant ||
      !newRaffel.priceConsumer ||
      !newRaffel.maxTickets ||
      !newRaffel.countryId ||
      !newRaffel.timezone ||
      !newRaffel.drawDate ||
      !newRaffel.drawHours ||
      !newRaffel.drawMinutes ||
      !newRaffel.drawDue ||
      !newRaffel.dueHours ||
      !newRaffel.dueMinutes ||
      !newRaffel.regulation
    ) {
      // console.log(newRaffel)
      setShow(true)
      toast.error(i18n.t('required_data_not_sent'))
      return
    } else {
      const success = await addRaffel(newRaffel)
      if (success.status) {
        // setModalAddRaffel(false)
        setNewRaffel(success.raffle)
        toast.success(i18n.t(success.msg))
        doGetRaffels()
      } else {
        setShow(true)
        toast.error(i18n.t(success.msg))
      }
    }
  }

  const doEditRaffle = async () => {
    setShow(false)
    const post = {
      id: newRaffel.id,
      lotteryName: newRaffel.lotteryName,
      contest: newRaffel.contest,
      priceConsultant: newRaffel.priceConsultant,
      priceConsumer: newRaffel.priceConsumer,
      maxTickets: newRaffel.maxTickets,
      countryId: newRaffel.countryId,
      timezone: newRaffel.timezone,
      drawDate: newRaffel.drawDate,
      drawHours: newRaffel.drawHours?.toString(),
      drawMinutes: newRaffel.drawMinutes?.toString(),
      drawDue: newRaffel.drawDue,
      dueHours: newRaffel.dueHours?.toString(),
      dueMinutes: newRaffel.dueMinutes?.toString(),
      regulation: newRaffel.regulation,
    }

    const success = await editRaffel(post)
    if (success.status) {
      // setModalAddRaffel(false)
      toast.success(i18n.t(success.msg))
      doGetRaffels()
    } else {
      setShow(true)
      toast.error(i18n.t(success.msg))
    }
  }

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        {!show ? (
          <LoaderPage />
        ) : (
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('raffels')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="row w-100">
                <div className="col-lg-3 mb-3">
                  <button className="btn btn-primary w-100" onClick={openModalAdd}>{`${i18n.t('new_raffle')}`}</button>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="input-group me-2">
                    <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                      <option value={''}>{`${i18n.t('status')} (${i18n.t('all')})`}</option>
                      <option value={1}>{i18n.t('waiting')}</option>
                      <option value={12}>{i18n.t('affected')}</option>
                      <option value={4}>{i18n.t('confirmed')}</option>
                      <option value={18}>{i18n.t('awarded')}</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={ordenation} onChange={e => setOrdenation(e.target.value)}>
                    <option className="bg-light" value="asc">
                      {i18n.t('olds')}
                    </option>
                    <option className="bg-light" value="desc">
                      {i18n.t('recents')}
                    </option>
                  </select>
                </div>
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={lim} onChange={e => setLims(e.target.value)}>
                    <option className="bg-light" value="5">
                      5 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="10">
                      10 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="25">
                      25 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="50">
                      50 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="100">
                      100 {i18n.t('registers')}
                    </option>
                  </select>
                </div>
              </div>
            </Pagination>
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200">{i18n.t('id')}</th>
                    <th className="border-gray-200">{i18n.t('lottery')}</th>
                    <th className="border-gray-200">{i18n.t('prizes')}</th>
                    <th className="border-gray-200">{i18n.t('draw_date')}</th>
                    <th className="border-gray-200">{i18n.t('remaining')}</th>
                    <th className="border-gray-200">{i18n.t('result')}</th>
                    <th className="border-gray-200">{i18n.t('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {raffels?.map((r, i) => (
                    <tr key={i}>
                      <td>
                        <span className="me-2">{r.id}</span>
                        <span className={`badge text-bg-${r.statuses?.bg}`}>{i18n.t(r.statuses?.status)}</span>
                      </td>
                      <td>
                        <div>{r.lotteryName}</div>
                        <small>{r.contest}</small>
                      </td>
                      <td className="d-flex flex-column align-items-start gap-1">
                        {r.prizes?.map(p => (
                          <div className="badge text-bg-light border border-info shadow">{p.prizeName}</div>
                        ))}
                      </td>
                      <td>{FormateDate(r.drawDate, true, true)}</td>
                      <td>{r.rests}</td>
                      <td>{r.result}</td>
                      <td className="d-flex gap-2">
                        <button className="btn btn-sm btn-secondary" onClick={() => openModalAdd(r)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        {[12].includes(r.status) && (
                          <button className="btn btn-sm btn-success" onClick={() => openModalClose(r)}>
                            <i className="fa fa-check"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </main>
      {modalCloseRaffel && (
        <Modal show={modalCloseRaffel} onHide={() => setModalCloseRaffel(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{`${i18n.t('close')} ${i18n.t('raffel')}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-2">
              <label>{i18n.t('result')}:</label>
              <input
                type="text"
                className="form-control"
                value={resultRaffel}
                onChange={e => setResultRaffel(e.target.value.replace(/[^0-9]/g, ''))}
              />
            </div>
            <div className="card">
              <div className="card-header fs-5 text-warning bg-dark p-1 text-center">{i18n.t('awards')}</div>
              {ticketsFilter.length > 0 ? (
                ticketsFilter.map((t, i) => (
                  <div className="card-body" key={i}>
                    <div>
                      {i18n.t('number')}:
                      {t.number.toString().padStart((parseInt(newRaffel.maxTickets) - 1).toString().length, '0')}
                    </div>
                    <div>
                      {i18n.t('nick')}: {t.consumer?.nick}
                    </div>
                    <div>
                      {i18n.t('email')}: {t.consumer?.email}
                    </div>
                    <div>
                      {i18n.t('phone')}: +{t.consumer?.ddi} {t.consumer?.phone}
                    </div>
                  </div>
                ))
              ) : (
                <div className="alert alert-info">{i18n.t('no_tickets')}</div>
              )}
              <div className="card-footer">
                <button className="btn btn-primary" onClick={doCloseRaffle}>
                  <i className="fa fa-check"></i> {i18n.t('confirm')} {i18n.t('close')}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {modalAddRaffel && (
        <ModalRaffel
          modalAddRaffel={modalAddRaffel}
          setModalAddRaffel={setModalAddRaffel}
          newRaffel={newRaffel}
          setNewRaffel={setNewRaffel}
          doAddRaffle={doAddRaffle}
          doEditRaffle={doEditRaffle}
          doGetRaffels={doGetRaffels}
          setModalAddPrize={setModalAddPrize}
          setPostPrize={setPostPrize}
        />
      )}

      {modalAddPrize && (
        <Modal size="lg" show={modalAddPrize} onHide={() => setModalAddPrize(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t(postPrize.id ? 'edit_prize' : 'add_prize')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row border border-secondary rounded p-2">
              <div className="col-12 mb-2">
                <div className="input-group bg-secondary rounded text-dark">
                  <div className="p-2">{i18n.t('prize_name')}</div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={i18n.t('prize_name')}
                    value={postPrize.prizeName}
                    onChange={e => setPostPrize(p => ({ ...p, prizeName: e.target.value }))}
                    onBlur={e =>
                      setPostPrize(p => ({
                        ...p,
                        raffelId: newRaffel.id,
                        prizeName: e.target.value.toUpperCase(),
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="input-group bg-secondary rounded text-dark">
                  <div className="p-2">{i18n.t('amount')}</div>
                  <input
                    type="number"
                    className="form-control"
                    min={1}
                    step={1}
                    placeholder={i18n.t('prize_name')}
                    value={postPrize.prizeAmount}
                    onChange={e => setPostPrize(p => ({ ...p, prizeAmount: e.target.value }))}
                    onBlur={e => setPostPrize(p => ({ ...p, prizeAmount: e.target.value < 1 ? 1 : e.target.value }))}
                  />
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="input-group bg-secondary rounded text-dark">
                  <div className="p-2">{i18n.t('description')}</div>
                  <textarea
                    className="form-control"
                    rows="10"
                    placeholder={i18n.t('description')}
                    value={postPrize.prizeDescription}
                    style={{ whiteSpace: 'pre-line' }}
                    onChange={e => setPostPrize(p => ({ ...p, prizeDescription: e.target.value }))}
                  />
                </div>
              </div>
              <div className="col-12">
                <button type="button" className="btn btn-secondary" onClick={doAddPrize}>
                  {i18n.t('save')}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}
