/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu/Menu'
import { i18n } from '../../utils/translates/i18n'
import { getActiveUsersParam } from '../../services/UsersService'
import { sendCredDebt } from '../../services/CredDebtService'
import LoaderPage from '../../utils/LoaderPage'
import { toast } from 'react-toastify'
import { decrypt } from '../../utils/crypto'

function CredDeb() {
  const token = sessionStorage.getItem('token')
  const rule = decrypt(sessionStorage.getItem('rule'))
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [dataPost, setDataPost] = useState({ user: '', type: '', amount: 0 })
  const [show, setShow] = useState(false)

  const doGetUsers = async () => {
    const success = await getActiveUsersParam(search)
    if (success.length > 0) setUsers(success)
  }

  const onInputChange = e => setDataPost(p => ({ ...p, [e.target.id]: e.target.value }))

  const doSave = async () => {
    setShow(false)
    const post = {
      ...dataPost,
      user: dataPost.user.split(' | ')[0],
    }
    if (!!parseInt(post.user)) {
      const success = await sendCredDebt(token, post)
      if (success.status) toast.success(`Credito / Débito enviado com sucesso para ${dataPost.user.split(' | ')[1]}`)
      setDataPost({ user: '', type: '', amount: 0 })
      setShow(true)
    } else {
      setShow(true)
      toast.error('Escolha um usuário na lista')
    }
  }

  useEffect(() => {
    if (search && search.length > 2) doGetUsers()
    setShow(true)
  }, [search])

  return (
    <React.Fragment>
      <Menu />
      {['developer', 'finance'].includes(rule) && (
        <main className="content">
          {!show ? (
            <LoaderPage />
          ) : (
            <>
              <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <div className="h3">{i18n.t('cred_deb')}</div>
                  </div>
                </div>
                <div className="card card-body border-0 shadow mb-5">
                  <div className="h5">{i18n.t('cred_deb_title')}</div>
                  <div className="row">
                    <div className="col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('user')}</span>
                        <input
                          list="userlist"
                          value={search}
                          onChange={e => setSearch(e.target.value)}
                          className="form-control"
                          placeholder="digite"
                        />
                        {users.length > 0 && (
                          <select className="form-control" id="user" value={dataPost.user} onChange={onInputChange}>
                            <option value="">-- {i18n.t('choose')} --</option>
                            {users.map((u, i) => (
                              <option key={i} value={u.id}>{`${u.nick} (${u.name})`}</option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md">
                    <div className="input-group">
                      <span className="btn btn-primary">{i18n.t('user')}</span>
                      <select id="user" value={dataPost.user} onChange={onInputChange} className="form-select">
                        <option value="">-- {i18n.t('choose')} --</option>
                        {users && users.map((u, i) => <option key={i} value={u.id}>{`${u.nick} - ${u.name}`}</option>)}
                      </select>
                    </div>
                  </div> */}
                    <div className="col-md">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('type')}</span>
                        <select id="type" value={dataPost.type} onChange={onInputChange} className="form-select">
                          <option value="">-- {i18n.t('choose')} --</option>
                          <option value="12" className="text-success fw-bold">
                            {i18n.t('cred_saldo_disp')}
                          </option>
                          <option value="56" className="text-success fw-bold">
                            {i18n.t('cred_saldo_lotterProCoin')}
                          </option>
                          <option value="52" className="text-success fw-bold">
                            {i18n.t('add_balance_games')}
                          </option>
                          <option value="41" className="text-success fw-bold">
                            {i18n.t('consultant_indication')}
                          </option>
                          <option value="5" className="text-success fw-bold">
                            {i18n.t('cred_binario')}
                          </option>
                          {/* <option value="63" className="text-success fw-bold">
                            {i18n.t('add_balance_awards')}
                          </option> */}
                          <option value="16" className="text-danger fw-bold">
                            {i18n.t('deb_saldo_disp')}
                          </option>
                          <option value="45" className="text-danger fw-bold">
                            {i18n.t('deb_saldo_lotterProCoin')}
                          </option>
                          <option value="55" className="text-danger fw-bold">
                            {i18n.t('deb_balance_games')}
                          </option>
                          <option value="64" className="text-danger fw-bold">
                            {i18n.t('deb_balance_awards')}
                          </option>
                          {/* <option value="53">{i18n.t('mensal_activation')}</option> */}
                          {/* <option value="54">{i18n.t('renew_meta')}</option> */}
                          {/* <option value="17">{`${i18n.t('pay')} ${i18n.t('pl_carreira')}`}</option> */}
                          {/* <option value="14">{i18n.t('ajust_limite')}</option> */}
                          {/* <option value="9">{i18n.t('extorno_td')}</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-group">
                        <span className="btn btn-primary">{i18n.t('amount')}</span>
                        <input
                          id="amount"
                          value={dataPost.amount}
                          type="number"
                          onChange={onInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-success text-white"
                        onClick={doSave}
                        disabled={!dataPost.user || !dataPost.type || !dataPost.amount || dataPost.amount <= 0}>
                        <i className="fa fa-check"></i>
                        <span className="ms-2">{i18n.t('send')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <pre>{JSON.stringify(dataPost, null, 2)}</pre> */}
              </div>
            </>
          )}
        </main>
      )}
      {/* <Footer /> */}
    </React.Fragment>
  )
}

export default CredDeb
