import axios from './BaseService'

const API_URL = process.env.REACT_APP_API_URL

export async function doLogin(email, password) {
  const loginUrl = `${API_URL}/login`
  const response = await axios.post(loginUrl, { email, password })
  return response.data
}

export async function validateToken() {
  const validateUrl = `${API_URL}/validateToken`
  const response = await axios.get(validateUrl)
  return response.data
}

export async function doLogout() {
  const logoutUrl = `${API_URL}/logout`
  const response = await axios.post(logoutUrl, {})
  return response.data
}

export async function deactivateTwoFAAdm(id) {
  const endpoint = `${API_URL}/auth/2fa/deactivatetwofaadm`
  const response = await axios.post(endpoint, { id })
  return response.data
}
