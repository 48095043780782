/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { approveOrRejectKyc, getKycs } from '../../services/UsersService'
import { i18n } from '../../utils/translates/i18n'
import { toast } from 'react-toastify'
import { decrypt } from '../../utils/crypto'
import LoaderPage from '../../utils/LoaderPage'

export default function Kyc() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const btnClose = useRef('')

  const defaultLocation = useLocation()
  const history = useHistory()
  const [show, setShow] = useState(false)

  const [viewImage, setViewImage] = useState('')

  const [kycs, setKycs] = useState([])

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [exact, setExact] = useState(false)
  const [ordenation, setOrdenation] = useState('asc')

  const doGetKycs = async () => {
    setShow(false)
    const success = await getKycs(token, page, lim, search, exact, ordenation)
    if (success.status) {
      // console.log(success);
      setCount(success.list.count)
      setKycs(success.list.rows)
      setShow(true)
    } else {
      toast.error(success.error)
      setShow(true)
    }
  }

  useEffect(() => {
    doGetKycs()
  }, [token, page, lim, exact, ordenation])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const doApproveOrRejectKyc = async (id, action) => {
    const success = await approveOrRejectKyc(token, id, action)
    if (success.status) {
      toast.success(success.message)
      doGetKycs()
    } else {
      toast.error(success.error)
    }
  }

  return (
    <React.Fragment>
      <Menu />
      {!show ? (
        <LoaderPage />
      ) : (
        ['developer', 'support', 'manager', 'admlotteries', 'finance'].includes(rule) && (
          <main className="content">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
              <div className="d-block">
                <h2 className="h4">{i18n.t('kyc')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="align-items-center mb-2">
                <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                  <i className="fas fa-search"></i>
                </div>
              </div>
              <div className="input-group me-2 mb-2">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={`${i18n.t('search')} ${i18n.t('user')}`}
                  onBlur={doGetKycs}
                />
                <button className={`btn btn-${exact ? 'success' : 'secondary'}`} onClick={() => setExact(!exact)}>
                  {i18n.t(exact ? 'exact' : 'partiallly')}
                </button>
              </div>
              <select
                className="form-select me-2 mb-2"
                value={ordenation}
                onChange={e => setOrdenation(e.target.value)}>
                <option className="bg-light" value="asc">
                  {i18n.t('olds')}
                </option>
                <option className="bg-light" value="desc">
                  {i18n.t('recents')}
                </option>
              </select>
              <select className="form-select mb-2" value={lim} onChange={e => setLims(e.target.value)}>
                <option className="bg-light" value="5">
                  5 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="10">
                  10 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="25">
                  25 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="50">
                  50 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="100">
                  100 {i18n.t('registers')}
                </option>
              </select>
            </Pagination>
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200">{i18n.t('nick')}</th>
                    <th className="border-gray-200">{i18n.t('front')}</th>
                    <th className="border-gray-200">{i18n.t('back_doc')}</th>
                    <th className="border-gray-200">{i18n.t('selfie')}</th>
                    <th className="border-gray-200">{i18n.t('type')}</th>
                    <th className="border-gray-200">{i18n.t('status')}</th>
                    <th className="border-gray-200">{i18n.t('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {kycs?.map(kyc => (
                    <tr key={kyc.id}>
                      <td className="border-gray-200">
                        <div className="fw-bold fs-5">{kyc.user.nick}</div>
                        <small>{kyc.user.name}</small>
                      </td>
                      <td className="border-gray-200">
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          className="btn btn-light p-0"
                          data-bs-target="#viewModalKyc"
                          onClick={() => setViewImage(`${process.env.REACT_APP_API_URL}/docs/${kyc.front}`)}>
                          <img
                            src={`${process.env.REACT_APP_API_URL}/docs/${kyc.front}`}
                            alt="front"
                            className="img-thumbnail"
                            crossOrigin="anonymous"
                            style={{ maxHeight: '90px' }}
                          />
                        </button>
                      </td>
                      <td className="border-gray-200">
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          className="btn btn-light p-0"
                          data-bs-target="#viewModalKyc"
                          onClick={() => setViewImage(`${process.env.REACT_APP_API_URL}/docs/${kyc.back}`)}>
                          <img
                            src={`${process.env.REACT_APP_API_URL}/docs/${kyc.back}`}
                            alt="back"
                            className="img-thumbnail"
                            crossOrigin="anonymous"
                            style={{ maxHeight: '90px' }}
                          />
                        </button>
                      </td>
                      <td className="border-gray-200">
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          className="btn btn-light p-0"
                          data-bs-target="#viewModalKyc"
                          onClick={() => setViewImage(`${process.env.REACT_APP_API_URL}/docs/${kyc.selfie}`)}>
                          <img
                            src={`${process.env.REACT_APP_API_URL}/docs/${kyc.selfie}`}
                            alt="selfie"
                            className="img-thumbnail"
                            crossOrigin="anonymous"
                            style={{ maxHeight: '90px' }}
                          />
                        </button>
                      </td>
                      <td className="border-gray-200 fs-6">{i18n.t(kyc.type)}</td>
                      <td className={`border-gray-200`}>
                        <span className={`badge fs-6 p-2 px-3 text-bg-${kyc.statuses.bg}`}>
                          {i18n.t(
                            kyc.status == 20 ? 'kyc_pending' : kyc.status == 21 ? 'kyc_rejected' : 'kyc_approved'
                          )}
                        </span>{' '}
                      </td>
                      <td className="d-flex flex-wrap gap-2">
                        {kyc.status == 20 && (
                          <>
                            <button className="btn btn-success" onClick={() => doApproveOrRejectKyc(kyc.id, 'approve')}>
                              <i className="fas fa-check"></i> {i18n.t('approve')}
                            </button>
                            <button className="btn btn-danger" onClick={() => doApproveOrRejectKyc(kyc.id, 'reject')}>
                              <i className="fas fa-ban"></i> {i18n.t('reject')}
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination count={count} />
            </div>
            <div
              className="modal fade"
              id="viewModalKyc"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modalTitleNotify"
              aria-hidden="true">
              <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <p className="modal-title" id="modalTitleNotify">
                      {i18n.t('view')} {i18n.t('kyc')}
                    </p>
                    <button
                      ref={btnClose}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="close"></button>
                  </div>
                  <div className="modal-body text-center">
                    <img
                      src={viewImage}
                      alt="kyc"
                      className="img-thumbnail"
                      crossOrigin="anonymous"
                      style={{ maxHeight: '80vh' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        )
      )}
    </React.Fragment>
  )
}
