/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { i18n } from '../../utils/translates/i18n'
import { toast } from 'react-toastify'
import { decrypt } from '../../utils/crypto'
import LoaderPage from '../../utils/LoaderPage'
import { getAllPointsOnPeriod } from '../../services/BinaryService'

export default function Points() {
  const rule = decrypt(sessionStorage.getItem('rule'))

  const defaultLocation = useLocation()
  const history = useHistory()
  const [show, setShow] = useState(false)

  // PARA PAGINAÇÃO
  const [list, setList] = useState([])
  const [start, setStart] = useState(new Date('2023-11-01').toISOString().split('T')[0])
  const [end, setEnd] = useState(new Date().toISOString().split('T')[0])
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [exact, setExact] = useState(false)
  const [ordenation, setOrdenation] = useState('asc')

  const doGetAllPointsOnPeriod = async () => {
    setShow(false)
    const success = await getAllPointsOnPeriod(start, end, page ?? 1, lim, search, exact, ordenation)
    if (success.status) {
      // console.log(success);
      setCount(success.list.count)
      setList(success.list.rows)
      setShow(true)
    } else {
      toast.error(success.error)
      setShow(true)
    }
  }

  useEffect(() => {
    doGetAllPointsOnPeriod()
  }, [page, lim, exact, ordenation])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  return (
    <>
      <Menu />
      {!show ? (
        <LoaderPage />
      ) : (
        ['developer', 'support', 'manager', 'admlotteries', 'finance'].includes(rule) && (
          <main className="content">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
              <div className="d-block">
                <h2 className="h4">{i18n.t('accumulated_points')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="align-items-center mb-2">
                <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                  <i className="fas fa-search"></i>
                </div>
              </div>
              <div className="input-group me-2 mb-2">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={`${i18n.t('search')} ${i18n.t('user')}`}
                  onBlur={doGetAllPointsOnPeriod}
                />
                <button className={`btn btn-${exact ? 'success' : 'secondary'}`} onClick={() => setExact(!exact)}>
                  {i18n.t(exact ? 'exact' : 'partiallly')}
                </button>
              </div>
              <div className="input-group mb-2">
                <span className="input-group-text text-bg-dark">{i18n.t('start')}</span>
                <input
                  type="date"
                  className="form-control"
                  value={start}
                  onChange={e => setStart(e.target.value)}
                  placeholder={`${i18n.t('start')} ${i18n.t('date')}`}
                  onBlur={doGetAllPointsOnPeriod}
                />
              </div>
              <div className="input-group mb-2">
                <span className="input-group-text text-bg-dark">{i18n.t('to')}</span>
                <input
                  type="date"
                  className="form-control"
                  value={end}
                  onChange={e => setEnd(e.target.value)}
                  placeholder={`${i18n.t('end')} ${i18n.t('date')}`}
                  onBlur={doGetAllPointsOnPeriod}
                />
              </div>
              <select
                className="form-select me-2 mb-2"
                value={ordenation}
                onChange={e => setOrdenation(e.target.value)}>
                <option className="bg-light" value="asc">
                  {i18n.t('ascent')}
                </option>
                <option className="bg-light" value="desc">
                  {i18n.t('descent')}
                </option>
              </select>
              <select className="form-select mb-2" value={lim} onChange={e => setLims(e.target.value)}>
                <option className="bg-light" value="5">
                  5 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="10">
                  10 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="25">
                  25 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="50">
                  50 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="100">
                  100 {i18n.t('registers')}
                </option>
              </select>
            </Pagination>
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200">{i18n.t('nick')}</th>
                    <th className="border-gray-200">{i18n.t('left')}</th>
                    <th className="border-gray-200">{i18n.t('right')}</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <tr key={index}>
                      <td>{item.nick}</td>
                      <td>{item.left}</td>
                      <td>{item.right}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination count={count} />
            </div>
          </main>
        )
      )}
    </>
  )
}
