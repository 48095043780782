/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import { getAllTutorials } from "../../services/TutorialService";
import { i18n } from "../../utils/translates/i18n";
import TutorialRow from "./TutorialRow";

export default function Tutorial() {
  const [tutorials, setTutorials] = useState([]);
  const token = sessionStorage.getItem("token");

  const getTutorials = async () => {
    const tutorials = await getAllTutorials(token);
    if (tutorials) {
      setTutorials(tutorials);
    }
  };

  useEffect(() => {
    getTutorials();
  }, []);

  return (
    <React.Fragment>
      <Menu />
      <div className="content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h2 className="h4">{i18n.t("tutorial")}</h2>
            </div>
          </div>

          <div className="align-items-center">
            <Link className="btn btn-primary" to="/tutorials/create">
              <div style={{ whiteSpace: "nowrap" }}>
                <i className="fas fa-plus me-2"></i>
                {i18n.t("create")} {i18n.t("tutorial")}
              </div>
            </Link>
          </div>

          <div className="card card-body bgame-0 shadow table-wrapper table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="bgame-gray-200">{i18n.t("name")}</th>
                  <th className="bgame-gray-200">{i18n.t("video_url")}</th>
                  <th className="bgame-gray-200">{i18n.t("description")}</th>
                  <th className="bgame-gray-200">{i18n.t("language")}</th>
                  <th className="bgame-gray-200">{i18n.t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {tutorials?.map((tutorial, i) => (
                  <TutorialRow key={i} tutorial={tutorial} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
