const messages = {
  es: {
    translations: {
      a_crase: 'a',
      about: 'Acerca de Nosotros',
      above_the_same: 'Arriba exactamente igual',
      action: 'Acción',
      actionTemplates: 'Plantillas de Acciones',
      activate_date: 'Fecha de activación',
      active_automations: 'Automatizaciones Activas',
      active_connections: 'Conexiones Activas',
      active_monitors: 'Monitores Activos',
      active_users: 'Usuarios Activos',
      ad: 'Anuncio',
      add: 'Agregar',
      add_option: 'Agregar opción',
      add_payment: 'Agregar Pago',
      add_question: 'Agregar Pregunta',
      add_receipt: 'Agregar Recibo',
      add_wallet: 'Agregar Billetera',
      address: 'Dirección',
      admin: 'Administración',
      advance: 'Avanzar',
      affected: 'Afectado',
      after: 'Después',
      air_transport: 'Transporte Aéreo',
      ajust_limite: 'Ajuste de Límite',
      ajust_saldo: 'Ajuste de Saldo',
      alert: 'Alerta',
      alerts: 'Alertas',
      all: 'Todos',
      all_departments: 'Todos los Departamentos',
      allies_of: 'Aliados de la',
      allRightsReserved: 'Todos los derechos reservados.',
      almost_there: 'Casi allí',
      already_offset: 'Ya Compensado',
      already_registered: '¿Ya está registrado? Haga clic aquí e inicie sesión',
      amount: 'Cantidad',
      amount_above_balance: 'Saldo insuficiente',
      and: 'y',
      and_more: 'y más',
      answer: 'Respuesta',
      ao: 'a',
      approve: 'Aprobar',
      April: 'abril',
      asc: 'Ascendente',
      at: 'en',
      August: 'Agosto',
      automations: 'Automatizaciones',
      awaiting_email_confirmation: '¡Esperando confirmación por correo electrónico!',
      b_order: 'Orden',
      b_orders: 'Órdenes',
      back: 'Volver',
      bairroInteresse: 'Barrio',
      balance: 'Saldo',
      balance_available: 'Saldo Disponible',
      banned: 'Prohibido',
      before_contact: 'Antes de ponerse en contacto con nosotros',
      binary: 'Dual Point',
      birth: 'Nacimiento',
      blind_man: 'Anulado',
      body_monthly:
        'Su Cuota Mensual venció y su BOT TRADER fue desactivado, por favor, pague la Orden de Pago y vuelva a disfrutar de sus beneficios',
      bonus_saldo_trader: 'Bono Saldo Trader',
      book_health: 'Book Health',
      but_possible_register:
        'Aún así, es posible hacer su registro, pero no estará relacionado con ningún amigo indicador.',
      buy_volume: 'Volumen de Compra',
      buyer: 'Comprador',
      by: 'por',
      bye: 'Hasta luego',
      call: 'Llamada',
      call_to: 'Llamar a',
      calls: 'Apoyo',
      cancel: 'Cancelar',
      canceled: 'Cancelado',
      career_plan: 'Plan de Carrera',
      casa_verde_amarela: 'Casa Verde Amarilla',
      cash_payment: 'Pago en efectivo',
      cash_payment_return: 'Devolución de pago en efectivo',
      cash_receipt: 'Recibo en efectivo',
      change_indicant: 'Cambiar Indicador',
      change_photo: 'Cambiar Foto',
      change_plan: 'Cambiar plan',
      check_payment: 'Pago con cheque',
      check_payment_return: 'Devolución de pago con cheque',
      check_receipt: 'Recibo con cheque',
      choose: 'Elegir',
      cidadeInteresse: 'Ciudad',
      city: 'Ciudad',
      claims: 'Reclamaciones',
      clean: 'Limpiar',
      click_and_know: 'Haga clic y conozca',
      click_to_copy_the_wallet: 'Haga clic para copiar la billetera',
      client: 'Cliente',
      clients: 'Clientes',
      close: 'Cierre',
      cnh: 'Licencia de conducir',
      cnhDate: 'Fecha de emisión de la Licencia de conducir',
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ Inválido',
      coin: 'Moneda',
      color: 'Color',
      commercial: 'Comercial',
      company: 'Nombre de la Empresa',
      complement: 'Complemento',
      comporRenda: 'Va a componer ingresos con alguien',
      compRenda: 'Tipo de Comprobación de Ingresos',
      confirm_approve: 'Confirmar aprobación',
      confirm_delete: 'Confirmar eliminación',
      confirm_new_password: 'Confirmar Nueva Contraseña',
      confirm_new_password_security: 'Confirmar nueva contraseña de seguridad',
      confirm_password: 'Confirmar Contraseña de Acceso',
      confirm_password_security: 'Confirmar contraseña de seguridad',
      confirm_your_new_password: 'Vuelva a ingresar su nueva contraseña',
      confirmed: 'Confirmado',
      confirmed_email: 'Email confirmado',
      congratulations: '¡Felicidades!',
      contact: 'Contacto',
      contract: 'Contrato',
      copied: 'Copiado',
      copy: 'Copiar',
      copy_success: 'Copiado exitosamente',
      correspondences: 'Correspondencias',
      countries: 'Países',
      country: 'País',
      cpf: 'CPF',
      cpf_invalid: 'CPF Inválido',
      create_order_success: '¡Pedido creado con éxito!',
      created: 'Registrado en',
      cred: 'Crédito',
      cred_binario: 'Crédito Dual Point',
      cred_deb: 'Créditos/Débitos',
      cred_game_donate: 'Crédito Game Donate',
      cred_ind_direta: 'Crédito de Indicación Directa',
      cred_ind_indireta: 'Crédito de Indicación Indirecta',
      cred_mandala: 'Crédito de la Mandala',
      cred_pagar_com_saldo: 'Crédito Pagar con Saldo',
      cred_pontos: 'Puntos de crédito',
      cred_recb_trader: 'Comerciante receptor de crédito',
      cred_renovacao: 'Crédito de renovación',
      cred_saldo_disp: 'Saldo disponible de crédito',
      cred_upgrade: 'Crédito de actualización',
      credit_card_payment: 'Pago con tarjeta de crédito',
      credit_card_payment_return: 'Devolución de pago con tarjeta de crédito',
      credit_card_receipt: 'recibo de tarjeta de crédito',
      current: 'Actual',
      current_password: 'Contraseña actual',
      current_wallet: 'Portafolio actual',
      dashboard: 'Panel de control',
      data_added: 'Datos agregados',
      data_saved: 'Datos guardados',
      datas: 'Datos',
      date: 'Fecha',
      date_first_installment: 'Fecha del primer pago',
      ddi: 'Código de país',
      deb_de_trader: 'Deuda del comerciante',
      deb_game_donate: 'Juego Donar Débito',
      deb_saldo: 'Balance de débito',
      deb_saldo_disp: 'Saldo disponible deudor',
      deb_saldo_pendent: 'Débito de saldo pendiente',
      deb_val_indev: 'Débito de monto indebido',
      debit_card_payment: 'Pago con tarjeta de débito',
      debit_card_payment_return: 'Devolución de pago con tarjeta de débito',
      debit_card_receipt: 'Recibo con tarjeta de débito',
      debt: 'Deuda',
      debt_td: 'Débito',
      December: 'Diciembre',
      decImpRenda: 'declarar el impuesto sobre la renta',
      deleted_document: 'El documento fue eliminado de su registro',
      department: 'Departamento',
      dependents: 'Dependientes',
      desc: 'Hacia abajo',
      description: 'Descripción',
      details: 'Detalles',
      dev_econom: 'Desarrollo economico',
      developer: 'Desarrollador',
      directly_indicated: 'Indicado directamente',
      directly_indicateds: 'Referido directamente',
      directs: 'Directo',
      district: 'Barrio / distrito',
      divorced: 'Divorciado',
      doa_men_receb: 'Donación mensual de plataforma recibida',
      doacao_td: 'Donación',
      doctorate_complete: 'Doctorado - Completo',
      doctorate_incomplete: 'Doctorado - Incompleto',
      document: 'Documento',
      document_already_registered: 'Documento ya registrado',
      documents: 'Documentos',
      donate: 'Donar',
      donor: 'Donante',
      dont_know_zip: '¿No sabes el código postal? Haga clic aquí',
      dou_fe: 'Confirmo que la información anterior es correcta.',
      downloads: 'Descargar archivos',
      drop_images: 'Haz clic o arrastra las imágenes aquí.',
      due_date: 'Madurez',
      edit: 'Para editar',
      edit_option: 'Opción de edición',
      edit_question: 'Editar pregunta',
      email: 'Correo electrónico',
      email_already_registered: 'correo electrónico ya registrado',
      email_and_or_password_wrong: 'Correo electrónico y/o contraseña incorrectos!',
      email_for_login: 'Este correo electrónico será su inicio de sesión',
      email_or_password_wrong: 'Correo o contraseña incorrectos',
      employee: 'Empleado',
      employees: 'Empleados',
      empty: 'Vacío',
      enter_your_new_password: 'Introduzca su nueva contraseña',
      entrada: '¿Tiene un valor de entrada? En caso afirmativo, ¿qué valor?',
      entries_until_today: 'Entradas hasta la fecha',
      error_confirm_password: 'La confirmación y la nueva contraseña deben ser iguales.',
      error_email: 'Formato de correo inválido',
      error_password: 'Contraseña incorrecta',
      error_phone: 'Numero de telefono invalido',
      estadoInteresse: 'estado',
      estimate: 'Estimado',
      exchange: 'Reemplazo',
      expired: 'Venció',
      extorno_td: 'Inversión',
      extra: 'Extra',
      extract: 'Extracto',
      extracts: 'Extractos',
      fantasy: 'Fantasía',
      fatherName: 'Nombre del Padre',
      February: 'Febrero',
      fee: 'Cargo por retiro',
      feedback: 'Devolver',
      feminine: 'Femenino',
      fgts: 'Valor de su FGTS',
      field_required: 'Finalización obligatoria',
      final: 'Final',
      finance: 'Financiero',
      financial: 'Financiero',
      financial_payment: 'Pago financiero',
      financial_payment_return: 'Devolución del pago financiero',
      financial_receipt: 'Recibo de financiación',
      financing: 'Financiación',
      financings: 'Financiación',
      first_10_pages: 'Primeras 10 páginas',
      fisica: 'Persona física',
      for_year: 'Por año',
      forget_password: 'Olvidé la contraseña',
      forgot_password: 'Olvido la contraseña',
      form: 'Molde',
      form_of_payment: 'Forma de pago',
      found: 'Encontró',
      founds: 'Encontró',
      free: 'Gratis',
      friend_indicated: 'Recomendado por mis amigos',
      friend_indicateds: 'Recomendado por mis amigos',
      fuel: 'Combustible',
      full_name: 'Nombre completo',
      funcPublico: 'es un servidor publico',
      fundamental_complete: 'Fundamental - Completo',
      fundamental_incomplete: 'Fundamental - Incompleto',
      ganh_diario: 'Ganancias diarias',
      gender: 'Género',
      grand_total: 'Total general',
      group: 'Grupo',
      has_withdraw_today: 'Ya se ha solicitado un retiro hoy',
      high: 'Alto',
      home: 'Comenzar',
      how_create_binance_apikey: 'Cómo crear una clave API en Binance',
      how_it_works: 'Como funciona',
      how_to_call: '¿Cómo quieres que te llamen?',
      id_td: 'Referencia directa',
      images: 'Imágenes',
      in_construction: 'En construcción',
      in_order: 'En orden',
      in_stock: 'En stock',
      indicant: 'Indicador',
      indicated_by: 'Estás siendo referido por',
      indicated_not_found: 'El Referidor indicado no fue encontrado...',
      indicateds: 'Nominados',
      indirects: 'Indirecto',
      info: 'Boletines',
      info_contact: 'Información del contacto',
      initial: 'Hogar',
      insert_new: 'Ingresar nuevo',
      installments: 'Cuotas',
      intelligence: 'Inteligencia',
      interesse: '¿Cuál es la región de interés?',
      invalid_contact: 'Formato de contacto no válido',
      invalid_images: 'Imágenes no válidas',
      invalid_token: 'simbolo no valido',
      invite: 'Invitar',
      invite_friends: 'Invita a tus amigos',
      January: 'Enero',
      July: 'Julio',
      June: 'Junio',
      juridica: 'Persona jurídica',
      know_exact_result: '¿Sabes el valor exacto?',
      lead: 'Juntos estaremos construyendo un nuevo futuro.',
      learn_more: 'Sepa mas',
      left: 'Izquierda',
      left_preferential: 'Prefiriendo el lado izquierdo',
      legalNature: 'Naturaleza jurídica',
      level: 'Nivel',
      limite_80: 'Has alcanzado el {{perc}} de tu límite.\n Realiza una mejora y evita la suspensión de tus ingresos.',
      limite_excedido: 'Límite de débito excedido',
      linear: 'Lineal',
      link_email: 'Haga clic en el enlace enviado al correo electrónico registrado.',
      link_invite_copy: 'Compartir enlace copiado correctamente',
      liquid: 'Líquido',
      list: 'Lista',
      loading: 'Cargando',
      location: 'Ubicación',
      locked: 'Obstruido',
      login: 'Entrar',
      logout: 'Salir',
      low: 'Bajo',
      male: 'Masculino',
      manage_users: 'Administrar usuarios',
      manager: 'Gerente',
      mandala: 'mandala',
      mandalas: 'Mándalas',
      manufacturer: 'Fabricante',
      March: 'Marzo',
      maritalStatus: 'Estado civil',
      market: 'Mercado',
      married: 'Casado',
      master_complete: 'Maestría - Completa',
      master_incomplete: 'Maestría - Incompleta',
      max_payment: 'Pago máximo',
      May: 'Puede',
      message: 'Mensaje',
      messageNotSent: 'No fue posible enviar tu mensaje',
      messageSent: 'Su mensaje ha sido enviado con éxito',
      middle_complete: 'Medio - Completo',
      middle_incomplete: 'Medio - Incompleto',
      min_amount_error: 'El valor debe ser al menos:',
      min_withdraw: 'Monto mínimo de retiro',
      missing: 'Desaparecido',
      mission: 'Misión',
      mission_vision_values: 'Misión, visión y valores',
      model: 'Modelo',
      monitors: 'Monitores',
      monthly: 'Mensualidad',
      motherName: 'Nombre de la madre',
      movements: 'Movimientos',
      msg_renovacao: 'Has alcanzado tu límite de ganancias, renueva tu paquete.',
      my_account: 'Mi cuenta',
      my_wallet: 'Mi billetera',
      mystock: 'Mi acción',
      name: 'Nombre',
      nascMaisVelhoRenda2: '¿Cuál es la fecha de nacimiento del mayor?',
      nationality: 'Nacionalidad (País de nacimiento)',
      naturalness: 'Lugar de nacimiento (Ciudad de nacimiento)',
      nature: 'Naturaleza',
      network: 'Red',
      new: 'Nuevo',
      new_call: 'Nueva convocatoria',
      new_financing: 'Nueva Financiación',
      new_order: 'Nueva orden',
      new_password: 'Nueva contraseña',
      new_password_security: 'Nueva contraseña de seguridad',
      new_seller: 'Nuevo vendedor',
      new_wallet: 'Nueva billetera',
      news: 'Noticias',
      next: 'Próximo',
      nick: 'Inicio de sesión de usuario)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS ya registrado',
      no: 'No',
      no_approve: 'Aún no aprobado',
      no_delete: 'No borres',
      no_literate: 'No alfabetizado',
      no_repeat: 'Los datos no se pueden repetir',
      no_results_found: 'Ningún resultado encontrado',
      no_services_or_products_added: 'No se agregaron servicios ni productos',
      no_settings: 'Ve a Configuración y completa tus datos.',
      none_friends_indicated: 'Aún no he recomendado a ningún amigo',
      not_authorized: 'No autorizado',
      not_found: 'No encontrado',
      not_login: 'No se puede iniciar sesión, verifique sus datos e inténtelo nuevamente.',
      not_orders: 'Sin órdenes de pago',
      not_permission: 'Usted no tiene permiso para acceder a esta página',
      not_registered: '¿Todavía no estas registrado? Haga clic aquí y regístrese',
      not_results: 'No se encontraron registros',
      not_self_register: 'No se puede registrar',
      not_today_entry: 'Todavía no hemos tenido entradas hoy.',
      notfound_ad: 'No se encontraron anuncios',
      November: 'Noviembre',
      number: 'Número',
      obs: 'Comentarios',
      obs_pay:
        'Si su corredor debita tarifas del monto enviado, deberá realizar la corrección manualmente sumando los montos de estas tarifas.',
      October: 'Octubre',
      of: 'en',
      on_total: 'Del total',
      open: 'Abierto',
      opening: 'Apertura',
      operation: 'Actuación',
      ordenation: 'Realizar pedidos',
      order: 'Orden',
      order_book: 'Libro de pedidos',
      order_not_alowed_to_pay: 'Solicitud no autorizada para pagar con saldo',
      order_not_found: 'Orden no encontrada',
      order_payed: 'Orden #{{id}} pagado exitosamente',
      orders: 'Peticiones',
      other: 'Otro',
      other_services_or_products: 'Otros servicios o productos',
      paid: 'Pagado',
      paid_date: 'dia de pago',
      partial: 'Parcialmente pagado',
      passport: 'Pasaporte',
      password: 'Contraseña',
      password_security: 'Contraseña de seguridad',
      pay: 'Pagar',
      pay_exact: 'Paga el importe en {{coin}}',
      pay_generate: 'Generar pago',
      pay_selected: 'Pagar seleccionado',
      pay_value_in: 'Pagar el importe en',
      pay_with_balance: 'Pagar con Saldo',
      payable: 'Pagar',
      payer: 'Pagador',
      payment: 'Pago',
      ped_retirada: 'Solicitud de Retiro',
      ped_retirada_gan_diario: 'Solicitud de Retiro de Ganancias Diarias',
      pendent: 'Pendiente',
      pendent_order: 'Tienes pedidos pendientes',
      people: 'Gente',
      person: 'Persona',
      person_found_in_other_prefecture: 'Persona ya empadronada en otro municipio',
      personal: 'Tipo',
      personal_addresses: 'Direcciones',
      personal_contacts: 'Contactos',
      personal_data: 'Datos personales',
      personal_infos: 'Información',
      personal_pcva: 'Programa Casa Verde y Amarilla',
      phone: 'Numero de teléfono',
      photo: 'Fotografía',
      pix: 'FOTO',
      pix_payment: 'Pago en foto',
      pix_payment_return: 'Devolución de pago en PIX',
      pix_receipt: 'Recibir en PIX',
      pl_carreira: 'Plano de carrera',
      plan: 'Departamento',
      plates: 'Platos',
      please_wait: 'Espere por favor',
      plus: 'Más',
      point: 'Punto',
      points: 'Puntos',
      portfolio: 'portafolio',
      pos_complete: 'Lato sensu - Completo',
      pos_incomplete: 'Lato sensu - Incompleto',
      prefecture: 'Ayuntamiento',
      prefecture_addresses: 'Direcciones',
      prefecture_contacts: 'Contactos',
      prefecture_data: 'Datos del Ayuntamiento',
      prefecture_infos: 'Información',
      prefectures: 'Ayuntamientos',
      prev: 'Anterior',
      price: 'Precio',
      print: 'Imprimir',
      privacy_policies: 'Políticas de privacidad',
      private_works: 'Obras Privadas',
      profession: 'Profesión',
      profile: 'Perfil',
      program: 'Programa',
      programs: 'Software',
      project: 'Proyecto',
      projects: 'Proyectos',
      public_works: 'Obras Públicas',
      purchase: 'Compra',
      qualify_binary: 'Calificación de doble punto',
      qualquer: 'Cualquier',
      quantity: 'Cantidad',
      quotation: 'Precio',
      read: 'Leer',
      receivable: 'Para recibir',
      recipient: 'Receptor',
      recommendations: 'Recomendaciones',
      redefine_password: 'Redefinir contraseña',
      register: 'Registro',
      register_people: 'Registro de Personas',
      register_wallet: 'Registre una billetera para realizar retiros',
      registers: 'Registros',
      registers_users: 'Registros de usuarios',
      reject: 'Rechazar',
      remaining: 'Restante',
      remove: 'Para eliminar',
      rendaIndividual: 'Ingreso bruto individual',
      renew: 'Renovar',
      renew_mandala: 'Renovación de mandalas',
      reply: 'Responder',
      reports: 'Informes',
      required_data_not_sent: 'Datos requeridos no enviados',
      resend_email: 'Reenvíe el correo electrónico',
      residential: 'Residencial',
      responded: 'Contestada',
      restricao: '¿Tiene restricciones sobre su nombre? (SPC/Serasa)',
      result: 'Resultado',
      results: 'Resultados',
      resume_data: 'Resumen de datos',
      revoked: 'Derogado',
      rg: 'Registro General (Identidad)',
      rgAgency: 'emisor RG',
      rgDate: 'Fecha de emisión de RG',
      rgUf: 'Estado de RG',
      right: 'Bien',
      right_preferential: 'Prefiriendo el lado derecho',
      roi: 'Ganancias diarias',
      sale: 'Venta',
      saq_duplicado: 'Doble Retiro',
      save: 'Ahorrar',
      schooling: 'Educación',
      search: 'Buscar',
      secretary: 'Secretario',
      security_password: 'Contraseña de seguridad',
      see: 'Para ver',
      see_advents: 'ver los anuncios',
      see_call: 'Ver convocatoria',
      see_details: 'Ver detalles',
      see_details_wallet: 'Ver detalles de la billetera',
      see_financing: 'Ver financiación',
      see_order: 'Ver pedido',
      see_user: 'Ver usuarios',
      select_vehicle: 'Seleccionar vehículo',
      self_register: 'Registro',
      sell_volume: 'El volumen de ventas',
      seller: 'Vendedor',
      send_call: 'Enviar llamada',
      send_to: 'Enviar para',
      September: 'Septiembre',
      settings: 'ajustes',
      share_your_link: 'Comparte tu enlace',
      show: 'Mostrar hasta',
      show_from: 'Mostrando desde',
      side: 'Lado',
      sign_in_to_start_your_session: 'Inicia sesión para iniciar tu sesión',
      sign_up: 'Registro',
      single: 'Soltero',
      sold: 'Vendido',
      state: 'estado',
      status: 'Estado',
      strategies: 'Estrategias',
      street: 'Camino',
      subject: 'Sujeto',
      submit: 'Mandar',
      submit_placeholder: 'Por favor complete su correo electrónico',
      subscribe: 'Suscríbete para recibir nuestras novedades',
      subtitle: 'Subtítulo',
      success_update_user: 'Usuario actualizado exitosamente!',
      success_wallet: 'Portafolio actualizado exitosamente!',
      suggestions: 'Sugerencias',
      superior_complete: 'Superior completo',
      superior_incomplete: 'Superior incompleta',
      supervisor: 'Supervisor',
      support: 'Soporte',
      support_materials: 'Materiales de apoyo',
      suspended: 'Suspendido',
      symbol: 'Símbolo',
      symbols: 'Símbolos',
      system: 'Sistema',
      target: 'Meta',
      telegram_chat_id: 'ID de chat de Telegram',
      tempoCarteira: '¿Cuánto tiempo lleva registrado en su cartera?',
      terms: 'Acepto los términos de uso',
      text_hint_binance:
        'Al crear la clave API en Binance, ingrese la IP en las restricciones de acceso:\n172.105.78.139\nLuego seleccione las opciones:\n HABILITAR OPCIONES EUROPEAS\n HABILITAR COMERCIO PUNTUAL Y MARGEN',
      this_value_in: 'Este valor en',
      ticker_health: 'TickerSalud',
      title: 'Título',
      to: 'Hasta',
      to_pay: 'Apagar',
      to_receive: 'A recibir',
      today_entry: 'La entrada de hoy',
      token_not_found_or_already_used: 'Token no encontrado o ya utilizado',
      total: 'Total',
      total_entry: 'Entrada total',
      traderbot: 'Comerciante de robots',
      transf_credito: 'Transferir crédito',
      transf_debito: 'Transferir Débito',
      transfer_payment: 'Pago por transferencia',
      transfer_payment_return: 'Devolución de pago mediante transferencia',
      transfer_receipt: 'Recibo en transferencia',
      transparency: 'Transparencia',
      tx_adm_cred: 'Administrar impuestos',
      tx_adm_deb: 'Administrar impuestos',
      tx_retirada: 'Cargo por retiro',
      tx_retirada_gan_diario: 'Tarifa de retiro de ganancias diarias',
      type: 'Tipo',
      update: 'Actualizar',
      update_wallet: 'Actualizar billetera',
      upgrade: 'Mejora',
      used: 'Usado',
      user: 'Usuario',
      user_active: 'Usuario activo',
      user_already_exists: '¡El usuario ya existe!',
      user_and_or_password_wrong: '¡Nombre de usuario y/o contraseña incorrectos!',
      user_inactive: 'Usuario inactivo',
      user_not_found: 'Usuario no encontrado',
      user_not_logged: 'Usuario no iniciado sesión',
      user_or_password_wrong: 'usuario o contraseña incorrectos',
      users: 'Usuarios',
      users_list: 'Lista de usuarios',
      valid_images: 'Imágenes válidas',
      valorRenda2: '¿Cuál es el valor del segundo ingreso?',
      value: 'Valor',
      value_donate_on: 'Valor de la donación en',
      value_financed: 'Monto financiado',
      value_installment: 'Valor de la cuota',
      values: 'Valores',
      vehicle: 'Vehículo',
      vehicles: 'Vehículos',
      view_order: 'Ver pedido',
      vision: 'Visión',
      visitor: 'Visitante',
      voucher: 'Vale',
      wait_confirm: 'Esperando confirmacion',
      waiting: 'Espera',
      waiting_email: 'El registro fue exitoso, ahora todo lo que necesitas hacer es confirmar tu correo electrónico.',
      waiting_payment: 'En espera de pago',
      wallet: 'portafolio',
      wallet_generate: 'Generar Portafolio',
      wallets: 'Carteras',
      want_donate: 'quiero donar',
      want_now_more_about: 'quiero saber más sobre',
      warning: 'ATENCIÓN',
      we_are: 'Son',
      webhooks: 'Ganchos web',
      welcome: 'Sea bienvenido',
      who_we_are: 'Quienes somos',
      who_we_are_subtitle: 'Una breve descripción de la',
      widower: 'Viudo',
      will_expire_in: 'caducará en',
      with: 'con',
      withdraw: 'Retiro',
      withdraw_sended_email:
        '¡Retiro solicitado, revise su correo electrónico y haga clic en el enlace para autorizar!',
      withdrawal: 'Retiros',
      withdrawals: 'Retiros',
      year: 'Año',
      years: 'Años',
      yes: 'Sí',
      you_have: 'Tú tienes',
      your_country: 'Su país',
      your_dashboard: 'Este es tu Panel de Control.',
      your_link: 'Enlace de afiliados',
      zipcode: 'Código postal',
      withdrawal_sended: 'Retiro enviado',
      withdrawal_pending: '¡Retiro ya solicitado! \nEspere el pago para realizar otro retiro.',
      limite_80_top: 'Has alcanzado {{perc}} tu límite.\n Anticipa tu renovación y evita suspender tus ingresos.',
      game: 'Juego',
      games: 'Juegos',
      winning_numbers: 'Números sorteados',
      your_numbers: 'Tus números',
      add_numbers: 'Agregar números',
      next_draw: 'O sorteiro começará em:',
      can_bet_until: 'Puedes apostar hasta',
      your_bets: 'Tus apuestas',
      add_bet: 'Agregar apuesta',
      bet: 'Apuesta',
      bets: 'Apuestas',
      numbers: 'Números',
      special_numbers: 'Números especiales',
      resume: 'Resumen',
      choosed_numbers: 'Números elegidos',
      choosed_special_numbers: 'Números especiales elegidos',
      bets_finished: 'Apuestas resueltas',
      prize_draw: 'Sorteo de premios',
      balance_network: 'Saldo de la red',
      telegram: 'Telegrama',
      logs: 'Registros',
      release_withdrawals: 'Liberar retiros',
      bot_active: 'Bot de lanzamiento',
      available: 'Disponible',
      sunday: 'Domingo',
      monday: 'Segundo',
      tuesday: 'Tercero',
      wednesday: 'Cuatro',
      thursday: 'Quinto',
      friday: 'Viernes',
      saturday: 'Sábado',
      title_binary: 'Establecer porcentajes de puntos duales para cada día',
      ok_perc_change: 'Los porcentajes cambiaron exitosamente',
      cred_deb_title: 'Saldo de crédito o débito',
      send: 'Mandar',
      voucher_pendent: 'Vale pendiente',
      leader_pendent: 'Líder pendiente',
      voucher_approved: 'Cupón aprobado',
      leader_approved: 'Líder aprobado',
      no_binary_at_today: 'No hay ningún Punto Dual registrado ese día',
      generate_file: 'Generar archivo',
      withdrawals_file_created: 'Archivo de retiro creado',
      withdrawals_sended: 'Solicitudes de retiro enviadas',
      see_generated_files: 'Ver archivos generados',
      files: 'Archivos',
      file: 'Archivo',
      verify: 'Verificar',
      rescue: 'Rescate',
      awarded: 'Galardonado',
      rescued: 'Rescatada',
      repeat: 'Repetir',
      draw_date: 'Fecha del sorteo',
      bet_date: 'Fecha de apuesta',
      drawn_numbers: 'Números sorteados',
      awardeds_numbers: 'Números de premio',
      no_award: 'Sin premio',
      you_hit_number: 'Tienes {{qty}} número correcto',
      you_hit_numbers: 'Tienes {{qty}} números correctos',
      lottery: 'Lotería',
      lotteries: 'Loterías',
      balls: 'pelotas',
      special_balls: 'Especiales',
      levy: 'recopilación',
      major_awarded: 'mas bolas',
      hits: 'golpes',
      balance_game: 'Saldo para juegos',
      your_balance: 'Tu balance',
      your_balance_total: 'Su saldo total',
      game_package: '{{qty}} números (puede ganar {{prize}} veces)',
      choose_package: 'Elige el paquete',
      error_bet: 'No se puede enviar la apuesta',
      do_complete: 'Completar',
      success_bet: 'Apuesta enviada',
      you_bet_number: 'Apuestas {{qty}} números',
      winner_msg: 'y ganó {{prize}} veces',
      prize: 'Otorgar',
      balance_not_enough: 'Fondos insuficientes',
      addbalance: 'Agregar saldo a los juegos',
      convertbalance: 'Convertir saldos',
      convertbalance_title: 'Convertir saldo disponible en saldo de juego',
      can_award: 'Puedes ganar',
      emission: 'Emisión',
      validation: 'Validación',
      participant: 'Partícipe',
      concourse: 'Concurso',
      draw_hours: 'Hora del sorteo',
      qty_dezenas: 'Cantidad de decenas',
      bet_amount: 'Cantidad Apuesta',
      share: 'Para compartir',
      shared: 'Listo para compartir',
      conversion: 'Conversión entre saldos',
      better: 'Consultor de apuestas',
      lottery_consultant: 'Consultor Asociado',
      consultant_indication: 'Cita de consultor',
      betting_commission: 'Comisión de recarga de saldo del juego',
      be_a_consultant: 'Conviértete en Consultor de Lotería y gana mucho más',
      msg_be_a_consultant:
        'The Lotter Global Profit Division.\nBono por recomendación (en ventas de paquetes)\nBono de doble punto\ny mucho más.',
      auto_renovations: 'Renovaciones Automáticas',
      auto_renove_binary: 'Autorrenovación de doble punto',
      auto_renove_quotas: 'Autorenovación de Metas (220%)',
      auto_renove_anual: 'Autorenovación anual',
      off: 'Apagado',
      on: 'Conectado',
      change_success: 'cambiado con éxito',
      anual: 'Anual',
      only_monday: 'Los retiros estarán disponibles todos los lunes.',
      transferency: 'Transferir',
      max_amount: 'Valor máximo',
      info_better:
        '- El Asesor de Apuestas recibe el valor total del pack en créditos de apuestas.\n\n- Se permite realizar mejoras en los PAQUETES DE APUESTAS pagando sólo la diferencia y recibiendo la diferencia en nuevos créditos de apuestas.\n\n- Actualizaciones también se permiten en PAQUETES DE ASOCIADOS pagando un paquete NUEVO en su valor total y desde allí usted recibe PARTICIPACIÓN EN LAS GANANCIAS.\n\n- Se pueden realizar renovaciones y degradaciones con Paquetes de Apostador o Paquetes de Asociado si están disponibles.',
      info_consultant:
        '- El consultor Asociado pasa los Tickets de Apuestas de su Pack a Lotter Pro para negociar con su base de clientes, y recibe hasta el 1% sobre el PACK ASOCIADO elegido en días hábiles como Reparto de Utilidades.\n\n- Se permite realizar actualizaciones a los PACKS ASOCIADOS pagando SÓLO LA DIFERENCIA, y el nuevo tope de participación del 220% pasa a ser el nuevo valor del PACK ASOCIADO descontando lo ya recibido de todos los bonos hasta ese momento.\n\n- Se permiten Upgrades en MEJORES PACKS mediante pagando un NUEVO paquete en su valor total y desde allí recibe el valor total del paquete en créditos de apuestas.\n\n- Las renovaciones y degradaciones se pueden realizar con Paquetes de Apostador o Paquetes de Asociado.\n\n- Paquetes, actualizaciones y renovaciones superiores a $500 deberán realizarse exclusivamente en PACKS ASOCIADOS.',
      confirm: 'Confirmar',
      send_new_token:
        'Una vez que la confirmación haya sido enviada a su correo electrónico, haga clic en su correo electrónico para confirmar',
      email_resended: 'Confirmación enviada a su correo electrónico, haga clic en su correo electrónico para confirmar',
      expired_send_new_token:
        'Nueva confirmación enviada a su correo electrónico, haga clic en su correo electrónico para confirmar',
      become_a_consultant: 'Actualízate y obtén mucho más',
      register_ok: 'Registrado correctamente',
      ticket_number: 'Número de boleto',
      mensal_activation: 'Activación mensual',
      conversion_to_bet: 'Conversión a apuesta',
      conversion_balance_games: 'Conversión de Saldo Disponible a saldo de juego',
      conversion_balance_lotterprocoin: 'Conversión de Saldo Disponible a Activaciones',
      conversion_lotterprocoin_games: 'Conversión de Saldo de Activaciones a saldo de juego',
      conta_corrente: 'Cuenta corriente',
      balance_games: 'Equilibrio del juego',
      add_balance_games: 'Agregar saldo a los juegos',
      transf_debit_lotterProCoin: 'Transferencia de saldo de Activaciones',
      deb_saldo_lotterProCoin: 'Débito de saldo de Activaciones',
      transf_credit_lotterProCoin: 'Transferir crédito a Activaciones',
      live_now: 'En vivo',
      offline: 'Esperando dibujo',
      tutorials: 'Tutoriales',
      tutorial_created: 'Tutorial creado',
      english: 'Inglés',
      spanish: 'Español',
      portuguese: 'Portugués',
      select_language: 'Seleccione el idioma',
      msg_renove_binary:
        'Ya has completado otro mes de la última activación mensual y no tienes activada la activación mensual automática.\nEs por eso que tu Punto Dual está inactivo.\nPaga una solicitud para agregar saldo de juego por un monto de $25.00 para realizar la activación mensual. .',
      choose_side_preferential: 'Elija el lado preferido (actualmente: {{side}})',
      count: 'Conteo',
      deb_balance_games: 'Debe de saldo de juego',
      cred_saldo_lotterProCoin: 'Creédito de saldo de Activaciones',
      historics: 'Historial',
      balance_lotterProCoin: 'Equilibrio de activación',
      msg_binary_tree_1: 'Pulse el usuario deseado durante 1 segundo para ver la información',
      msg_binary_tree_2: 'Pulse una vez sobre el usuario deseado para ver los siguientes en el binario.',
      change_security_password: 'Cambiar Contraseña de Seguridad',
      info_pass_security: 'La contraseña de seguridad se utiliza para realizar transacciones financieras.',
      redefine_security_password: 'Redefinir Contraseña de Seguridad',
      invalid_wallet: 'La billetera no es válida',
      valid_wallet: 'La billetera es válida',
      cpf_account:
        'Es necesario informar al CPF del titular de la cuenta bancaria que realizará el pago.\n\nLuego será redirigido a la página de registro de la Pasarela de Pago.\n\nUna vez completado el registro, regrese a esta página de LOTTER PRO y haga clic en Generar CÓDIGO QR PIX.',
      confirm_email: 'confirme su email',
      error_confirm_email: 'El correo electrónico proporcionado no coincide',
      info_choose_package:
        'Compra uno de los paquetes disponibles para activar y acumular puntos, o será eliminado dentro de los 15 días',
      title_info_register: 'LEA DETENIDAMENTE ANTES DE CONFIRMAR LA INSCRIPCIÓN',
      info_register:
        '- Los puntos solo se acumulan después de activar un mínimo de $10 \n- Los puntos duales se pagan a las 00:00 de Londres todos los días \n- La activación de puntos duales es 2 cuentas de $25 en cada lado \n- Confirme en la oficina el lado de derrame de su punto dual \n n- Antes de registrarse, confirme la información anterior',
      no_binaries: 'No se puede mostrar la red de este usuario',
      register_gateway: 'Haga clic aquí para registrarse en la pasarela de pago',
      packs_better: 'Paquetes de membresía para consultores de apuestas (reciba créditos por apuestas)',
      packs_consultant: 'Paquetes de membresía de consultor asociado (recibe participación en las ganancias)',
      read_choose: 'Lee atentamente y elige el pack más adecuado para ti',
      footer_choose:
        'Todos los Packs incluyen la participación en los beneficios del Programa de Afiliados de Lotter Pro',
      day: 'Día',
      days: 'Días',
      olds: 'Los viejos primero',
      recents: 'Reciente primero',
      accept_title: 'Para realizar pagos a través de PIX, se deben aceptar los términos a continuación',
      acept1:
        'Tengo conocimiento de que SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 es una empresa registrada en Brasil, y que cumple con todas las normas del Banco Central y de la Hacienda Federal, donde se realizan todas las operaciones de compra o venta de USDT. son acompañados de Factura y reportados a la Receita Federal de Brasil, según lo determina la Instrucción Normativa 1888/19.',
      acept2:
        'Soy consciente de que SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 no ofrece ningún servicio o producto de inversión y que la actividad se limita a la compra o venta de USDT.',
      acept3:
        'Acepto compartir mis datos personales con SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 para que pueda realizar el servicio de compra o venta de USDT-Polygon.',
      acept4:
        'Soy consciente y acepto que estoy comprando USDT a través de SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 y autorizo ​​el envío del criptoactivo a la dirección de billetera 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc de: \nSMART PAY INTERMEDIACOES | 42.913.912/0001-02 \nDirección: Av.: Paulista, N.º 171 – Complemento: PAVMTO4 y 3 VG – Bela Vista – 01311.904 – São Paulo - SP \nEmail: smartpayintermediacoes@gmail.com',
      acept5:
        'Eximo a SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 de cualquier responsabilidad comercial por los productos o servicios ofrecidos por este sitio web y/o por la empresa titular de la dirección USDT.',
      acept6:
        'Acepto que al pagar el QRCODE Pix generado a continuación estaré aceptando los términos de compra o venta de USDT de SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 y que la operación quedará registrada en la Blockchain de Polygon enviando el ID de operación del Banco Central de Brasil (de extremo a extremo) en el campo de mensaje a la dirección 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc',
      aceptAlert:
        'Al hacer clic en validar, se generará una clave Pix con un valor aleatorio entre R$0,10 y R$0,99.\n\nEste pago debe realizarse para validar su registro en SmartPay Serviços Digitais Ltda.',
      validate: 'Validar (Este cargo sólo se realizará una vez).',
      modal_acpet_title: '¿Primera vez que pagas con PIX en LOTTER PRO?',
      only_time: 'ESTE PROCESO SÓLO SE REALIZARÁ UNA VEZ.\nOtros pagos mediante PIX se validarán automáticamente.',
      terms_smartpay:
        'Estoy de acuerdo con los términos y condiciones de Smartpay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96',
      success_activation: 'Ha sido validado exitosamente y ahora puede realizar pagos a través de PIX',
      warning1: 'NO ES PAGO POR TU PEDIDO',
      warning2:
        'Este es el Código QR para validar su registro en SmartPay Serviços Digitais Ltda y poder realizar pagos vía PIX. \n NO ES PAGO POR SU PEDIDO.\n Una vez que su pago haya sido confirmado, regrese a esta página de LOTTER PRO y haga clic en Generar CÓDIGO QR PIX para generar el Código QR para el pago mediante PIX de su PEDIDO.',
      only_brazil: 'Sólo en Brasil',
      changeUserGroup: 'Cambiar categoría',
      alert_change_category:
        'Si cambia la categoría, su paquete actual ya no será válido.\nYA NO RECIBIRÁ las ganancias del paquete actual.\nAhora recibirá las ganancias de su nuevo paquete.\nSu límite de ganancias se reajustará al 220% de su nuevo paquete.\nSus saldos no cambiarán.\n\n¿Está seguro de que desea cambiar la categoría?',
      exceeded: 'Excedido',
      vinculate_whatsapp: 'Vincular WhatsApp',
      verification_code: 'Código de verificación',
      verification_msg: 'Ingresa el código de verificación enviado a tu WhatsApp',
      balance_awards: 'Saldo de premios',
      addprocoin: 'Agregar saldo de activación',
      withdrawal_awards: 'Retirada del premio',
      conversion_awards_games: 'Conversión de SALDO DE PREMIOS a SALDO DE JUEGOS',
      last_draws: 'Últimos sorteos',
      today: 'Hoy',
      yesterday: 'Ayer',
      tomorrow: 'Mañana',
      others: 'Otros',
      draws: 'Sorteos',
      learn_more_text:
        'Elija entre {{choose}} docenas \ny gane de {{rangeWin.min}} a {{rangeWin.max}} veces la cantidad apostada \namatching {{win}} docenas extraídas.',
      or: '  o',
      good_luck: '¡Buena suerte!',
      add_balance_awards: 'Agregar saldo de premio',
      deb_balance_awards: 'Débito del saldo del premio',
      key_pix: 'Clave PIX',
      choose_game: 'Elige un juego',
      do_bet: 'Apostar',
      response_bets_lot: 'Respuesta de apuestas por lotes',
      lottery_not_found: 'Lotería no encontrada',
      lottery_expired: 'Lotería caducada',
      amount_invalid: 'valor no válido',
      balance_game_not_enough: 'Fondos insuficientes',
      game_package_not_found: 'Paquete de juego no encontrado',
      please_choose_other_tens: 'Por favor elige docenas más',
      continue_beting: 'Continuar apostando',
      balls_invalid: 'Números no válidos',
      max_bet: 'Valor máximo a apostar \n dependiendo del número de decenas',
      copy_paste: 'Múltiples juegos',
      amount_copy_paste: 'Valor de cada apuesta',
      list_copy_paste: 'Lista de apuestas deseadas',
      total_valid: 'Total válido',
      erase_my_account: 'Eliminar cuenta',
      erase_alert_caution:
        'Esta operación no se puede deshacer. ¿Estás seguro de que quieres eliminar tu cuenta? \nEscribe "borrar mi cuenta" para confirmar',
      success_delete_account: 'Cuenta eliminada',
      age_major:
        'Antes de continuar, debemos confirmar que tienes 18 años o más. De acuerdo con las leyes y regulaciones, los juegos de azar están prohibidos para personas menores de 18 años.\n\nHaga clic en la casilla de verificación a continuación para confirmar que tiene 18 años o más.',
      age_major_button: 'Confirmo que tengo 18 años o más',
      title_age_major: 'Confirmación de edad',
      about_us:
        'En {{company}} creemos que todos deberían tener la oportunidad de soñar en grande. Por eso te ofrecemos la oportunidad de participar en las loterías más importantes del mundo, estés donde estés. \n\nNos enorgullecemos de operar de acuerdo con los más altos estándares éticos y legales.\nNuestro compromiso con la transparencia, la seguridad y la responsabilidad garantiza la confianza para brindar una experiencia de juego justa y divertida.',
      site_map: 'Mapa del sitio',
      kyc: 'Verificación de identidad (KYC)',
      kyc_welcome: 'Para verificar su identidad, cargue las imágenes a continuación:',
      kyc_msg:
        'Cuando se apruebe la verificación de identidad, recibirá un correo electrónico de confirmación y se liberarán todas las funcionalidades de {{company}}.',
      kyc_error: 'Se produjo un error al cargar las imágenes, inténtelo nuevamente.',
      driving: 'Licencia de conducir',
      identity: 'Identidad',
      front: 'Frente',
      front_msg: 'Foto del frente del Documento',
      back_doc: 'Verso',
      back_doc_msg: 'Foto del reverso del Documento',
      selfie: 'autofoto',
      selfie_msg: 'Documento de tenencia selfie',
      kyc_pending: 'Verificación pendiente',
      kyc_rejected: 'Verificación rechazada',
      kyc_approved: 'Verificación aprobada',
      two_fa: '',
      two_fa_msg: '',
      two_fa_step1: '',
      two_fa_step2: '',
      two_fa_activate: '',
      two_fa_deactivate: '',
      two_fa_activated: '',
      two_fa_verify: '',
      draw_due: 'Límite para apostar',
      close_game: 'Cerrar juego',
      draw_due_greater_than_draw_date:
        'El límite de apuestas debe ser al menos {{timeLimit}} minutos antes del sorteo.',
      draw_date_less_than_now:
        'La fecha del sorteo debe ser mayor que la fecha actual en al menos {{timeLimit}} minutos',
      simulation: 'Simulación',
      valuePay: 'Valor a pagar',
      quotas_paid_success: 'Pago exitoso',
      paids_today: 'Pagos realizados hoy',
      no_paids_today: 'No se realizaron pagos hoy',
      perc: 'Porcentaje',
      define_percent: 'Establecer porcentaje',
      success_update: 'Actualización exitosa',
      define_percent_next_payment: 'Se define el porcentaje del siguiente pago',
      create: 'Crear',
      doclose: 'Cerrar',
      lottery_updated: 'Juego actualizado',
      lottery_created: 'Juego creado',
      award_packages: 'Paquetes de premio',
      qty: 'cant.',
      multiplier: 'multiplicación',
      maxValue: 'Max. valor',
      limitRepeat: 'Limitar repetición',
      draw_day: 'Fecha del sorteo',
      hour: 'hora',
      minutes: 'minutos',
      ytb_channel_id: 'ID del canal de YouTube',
      tutorial: 'Tutorial',
      video_url: 'URL del video',
      tutorial_deleted: 'Tutorial eliminado',
      tutorial_error_delete: 'No se puede eliminar el tutorial',
      tutorial_updated: 'Tutorial actualizado',
      tutorial_error_update: 'No se puede actualizar el tutorial',
      language: 'Idioma',
      live_hours: 'Hora en vivo',
      exact: 'Exactamente',
      partiallly: 'Parcial',
      closed: 'Cerrado',
      statistics: 'Statisticas',
      your_link2: 'Enlace del apostador',
      deb_20_to_games: 'Transferir al saldo del juego',
      cred_20_to_games: 'Transferir al saldo del juego',
      transf_debit_games: 'Transferencia de saldo del juego',
      transf_credit_games: 'Transferir al saldo del juego',
      file_too_large: 'El archivo es demasiado grande. Tamaño máximo: {{max}}',
      kyc_warning: 'Complete la verificación de identidad para apostar hasta $100 por juego',
      msg_rules_footer:
        '1. Este producto está destinado a usuarios mayores de 18 años y está destinado únicamente a fines de entretenimiento.\n 2. Este sitio contiene compras dentro de la aplicación. \n 3. El hecho de que un jugador juegue o gane en un juego de apuestas sociales no significa que ganará en apuestas con dinero real y juegos relacionados en el futuro.',
      terms_conditions: 'Términos y Condiciones',
      aml_policy: 'Política ALD',
      kyc_policy: 'Política de verificación de identidad',
      self_exclusion_policy: 'Política de autoexclusión',
      responsible_gambling_policy: 'Política de juego responsable',
      clause_18: 'Política de juego menor (cláusula 18+ para jugar)',
      policies: 'Políticas',
      join_us: 'Únete a nuestra comunidad',
      max_prize: 'Premio máximo: {{maxPrize}}',
      error_weak_password:
        'Su contraseña debe tener al menos 8 caracteres, con letras mayúsculas, minúsculas, números y caracteres especiales.',
      register_error_age: 'Debe ser mayor de 18 años para registrarse',
      months: 'meses',
      raffels: 'Rifas',
      tickets: 'Entradas',
      ticket: 'Boleto',
      contest: 'Concurso',
      your_raffels: '¡Tus rifas son:',
      your_tickets: '¡Tus entradas son:',
      result_lottery: 'Resultado del sorteo',
      buy_now: 'Compralo ahora',
      generate_link: 'Generar enlace',
      mobile_provider: 'Proveedorador de móviles',
      mobile: 'Móvil',
      bank_transfer: 'Transferencia bancaria',
      accountNumber: 'Cuenta',
      ifscCode: 'IFSC',
      swiftCode: 'código SWIFT',
      bankName: 'Nombre del banco',
      documentNumber: 'Número del Documento',
      accountHolderName: 'Nombre del titular de la cuenta',
      accountAgencyNumber: 'Número de agencia',
      accountTypeCode: 'Código de tipo de cuenta',
      payMode: 'Modo de pago',
      transitNumber: 'Número de Tránsito',
      financialInstitutionNumber: 'Número de institución financiera',
      bankBranchName: 'Nombre de agencia',
      bankBranchCode: 'Código de agencia',
      click_to_open: 'Click para abrir',
      send_proof: 'Enviar comprobante',
      proof_payment_sent: 'Comprobante enviado',
      indicate_a_friend_and_win: 'Recomiende a un amigo y gane con cada recarga',
      conversion_slim: 'Conversión',
      link: 'Enlace',
      recomendeds: 'Recomendado',
      all_games: 'Todos los juegos',
      selected_country: 'Pais seleccionado',
      games_of_today: 'Juegos de hoy',
      you_win: '¡Acabas de ganar!',
      times: 'veces',
      over_your_bet: 'sobre el valor de tu apuesta',
      accumulated: 'acumulado',
      accumulated_points: 'puntos acumulados',
      start: 'Comenzar',
      end: 'Finalizar',
      new_raffle: 'Nuevo sorteo',
      add_raffel_success: 'Nueva rifa creada exitosamente',
      add_prize_success: 'Nuevo premio creado con éxito',
      edit_prize_success: 'Premio editado con éxito',
      add_prize_img_success: 'Imagen del premio creada con éxito',
      add_order_tickets_success: 'Entradas agregadas exitosamente',
      edit_raffel_success: 'Rifa editada con éxito',
      error_orderPayment: 'Error al realizar el pago',
      error_currentOrder: 'Error al actualizar el pedido',
      error_editedOrder: 'Error al editar el pedido',
      partially_paid: 'Parcialmente pagado',
      add_paid: 'Membresía paga',
      upgrade_paid: 'Actualización paga',
      renew_paid: 'Renovación pagada',
      monthly_paid: 'Cuota mensual pagada',
      mandala_paid: 'Matriz pagada',
      bet_activated: 'Apuesta Activada',
      add_tickets_raffel: 'Entradas añadidas al sorteo.',
      order_not_canceled: 'Pedido no cancelado',
      cancelOrder: 'Orden cancelada',
      price_consultant: 'Precios de consultor',
      price_consumer: 'Precio al consumidor',
      maxTickets: 'Entradas máx.',
      edit_raffle: 'Editar sorteo',
      prizes: 'Premios',
      new_prize: 'Nuevo premio',
      add_prize: 'Agregar premio',
      edit_prize: 'Editar premio',
      prize_name: 'Nombre del premio',
      conversion_to_games: 'Conversión a juegos',
      conversion_to_lotterprocoin: 'Conversión a activaciones',
      trans_debit_awards: 'Transferencia del saldo del premio',
      conversion_awards_lotterprocoin: 'Conversión de SALDO DE PREMIOS a SALDO DE ACTIVACIÓN',
      pay_with_balance_awards: 'Pagar con SALDO DE PREMIOS',
      your_minor_side_is: 'El lado menor es {{side}}',
      promotion_title: 'Promoción LotterPro te lleva a curazao',
			picture_recomend: 'Imagen recomendada',
			picture_list: 'Imagen en lista',
			picture: 'Imagen',
			local_hour: 'Hora local',
			timezone_hour: 'Hora de zona',
    },
  },
}
export { messages }
