import { Button, Modal } from 'react-bootstrap'
import { i18n } from '../../utils/translates/i18n'
import { useEffect, useState } from 'react'
import PrizeImg from './prizeImg'
import { deletePrize } from '../../services/RaffelsService'
import LoaderPage from '../../utils/LoaderPage'
import { toast } from 'react-toastify'
import { FormateFiatCurrency } from '../../utils/CustomFormatations'
import { getTimezone } from '../../services/LotteriesService'

export default function ModalRaffel({
  modalAddRaffel,
  setModalAddRaffel,
  newRaffel,
  setNewRaffel,
  doAddRaffle,
  doEditRaffle,
  doGetRaffels,
  setModalAddPrize,
  setPostPrize,
}) {
  const [show, setShow] = useState(true)

  const [timezones, setTimezones] = useState([])
  const [countries, setCountries] = useState([])
  const doGetTimezones = async () => {
    const success = await getTimezone()
    if (success.status) {
      setTimezones(success.timezones)
      setCountries(success.countries)
    }
    return success.timezones
  }

  const openModalAddPrize = data => {
    if (data)
      setPostPrize({
        ...data,
        raffelId: newRaffel?.id,
      })
    setModalAddPrize(true)
  }

  const doDeletePrize = async id => {
    setShow(false)
    const success = await deletePrize(id)
    if (success.status) {
      setNewRaffel(p => ({ ...p, prizes: p.prizes.filter(p => p.id !== id) }))
      toast.success(i18n.t(success.msg))
      setShow(true)
    }
  }

  const formateUtc = (date, offset) => {
    const dataUTC = new Date(date)

    dataUTC.setHours(dataUTC.getHours() + parseFloat(offset))

    // console.log({ dataUTC })

    return dataUTC
  }

  useEffect(() => {
    doGetTimezones().then(tz => {
      if (newRaffel.id && tz.length > 0) {
        const utc = tz?.filter(t => t.id?.toString() === newRaffel.timezone)[0]?.offset
        // console.log({ utc })

        const drawDate = new Date(formateUtc(newRaffel?.drawDate, utc))
        const drawDue = new Date(formateUtc(newRaffel?.drawDue, utc))

        setNewRaffel(p => ({
          ...p,
          drawDate: drawDate?.toISOString().split('T')[0],
          drawHours: drawDate?.toISOString().split('T')[1].split(':')[0],
          drawMinutes: drawDate?.toISOString().split('T')[1].split(':')[1],
          drawDue: drawDue?.toISOString().split('T')[0],
          dueHours: drawDue?.toISOString().split('T')[1].split(':')[0],
          dueMinutes: drawDue?.toISOString().split('T')[1].split(':')[1],
        }))
      }
    })
    setShow(true)
  }, [])

  return !show ? (
    <LoaderPage />
  ) : (
    <Modal size="xl" show={modalAddRaffel} onHide={() => setModalAddRaffel(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{newRaffel.id ? i18n.t('edit_raffle') : i18n.t('new_raffle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('lottery')}:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newRaffel?.lotteryName}
                    onChange={e => setNewRaffel(p => ({ ...p, lotteryName: e.target.value }))}
                    onBlur={e => setNewRaffel(p => ({ ...p, lotteryName: e.target.value.toUpperCase() }))}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('contest')}:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newRaffel?.contest}
                    onChange={e => setNewRaffel(p => ({ ...p, contest: e.target.value }))}
                    onBlur={e => setNewRaffel(p => ({ ...p, contest: e.target.value.toUpperCase() }))}
                  />
                </div>
              </div>
              {/* <div className="col-lg-4 mb-3">
                <div className="form-group">
                  <label>{i18n.t('price_consultant')}:</label>
                  <input
                    type="number"
                    step={1}
                    className="form-control"
                    value={newRaffel?.priceConsultant}
                    onChange={e =>
                      setNewRaffel(p => ({ ...p, priceConsultant: e.target.value < 1 ? 1 : e.target.value }))
                    }
                  />
                </div>
              </div> */}
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('price_consumer')}:</label>
                  <input
                    type="number"
                    step={1}
                    className="form-control"
                    value={newRaffel?.priceConsumer}
                    onChange={e => setNewRaffel(p => ({ ...p, priceConsumer: e.target.value }))}
                    onBlur={e => setNewRaffel(p => ({ ...p, priceConsumer: e.target.value < 1 ? 1 : e.target.value }))}
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label>{i18n.t('maxTickets')}:</label>
                  <input
                    type="number"
                    step={1}
                    className="form-control"
                    value={newRaffel?.maxTickets}
                    onChange={e => setNewRaffel(p => ({ ...p, maxTickets: e.target.value }))}
                    onBlur={e => setNewRaffel(p => ({ ...p, maxTickets: e.target.value < 1 ? 100 : e.target.value }))}
                  />
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className="input-group">
                  <div className="btn bg-dark text-light">{i18n.t('country')}</div>
                  <select
                    className="form-select"
                    value={newRaffel.countryId}
                    onChange={e => setNewRaffel({ ...newRaffel, countryId: e.target.value })}>
                    <option value="">-- {i18n.t('choose')} --</option>
                    {countries
                      .sort((a, b) => a.ptbr.localeCompare(b.ptbr))
                      .map((c, i) => (
                        <option key={i} value={c.id}>
                          {c.ptbr}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="btn bg-info text-light">{i18n.t('timezone')}</div>
                  <select
                    className="form-select"
                    value={newRaffel.timezone}
                    onChange={e => setNewRaffel({ ...newRaffel, timezone: e.target.value })}>
                    {timezones.map(timezone => (
                      <option key={timezone.id} value={timezone.id}>
                        {timezone.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="bg-secondary text-dark mb-2">
                <div className="row p-2">
                  <div className="col-12 text-bg-dark">{i18n.t('timezone_hour')} </div>
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label>{i18n.t('draw_date')}:</label>
                      <input
                        type="date"
                        className="form-control"
                        value={newRaffel?.drawDate}
                        onChange={e => setNewRaffel(p => ({ ...p, drawDate: e.target.value }))}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group mb-2">
                      <label>{i18n.t('hour')}:</label>
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        max={23}
                        step={1}
                        value={newRaffel.drawHours}
                        onChange={e => setNewRaffel(p => ({ ...p, drawHours: e.target.value }))}
                        onBlur={e =>
                          setNewRaffel(p => ({
                            ...p,
                            drawHours: e.target.value < 0 ? 0 : e.target.value > 23 ? 23 : e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group mb-2">
                      <label>{i18n.t('minutes')}:</label>
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        max={59}
                        step={1}
                        value={newRaffel.drawMinutes}
                        onChange={e => setNewRaffel(p => ({ ...p, drawMinutes: e.target.value }))}
                        onBlur={e =>
                          setNewRaffel(p => ({
                            ...p,
                            drawMinutes: e.target.value < 0 ? 0 : e.target.value > 59 ? 59 : e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-secondary text-dark mb-2">
                <div className="row p-2">
                  <div className="col-12 text-bg-dark">{i18n.t('timezone_hour')}</div>
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label>{i18n.t('draw_due')}:</label>
                      <input
                        type="date"
                        className="form-control"
                        value={newRaffel?.drawDue}
                        onChange={e => setNewRaffel(p => ({ ...p, drawDue: e.target.value }))}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group mb-2">
                      <label>{i18n.t('hour')}:</label>
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        max={23}
                        step={1}
                        value={newRaffel.dueHours}
                        onChange={e => setNewRaffel(p => ({ ...p, dueHours: e.target.value }))}
                        onBlur={e =>
                          setNewRaffel(p => ({
                            ...p,
                            dueHours: e.target.value < 0 ? 0 : e.target.value > 23 ? 23 : e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div className="form-group mb-2">
                      <label>{i18n.t('minutes')}:</label>
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        max={59}
                        step={1}
                        value={newRaffel.dueMinutes}
                        onChange={e => setNewRaffel(p => ({ ...p, dueMinutes: e.target.value }))}
                        onBlur={e =>
                          setNewRaffel(p => ({
                            ...p,
                            dueMinutes: e.target.value < 0 ? 0 : e.target.value > 59 ? 59 : e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-lg-12 mb-3">
              <div className="fs-5 fw-bold">{i18n.t('regulation')}</div>
              <div className="form-group mb-2">
                <textarea
                  rows={6}
                  className="form-control"
                  value={newRaffel.regulation}
                  onChange={e => setNewRaffel(p => ({ ...p, regulation: e.target.value }))}></textarea>
              </div>
            </div>
            {newRaffel.id ? (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fs-5 fw-bold">{i18n.t('prizes')}</div>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm p-2"
                    onClick={() => openModalAddPrize({ raffelId: newRaffel?.id, prizeName: '', prizeDescription: '' })}>
                    {i18n.t('new_prize')}
                  </button>
                </div>
                {newRaffel.prizes
                  ?.sort((a, b) => (a.ordenation > b.ordenation ? 1 : -1))
                  .map((prize, index) => (
                    <div className="row border border-secondary rounded p-2" key={index}>
                      {/* <div className="col-1 d-flex align-items-center">{prize.ordenation}</div> */}
                      <div className="col-2">
                        <PrizeImg data={prize} doGetRaffels={doGetRaffels} setNewRaffel={setNewRaffel} />
                      </div>
                      <div className="col-5">
                        <div className="fw-bolder">{prize.prizeName}</div>
                        <small>
                          <div className="text-truncate text-italic">{prize.prizeDescription}</div>
                        </small>
                      </div>
                      <div className="col-3">
                        <div className="fw-bolder">{FormateFiatCurrency(prize.prizeAmount)}</div>
                      </div>
                      <div className="col-1 d-flex align-items-center">
                        <button className="btn btn-warning btn-sm p-2" onClick={() => openModalAddPrize(prize)}>
                          <i className="fa fa-edit"></i>
                        </button>
                      </div>
                      <div className="col-1 d-flex align-items-center">
                        <button className="btn btn-danger btn-sm p-2" onClick={() => doDeletePrize(prize.id)}>
                          <i className="fa fa-ban"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                {/* <pre>{JSON.stringify(newRaffel.prizes, null, 2)}</pre> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* <pre>{JSON.stringify(newRaffel, null, 2)}</pre> */}
      </Modal.Body>
      <Modal.Footer>
        {newRaffel.id ? (
          <Button variant="warning" onClick={doEditRaffle}>
            {i18n.t('save')}
          </Button>
        ) : (
          <Button variant="primary" onClick={doAddRaffle}>
            {i18n.t('save')}
          </Button>
        )}
        <Button variant="primary" onClick={() => setModalAddRaffel(false)}>
          {i18n.t('doclose')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
