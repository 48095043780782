import React from 'react'

const ObjectList = ({ data }) => {
  if (data === undefined || data === null || typeof data !== 'object') {
    return <span></span>
  } else {
    return (
      <ul>
        {Object.entries(data).map(([label, content]) => (
          <li key={label}>
            <span className="fw-bold me-2">{label?.toUpperCase()}:</span>
            {typeof content === 'object' ? (
              <ObjectList data={content} />
            ) : (
              <span>{label === 'password' || label === 'passwordSecurity' || label === 'token' ? '********' : content}</span>
            )}
          </li>
        ))}
      </ul>
    )
  }
}

export default ObjectList
