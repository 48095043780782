import React, { useState, useEffect } from 'react'
import { getBalanceSmartNode } from '../../../services/HydraService'
import { i18n } from '../../../utils/translates/i18n'
import LoaderPage from '../../../utils/LoaderPage'
import { toast } from 'react-toastify'

export default function BalanceSmartNode() {
  const [report, setReport] = useState({})
  const [show, setShow] = useState(false)

  useEffect(() => {
    getBalanceSmartNode()
      .then(result => {
        setReport(result)
        setShow(true)
      })
      .catch(error => {
        console.error(error.response ? error.response.data : error)
        toast.error(error.response ? error.response.data : error)
        setShow(true)
      })
  }, [])

  return (
    <div className="col-md-4 col-sm-6 mb-4">
      <div className="card border-0 shadow h-100">
        <div className="card-body">
          {!show ? (
            <LoaderPage />
          ) : (
            <div className="row d-block d-xl-flex align-items-center">
              <div className="fs-5 fw-bolder mb-2">SmartNode (PIX)</div>
              {report?.balancesPix &&
                report.balancesPix.map((b, ib) => (
                  <div className="col-12 d-flex" key={ib}>
                    <div className="icon-shape icon-shape-info rounded me-4 me-sm-0">
                      <img
                        src={`https://www.coinpayments.net/images/coins/${
                          b.currency === 'polygon' ? 'MATIC' : b.currency.toUpperCase()
                        }.png`}
                        alt={b.currency}
                        className="icon"
                      />
                    </div>
                    <div className="ms-3">
                      <h2 className="h5">{`${i18n.t('balance')} ${b.currency.toUpperCase()}`}</h2>
                      <h5 className="fw-extrabold">
                        <small>{i18n.t('amount')}: </small>
                        {b.amount}
                      </h5>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
