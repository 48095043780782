import axios from './BaseService'

const HYDRA_URL = `${process.env.REACT_APP_API_URL}/hydra/`

export async function getDashboard() {
  const response = await axios.get(HYDRA_URL + 'dashboard')
  return response.data
}

export async function getBalances() {
  const response = await axios.get(HYDRA_URL + 'balances')
  return response.data
}

export async function getBalanceCoinPayments() {
  const response = await axios.get(HYDRA_URL + 'balancecoinpayments')
  return response.data
}

export async function getBalanceSmartNode() {
  const response = await axios.get(HYDRA_URL + 'balancesmartnode')
  return response.data
}
