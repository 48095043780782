import React from 'react'
import { i18n } from '../../utils/translates/i18n'

export default function DayAwardRow({ dayAward, doEditDayAward }) {
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  return (
    <>
      <tr>
        <td>{i18n.t(days[dayAward.dayWeek])}</td>
        <td>{dayAward.hour}</td>
        <td>{dayAward.minutes}</td>
        <td>
          <span className={`badge p-2 bg-${dayAward.status === 4 ? 'success' : 'danger'}`}>
            {dayAward.status === 4 ? i18n.t('confirmed') : i18n.t('canceled')}
          </span>
        </td>

        <td>
          <button
            className="btn btn-warning btn-sm my-0 py-1"
            data-bs-toggle="modal"
            data-bs-target="#modalDayAward"
            onClick={() => doEditDayAward(dayAward)}>
            <i className="fa fa-edit me-2"></i>
            {i18n.t('edit')}
          </button>
        </td>
      </tr>
    </>
  )
}
