import React from 'react'
import { FormateDate } from '../../../utils/CustomFormatations'
import ObjectList from './ObjectList'

export default function LogsRow({ log, setContentModal }) {
  return (
    <tr>
      <td>{FormateDate(log.createdAt, true, true)}</td>
      <td>{<ObjectList data={log?.userId} />}</td>
      <td>{log?.obs}</td>
      <td>
        <button
          type="button"
          className={`btn btn-primary btn-sm`}
          onClick={() => setContentModal({ title: 'Cabeçalho da Requisição', data: log?.headers })}>
          <i className="fas fa-eye me-2"></i> <span>Cabeçalho</span>
        </button>
      </td>
      <td>
        <button
          type="button"
          className={`btn btn-primary btn-sm`}
          onClick={() => setContentModal({ title: 'Corpo da Requisição', data: log?.body })}>
          <i className="fas fa-eye me-2"></i> <span>Corpo</span>
        </button>
      </td>
      <td>
        <button
          type="button"
          className={`btn btn-primary btn-sm`}
          onClick={() => setContentModal({ title: 'Resposta da Requisição', data: log?.response })}>
          <i className="fas fa-eye me-2"></i> <span>Resposta</span>
        </button>
      </td>
    </tr>
  )
}
