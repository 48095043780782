import React, { useRef, useState } from 'react'
import { i18n } from '../../../utils/translates/i18n'
import { FormateDate, FormateFiatCurrency } from '../../../utils/CustomFormatations'

export default function OrderPaymentsModal({ order, editOrder, rule }) {
  const btnClose = useRef('')

  return (
    <div
      className="modal fade"
      id="modalPayments"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content ">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('payment')} ${i18n.t('order')} # ${order?.id}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200">{i18n.t('form_of_payment')}</th>
                    <th className="border-gray-200">{i18n.t('amount')}</th>
                    <th className="border-gray-200">{i18n.t('paid')}</th>
                    <th className="border-gray-200">{i18n.t('coin')}</th>
                    <th className="border-gray-200">{i18n.t('wallet')}</th>
                    <th className="border-gray-200">{i18n.t('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.payments?.map((op, pi) => (
                    <tr key={pi}>
                      <td>
                        {i18n.t(op.method)}
                        {op.method === 'balance' ? (
                          ` (${
                            order?.moviments?.filter(f => {
                              const createdAt = new Date(f.createdAt).getTime()
                              const payDate = new Date(op.payDate).getTime()
                              return Math.abs(createdAt - payDate) <= 1000
                            })[0]?.user?.nick
                          })`
                        ) : (
                          <></>
                        )}
                        <br />
                        <small className={`bg-${op.statuses?.bg} rounded py-1 px-2 text-black fw-bold`}>
                          {i18n.t(op.statuses.status)} - {FormateDate(op.payDate, true, true)}
                        </small>
                      </td>
                      <td>{FormateFiatCurrency(op.amount)}</td>
                      <td>{FormateFiatCurrency(op.paid)}</td>
                      <td>{op.cryptoSymbol}</td>
                      <td>
                        {op.cryptoSymbol === 'eur' ? (
                          op.proof ? (
                            <a
                              href={`${process.env.REACT_APP_API_URL}/proofs/${op.proof}`}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="btn btn-sm btn-primary">
                              {i18n.t('proof')}
                            </a>
                          ) : (
                            <></>
                          )
                        ) : (
                          op.cryptoWallet
                        )}
                      </td>
                      <td>
                        {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
                          <>
                            {order.status === 1 || order.status === 2 ? (
                              <button
                                type="button"
                                className={`btn btn-${order.statuses?.bg} btn-sm me-3`}
                                title="Edit this Order"
                                data-bs-toggle="modal"
                                data-bs-target="#modalOrder"
                                onClick={() => editOrder(order)}>
                                <i className="fas fa-dollar me-2"></i> {i18n.t('pay')}
                              </button>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
