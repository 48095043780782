const messages = {
  la: {
    translations: {
      a_crase: 'ال',
      about: 'معلومات عنا',
      above_the_same: 'بالضبط نفس الشيء أعلاه',
      action: 'فعل',
      actionTemplates: 'نماذج العمل',
      activate_date: 'تفعيل التسجيل',
      active_automations: 'الأتمتة النشطة',
      active_connections: 'اتصالات نشطة',
      active_monitors: 'الشاشات النشطة',
      active_users: 'المستخدمين النشطين',
      ad: 'إعلان',
      add: 'الانضمام',
      add_option: 'إضافة خيار',
      add_payment: 'إضافة الدفع',
      add_question: 'أضف سؤال',
      add_receipt: 'إضافة إيصال',
      add_wallet: 'أضف المحفظة',
      address: 'عنوان',
      admin: 'إدارة',
      advance: 'يتقدم',
      affected: 'وصل',
      after: 'بعد',
      air_transport: 'النقل الجوي',
      ajust_limite: 'تعديل الحد',
      ajust_saldo: 'تعديل الرصيد',
      alert: 'يُحذًِر',
      alerts: 'التنبيهات',
      all: 'الجميع',
      all_departments: 'جميع الإدارات',
      allies_of: 'حلفاء',
      allRightsReserved: 'كل الحقوق محفوظة.',
      almost_there: 'اوشكت على الوصول',
      already_offset: 'لقد عوضت بالفعل',
      already_registered: 'مسجل بالفعل؟ انقر هنا وأدخل',
      amount: 'قيمة',
      amount_above_balance: 'رصيد غير كاف',
      and: 'إنها',
      and_more: 'و اكثر',
      answer: 'إجابة',
      ao: 'إلى',
      approve: 'ليوافق',
      April: 'أبريل',
      asc: 'تصاعدي',
      at: 'في',
      August: 'أغسطس',
      automations: 'الأتمتة',
      awaiting_email_confirmation: 'في انتظار تأكيد البريد الإلكتروني!',
      b_order: 'طلب',
      b_orders: 'طلبات',
      back: 'للعودة',
      bairroInteresse: 'حيّ',
      balance: 'توازن',
      balance_available: 'الرصيد متاح',
      banned: 'محظور',
      before_contact: 'قبل الاتصال بنا',
      binary: 'نقطة مزدوجة',
      birth: 'ولادة',
      blind_man: 'عكس',
      body_monthly:
        'لقد انتهت صلاحية الرسوم الشهرية وتم إلغاء تنشيط BOT TRADER الخاص بك، يرجى دفع أمر الدفع والاستمتاع بمزاياك مرة أخرى',
      bonus_saldo_trader: 'مكافأة رصيد التاجر',
      book_health: 'بوك هيلث',
      but_possible_register: 'لا يزال بإمكانك التسجيل، ولكن لن يتم ربطك بأي صديق محال.',
      buy_volume: 'حجم الشراء',
      buyer: 'مشتر',
      by: 'لكل',
      bye: 'أراك لاحقًا',
      call: 'مُسَمًّى',
      call_to: 'دعوة ل',
      calls: 'يدعم',
      cancel: 'يلغي',
      canceled: 'ألغيت',
      career_plan: 'المسار المهني',
      casa_verde_amarela: 'البيت الأخضر الأصفر',
      cash_payment: 'دفع نقدا',
      cash_payment_return: 'عودة الدفع النقدي',
      cash_receipt: 'إيصال الدفع',
      change_indicant: 'تغيير المؤشر',
      change_photo: 'غير الصوره',
      change_plan: 'خطة التغيير',
      check_payment: 'الدفع بالشيك',
      check_payment_return: 'إعادة الدفع عن طريق الشيك',
      check_receipt: 'الاستلام بالشيك',
      choose: 'لإختيار',
      cidadeInteresse: 'مدينة',
      city: 'مدينة',
      claims: 'المطالبات',
      clean: 'لينظف',
      click_and_know: 'انقر واكتشف',
      click_to_copy_the_wallet: 'انقر لنسخ المحفظة',
      client: 'عميل',
      clients: 'عملاء',
      close: 'إنهاء',
      cnh: 'CNH (ترخيص)',
      cnhDate: 'تاريخ إصدار CNH',
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ غير صالح',
      coin: 'عملة',
      color: 'لون',
      commercial: 'تجاري',
      company: 'اسم الشركة',
      complement: 'إطراء',
      comporRenda: 'هل ستجني المال مع شخص ما؟',
      compRenda: 'نوع إثبات الدخل',
      confirm_approve: 'تأكيد الموافقة',
      confirm_delete: 'تأكيد الحذف',
      confirm_new_password: 'تأكيد كلمة المرور الجديدة',
      confirm_new_password_security: 'تأكيد كلمة المرور الأمنية الجديدة',
      confirm_password: 'تأكيد كلمة مرور الوصول',
      confirm_password_security: 'تأكيد كلمة المرور الأمنية',
      confirm_your_new_password: 'أدخل كلمة المرور الجديدة مرة أخرى',
      confirmed: 'مؤكد',
      confirmed_email: 'البريد الإلكتروني المؤكد',
      congratulations: 'تهانينا',
      contact: 'اتصال',
      contract: 'عقد',
      copied: 'نسخ',
      copy: 'ينسخ',
      copy_success: 'تم النسخ بنجاح!',
      correspondences: 'مراسلة',
      countries: 'بلدان',
      country: 'دولة',
      cpf: 'CPF',
      cpf_invalid: 'رقم CPF غير صالح',
      create_order_success: 'تم إنشاء الطلب بنجاح!',
      created: 'مسجل في',
      cred: 'ائتمان',
      cred_binario: 'رصيد النقاط المزدوجة',
      cred_deb: 'الاعتمادات/الخصومات',
      cred_game_donate: 'لعبة التبرع بالائتمان',
      cred_ind_direta: 'رصيد الإحالة المباشر',
      cred_ind_indireta: 'رصيد الإحالة غير المباشر',
      cred_mandala: 'ماندالا الائتمان',
      cred_pagar_com_saldo: 'دفع الائتمان مع الرصيد',
      cred_pontos: 'نقاط الائتمان',
      cred_recb_trader: 'الائتمان تلقي التاجر',
      cred_renovacao: 'تجديد الائتمان',
      cred_saldo_disp: 'رصيد الائتمان المتاح',
      cred_upgrade: 'ترقية الائتمان',
      credit_card_payment: 'الدفع عن طريق بطاقة الائتمان',
      credit_card_payment_return: 'إعادة الدفع عن طريق بطاقة الائتمان',
      credit_card_receipt: 'إيصال بطاقة الائتمان',
      current: 'حاضِر',
      current_password: 'كلمة السر الحالية',
      current_wallet: 'المحفظة الحالية',
      dashboard: 'لوحة التحكم',
      data_added: 'البيانات المضافة',
      data_saved: 'البيانات المحفوظة',
      datas: 'بيانات',
      date: 'تاريخ',
      date_first_installment: 'تاريخ الدفعة الأولى',
      ddi: 'الرقم الدولي',
      deb_de_trader: 'ديون التاجر',
      deb_game_donate: 'لعبة التبرع بالخصم',
      deb_saldo: 'رصيد مدين',
      deb_saldo_disp: 'الرصيد المتاح للخصم',
      deb_saldo_pendent: 'الرصيد المدين المستحق',
      deb_val_indev: 'خصم المبلغ غير المستحق',
      debit_card_payment: 'الدفع عن طريق بطاقة الخصم',
      debit_card_payment_return: 'إعادة الدفع عن طريق بطاقة الخصم',
      debit_card_receipt: 'الاستلام عن طريق بطاقة الخصم',
      debt: 'دَين',
      debt_td: 'دَين',
      December: 'ديسمبر',
      decImpRenda: 'أعلن ضريبة الدخل',
      deleted_document: 'تم حذف المستند من السجل الخاص بك',
      department: 'قسم',
      dependents: 'المعالين',
      desc: 'إلى الأسفل',
      description: 'وصف',
      details: 'تفاصيل',
      dev_econom: 'النمو الإقتصادي',
      developer: 'مطور',
      directly_indicated: 'وأشار مباشرة',
      directly_indicateds: 'تمت الإشارة إليه مباشرة',
      directs: 'مباشر',
      district: 'الحي / المنطقة',
      divorced: 'مُطلّق',
      doa_men_receb: 'تم استلام التبرع الشهري للمنصة',
      doacao_td: 'هبة',
      doctorate_complete: 'دكتوراه - كاملة',
      doctorate_incomplete: 'دكتوراه - غير مكتملة',
      document: 'وثيقة',
      document_already_registered: 'الوثيقة مسجلة بالفعل',
      documents: 'وثائق',
      donate: 'يتبرع',
      donor: 'جهات مانحة',
      dont_know_zip: 'لا تعرف الرمز البريدي؟ انقر هنا',
      dou_fe: 'أؤكد أن المعلومات الواردة أعلاه صحيحة.',
      downloads: 'تحميل ملفات',
      drop_images: 'انقر أو اسحب الصور هنا',
      due_date: 'نضج',
      edit: 'للتعديل',
      edit_option: 'خيار التحرير',
      edit_question: 'تحرير السؤال',
      email: 'بريد إلكتروني',
      email_already_registered: 'البريد الإلكتروني مسجل مسبقا',
      email_and_or_password_wrong: 'البريد الإلكتروني و/أو كلمة المرور خاطئة!',
      email_for_login: 'سيكون هذا البريد الإلكتروني بمثابة تسجيل الدخول الخاص بك',
      email_or_password_wrong: 'بريد أو كلمة مرورغير صحيحة',
      employee: 'موظف',
      employees: 'موظفين',
      empty: 'فارغ',
      enter_your_new_password: 'أدخل كلمة المرور الجديدة',
      entrada: 'هل لها قيمة دخول؟ إذا كانت الإجابة بنعم، ما هي القيمة',
      entries_until_today: 'الإدخالات حتى الآن',
      error_confirm_password: 'يجب أن يكون التأكيد وكلمة المرور الجديدة متماثلين',
      error_email: 'تنسيق البريد الإلكتروني غير صالح',
      error_password: 'كلمة سر خاطئة',
      error_phone: 'رقم الهاتف غير صحيح',
      estadoInteresse: 'ولاية',
      estimate: 'مُقدَّر',
      exchange: 'إستبدال',
      expired: 'منتهي الصلاحية',
      extorno_td: 'انعكاس، ارتداد، انقلاب',
      extra: 'إضافي',
      extract: 'يستخرج',
      extracts: 'مقتطفات',
      fantasy: 'خيالي',
      fatherName: 'اسم الاب',
      February: 'شهر فبراير',
      fee: 'رسوم الانسحاب',
      feedback: 'يعود',
      feminine: 'المؤنث',
      fgts: 'قيمة FGTS الخاصة بك',
      field_required: 'الانتهاء الإلزامي',
      final: 'أخير',
      finance: 'مالي',
      financial: 'مالي',
      financial_payment: 'الدفع المالي',
      financial_payment_return: 'عودة الدفع المالي',
      financial_receipt: 'استلام التمويل',
      financing: 'التمويل',
      financings: 'التمويل',
      first_10_pages: 'أول 10 صفحات',
      fisica: 'شخص مادي',
      for_year: 'بالسنة',
      forget_password: 'لقد نسيت كلمة المرور',
      forgot_password: 'هل نسيت كلمة السر',
      form: 'استمارة',
      form_of_payment: 'طريقة الدفع',
      found: 'وجد',
      founds: 'وجد',
      free: 'حر',
      friend_indicated: 'أوصت به أصدقائي',
      friend_indicateds: 'أوصت به أصدقائي',
      fuel: 'وقود',
      full_name: 'الاسم الكامل',
      funcPublico: 'هو موظف عام',
      fundamental_complete: 'أساسي - كامل',
      fundamental_incomplete: 'الأساسية - غير مكتملة',
      ganh_diario: 'الأرباح اليومية',
      gender: 'جنس',
      grand_total: 'المجموع الإجمالي',
      group: 'مجموعة',
      has_withdraw_today: 'وقد تم بالفعل طلب الانسحاب اليوم',
      high: 'عالي',
      home: 'يبدأ',
      how_create_binance_apikey: 'كيفية إنشاء مفتاح API على Binance',
      how_it_works: 'كيف تعمل',
      how_to_call: 'ماذا تريد أن تسمى؟',
      id_td: 'الإحالة المباشرة',
      images: 'الصور',
      in_construction: 'تحت التشيد',
      in_order: 'مرتب',
      in_stock: 'في الأوراق المالية',
      indicant: 'مؤشر',
      indicated_by: 'تتم إحالتك من قبل',
      indicated_not_found: 'لم يتم العثور على المرجع المشار إليه...',
      indicateds: 'المرشحين',
      indirects: 'غير مباشر',
      info: 'النشرات الإخبارية',
      info_contact: 'معلومات الاتصال',
      initial: 'بيت',
      insert_new: 'أدخل جديد',
      installments: 'أقساط',
      intelligence: 'ذكاء',
      interesse: 'ما هي المنطقة ذات الاهتمام',
      invalid_contact: 'تنسيق جهة الاتصال غير صالح',
      invalid_images: 'صور غير صالحة',
      invalid_token: 'رمز غير صالح',
      invite: 'لدعوة',
      invite_friends: 'اعزم أصحابك',
      January: 'يناير',
      July: 'يوليو',
      June: 'يونيو',
      juridica: 'شخص قانوني',
      know_exact_result: 'هل تعرف القيمة الدقيقة؟',
      lead: 'معًا سوف نبني مستقبلًا جديدًا.',
      learn_more: 'تعرف أكثر',
      left: 'غادر',
      left_preferential: 'تفضيل الجانب الأيسر',
      legalNature: 'الطبيعة القانونية',
      level: 'مستوى',
      limite_80: 'لقد وصلت إلى {{perc}} من الحد المسموح به.\n قم بالتحسين وتجنب تعليق دخلك.',
      limite_excedido: 'تم تجاوز حد الخصم',
      linear: 'خطي',
      link_email: 'انقر على الرابط المرسل إلى البريد الإلكتروني المسجل!',
      link_invite_copy: 'تم نسخ رابط المشاركة بنجاح',
      liquid: 'سائل',
      list: 'قائمة',
      loading: 'تحميل',
      location: 'موقع',
      locked: 'محظور',
      login: 'للدخول',
      logout: 'للخروج',
      low: 'قليل',
      male: 'مذكر',
      manage_users: 'ادارة المستخدمين',
      manager: 'مدير',
      mandala: 'ماندالا',
      mandalas: 'المندالا',
      manufacturer: 'الصانع',
      March: 'يمشي',
      maritalStatus: 'الحالة الاجتماعية',
      market: 'سوق',
      married: 'متزوج',
      master_complete: 'درجة الماجستير - كاملة',
      master_incomplete: 'درجة الماجستير - غير مكتملة',
      max_payment: 'الحد الأقصى للدفع',
      May: 'يمكن',
      message: 'رسالة',
      messageNotSent: 'لم يكن من الممكن إرسال رسالتك',
      messageSent: 'تم ارسال رسالتك بنجاح',
      middle_complete: 'متوسطة - كاملة',
      middle_incomplete: 'متوسطة - غير مكتملة',
      min_amount_error: 'يجب أن تكون القيمة على الأقل:',
      min_withdraw: 'الحد الأدنى لمبلغ السحب',
      missing: 'مفتقد',
      mission: 'مهمة',
      mission_vision_values: 'الرسالة والرؤية والقيم',
      model: 'نموذج',
      monitors: 'الشاشات',
      monthly: 'الدفع الشهري',
      motherName: 'اسم الأم',
      movements: 'الحركات',
      msg_renovacao: 'لقد وصلت إلى حد الربح الخاص بك، قم بتجديد باقتك.',
      my_account: 'حسابي',
      my_wallet: 'محفظتى',
      mystock: 'مخزوني',
      name: 'اسم',
      nascMaisVelhoRenda2: 'ما هو تاريخ ميلاد أكبرهم؟',
      nationality: 'الجنسية (بلد الميلاد)',
      naturalness: 'مكان الميلاد (مدينة الميلاد)',
      nature: 'طبيعة',
      network: 'شبكة',
      new: 'جديد',
      new_call: 'مكالمة جديدة',
      new_financing: 'التمويل الجديد',
      new_order: 'طلب جديد',
      new_password: 'كلمة المرور الجديدة',
      new_password_security: 'كلمة مرور أمنية جديدة',
      new_seller: 'بائع جديد',
      new_wallet: 'محفظة جديدة',
      news: 'أخبار',
      next: 'التالي',
      nick: 'المستخدم (تسجيل الدخول)',
      nis: 'PIS/شيكل',
      nis_already_registered: 'PIS/NIS مسجل بالفعل',
      no: 'لا',
      no_approve: 'لم تتم الموافقة عليه بعد',
      no_delete: 'لا تحذف',
      no_literate: 'غير متعلم',
      no_repeat: 'لا يمكن تكرار البيانات',
      no_results_found: 'لم يتم العثور على نتائج',
      no_services_or_products_added: 'لم تتم إضافة أي خدمات أو منتجات',
      no_settings: 'انتقل إلى الإعدادات واملأ التفاصيل الخاصة بك.',
      none_friends_indicated: 'لم أقم بإحالة أي أصدقاء بعد',
      not_authorized: 'غير مخول',
      not_found: 'غير معثور عليه',
      not_login: 'غير قادر على تسجيل الدخول، يرجى التحقق من التفاصيل الخاصة بك وحاول مرة أخرى!',
      not_orders: 'لا توجد أوامر الدفع',
      not_permission: 'ليس لديك الصلاحية لدخول هذه الصفحة',
      not_registered: 'لم يتم تسجيلة بعد؟ انقر هنا وقم بالتسجيل',
      not_results: 'لا توجد سجلات',
      not_self_register: 'غير قادر على التسجيل',
      not_today_entry: 'مازلنا لم نحصل على التذاكر اليوم',
      notfound_ad: 'لم يتم العثور على إعلانات',
      November: 'شهر نوفمبر',
      number: 'رقم',
      obs: 'تعليقات',
      obs_pay:
        'إذا قام الوسيط الخاص بك بخصم رسوم من المبلغ المرسل، فيجب عليك إجراء التصحيح يدويًا عن طريق إضافة مبالغ هذه الرسوم.',
      October: 'اكتوبر',
      of: 'في',
      on_total: 'من المجموع الكلي',
      open: 'يفتح',
      opening: 'افتتاح',
      operation: 'أداء',
      ordenation: 'الطلب',
      order: 'طلب',
      order_book: 'سجل الطلبيات',
      order_not_alowed_to_pay: 'طلب غير مصرح به للدفع بالرصيد',
      order_not_found: 'لم يتم العثور على الطلب',
      order_payed: 'تم دفع الطلب رقم {{id}} بنجاح',
      orders: 'الطلبات',
      other: 'آخر',
      other_services_or_products: 'خدمات أو منتجات أخرى',
      paid: 'دفع',
      paid_date: 'يوم الدفع',
      partial: 'المدفوعة جزئيا',
      passport: 'جواز سفر',
      password: 'كلمة المرور',
      password_security: 'كلمة المرور الأمن',
      pay: 'يدفع',
      pay_exact: 'ادفع المبلغ بعملة {{coin}}',
      pay_generate: 'توليد الدفع',
      pay_selected: 'الدفع المحدد',
      pay_value_in: 'دفع المبلغ في',
      pay_with_balance: 'الدفع بالرصيد',
      payable: 'يدفع',
      payer: 'دافع',
      payment: 'قسط',
      ped_retirada: 'طلب سحب',
      ped_retirada_gan_diario: 'طلب سحب الأرباح اليومية',
      pendent: 'قيد الانتظار',
      pendent_order: 'لديك أوامر معلقة',
      people: 'الناس',
      person: 'شخص',
      person_found_in_other_prefecture: 'شخص مسجل بالفعل في بلدية أخرى',
      personal: 'شباب',
      personal_addresses: 'العناوين',
      personal_contacts: 'جهات الاتصال',
      personal_data: 'بيانات شخصية',
      personal_infos: 'معلومة',
      personal_pcva: 'برنامج البيت الأخضر والأصفر',
      phone: 'رقم هاتف',
      photo: 'تصوير',
      pix: 'بيكس',
      pix_payment: 'الدفع بالبيكس',
      pix_payment_return: 'إعادة الدفع في PIX',
      pix_receipt: 'الاستلام في PIX',
      pl_carreira: 'المسار المهني',
      plan: 'مستوي',
      plates: 'لوحات',
      please_wait: 'انتظر من فضلك',
      plus: 'زائد',
      point: 'نقطة',
      points: 'نقاط',
      portfolio: 'مَلَفّ',
      pos_complete: 'بالمعنى اللاتيني - كامل',
      pos_incomplete: 'بالمعنى اللاتيني - غير مكتمل',
      prefecture: 'بلدية',
      prefecture_addresses: 'العناوين',
      prefecture_contacts: 'جهات الاتصال',
      prefecture_data: 'بيانات قاعة المدينة',
      prefecture_infos: 'معلومة',
      prefectures: 'قاعات المدينة',
      prev: 'سابق',
      price: 'سعر',
      print: 'اطبع',
      privacy_policies: 'سياسات الخصوصية',
      private_works: 'أعمال خاصة',
      profession: 'مهنة',
      profile: 'حساب تعريفي',
      program: 'برنامج',
      programs: 'برمجة',
      project: 'مشروع',
      projects: 'المشاريع',
      public_works: 'الأشغال العامة',
      purchase: 'شراء',
      qualify_binary: 'مؤهل النقاط المزدوجة',
      qualquer: 'أي',
      quantity: 'كمية',
      quotation: 'سعر',
      read: 'ليقرأ',
      receivable: 'لاستقبال',
      recipient: 'المتلقي',
      recommendations: 'التوصيات',
      redefine_password: 'إعادة تعريف كلمة المرور',
      register: 'سِجِلّ',
      register_people: 'تسجيل الأشخاص',
      register_wallet: 'قم بتسجيل محفظة لإجراء عمليات السحب',
      registers: 'السجلات',
      registers_users: 'تسجيلات المستخدم',
      reject: 'يرفض',
      remaining: 'متبقي',
      remove: 'لازالة',
      rendaIndividual: 'الدخل الإجمالي الفردي',
      renew: 'تجديد',
      renew_mandala: 'تجديد ماندالا',
      reply: 'ليجيب',
      reports: 'التقارير',
      required_data_not_sent: 'لم يتم إرسال البيانات المطلوبة',
      resend_email: 'إعادة إرسال البريد الإلكتروني',
      residential: 'سكني',
      responded: 'أجاب',
      restricao: 'هل لديك قيود على اسمك؟ (توافق آراء ساو باولو/سيراسا)',
      result: 'نتيجة',
      results: 'نتائج',
      resume_data: 'ملخص البيانات',
      revoked: 'ألغيت',
      rg: 'التسجيل العام (الهوية)',
      rgAgency: 'مصدر RG',
      rgDate: 'تاريخ الإصدار RG',
      rgUf: 'دولة آر جي',
      right: 'يمين',
      right_preferential: 'تفضيل الجانب الأيمن',
      roi: 'الأرباح اليومية',
      sale: 'أُوكَازيُون',
      saq_duplicado: 'انسحاب مزدوج',
      save: 'للحفظ',
      schooling: 'تعليم',
      search: 'يبحث',
      secretary: 'سكرتير',
      security_password: 'كلمة المرور الأمن',
      see: 'لترى',
      see_advents: 'شاهد الإعلانات',
      see_call: 'عرض المكالمة',
      see_details: 'انظر التفاصيل',
      see_details_wallet: 'عرض تفاصيل المحفظة',
      see_financing: 'عرض التمويل',
      see_order: 'مشاهدة الطلب',
      see_user: 'عرض المستخدمين',
      select_vehicle: 'اختر مركبة',
      self_register: 'يسجل',
      sell_volume: 'حجم المبيعات',
      seller: 'تاجر',
      send_call: 'إرسال مكالمة',
      send_to: 'ارسل إلى',
      September: 'سبتمبر',
      settings: 'إعدادات',
      share_your_link: 'مشاركة الرابط الخاص بك',
      show: 'عرض حتى',
      show_from: 'العرض من',
      side: 'جانب',
      sign_in_to_start_your_session: 'قم بتسجيل الدخول لبدء جلستك',
      sign_up: 'يسجل',
      single: 'أعزب',
      sold: 'مُباع',
      state: 'ولاية',
      status: 'حالة',
      strategies: 'الاستراتيجيات',
      street: 'طريق',
      subject: 'موضوع',
      submit: 'لترسل',
      submit_placeholder: 'يرجى ملء البريد الإلكتروني الخاص بك',
      subscribe: 'قم بالتسجيل لتلقي أخبارنا',
      subtitle: 'التسمية التوضيحية',
      success_update_user: 'تم تحديث المستخدم بنجاح!',
      success_wallet: 'تم تحديث المحفظة بنجاح!',
      suggestions: 'اقتراحات',
      superior_complete: 'تخرج',
      superior_incomplete: 'غير مكتمل أعلى',
      supervisor: 'مشرف',
      support: 'يدعم',
      support_materials: 'مواد الدعم',
      suspended: 'معلق',
      symbol: 'رمز',
      symbols: 'حرف او رمز',
      system: 'نظام',
      target: 'هدف',
      telegram_chat_id: 'معرف دردشة برقية',
      tempoCarteira: 'منذ متى وأنت مسجل في محفظتك؟',
      terms: 'أقبل شروط الاستخدام',
      text_hint_binance:
        'عند إنشاء مفتاح واجهة برمجة التطبيقات على Binance، أدخل عنوان IP في قيود الوصول:\n172.105.78.139\nثم حدد الخيارات:\n تمكين الخيارات الأوروبية\n تمكين التداول الفوري والهامش',
      this_value_in: 'هذه القيمة في',
      ticker_health: 'تيكر هيلث',
      title: 'عنوان',
      to: 'حتى',
      to_pay: 'لإيقاف',
      to_receive: 'مستحق',
      today_entry: 'دخول اليوم',
      token_not_found_or_already_used: 'لم يتم العثور على الرمز المميز أو تم استخدامه بالفعل',
      total: 'المجموع',
      total_entry: 'إجمالي الدخول',
      traderbot: 'تاجر بوت',
      transf_credito: 'رسوم التحويل',
      transf_debito: 'تحويل الخصم',
      transfer_payment: 'الدفع عن طريق التحويل',
      transfer_payment_return: 'إعادة الدفع عن طريق التحويل',
      transfer_receipt: 'الاستلام في التحويل',
      transparency: 'الشفافية',
      tx_adm_cred: 'إدارة الضرائب',
      tx_adm_deb: 'إدارة الضرائب',
      tx_retirada: 'رسوم الانسحاب',
      tx_retirada_gan_diario: 'رسوم سحب الأرباح اليومية',
      type: 'يكتب',
      update: 'للتحديث',
      update_wallet: 'تحديث المحفظة',
      upgrade: 'تحسين',
      used: 'مستخدم',
      user: 'مستخدم',
      user_active: 'مستخدم نشط',
      user_already_exists: 'المستخدم موجود اصلا!',
      user_and_or_password_wrong: 'اسم المستخدم و / أو كلمة المرور خاطئة!',
      user_inactive: 'مستخدم غير نشط',
      user_not_found: 'لم يتم العثور على المستخدم',
      user_not_logged: 'لم يقوم المستخدم بتسجيل الدخول',
      user_or_password_wrong: 'اسم المستخدم أو كلمة المرور غير صحيحة',
      users: 'المستخدمين',
      users_list: 'قائمة المستخدم',
      valid_images: 'صور صالحة',
      valorRenda2: 'ما هي قيمة الدخل الثاني',
      value: 'قيمة',
      value_donate_on: 'قيمة التبرع في',
      value_financed: 'المبلغ الممول',
      value_installment: 'قيمة القسط',
      values: 'قيم',
      vehicle: 'عربة',
      vehicles: 'مركبات',
      view_order: 'مشاهدة الطلب',
      vision: 'رؤية',
      visitor: 'زائر',
      voucher: 'فاتورة',
      wait_confirm: 'في انتظار التأكيد',
      waiting: 'منتظر',
      waiting_email: 'تم التسجيل بنجاح، الآن كل ما عليك فعله هو تأكيد بريدك الإلكتروني.',
      waiting_payment: 'انتظار الدفع',
      wallet: 'مَلَفّ',
      wallet_generate: 'إنشاء المحفظة',
      wallets: 'محافظ',
      want_donate: 'أريد التبرع',
      want_now_more_about: 'أريد أن أعرف المزيد عنها',
      warning: 'انتباه',
      we_are: 'نكون',
      webhooks: 'خطافات الويب',
      welcome: 'مرحباً',
      who_we_are: 'من نحن',
      who_we_are_subtitle: 'وصف موجز لل',
      widower: 'أرمل',
      will_expire_in: 'سوف تنتهي في',
      with: 'مع',
      withdraw: 'انسحاب',
      withdraw_sended_email: 'تم طلب السحب، تحقق من بريدك الإلكتروني وانقر على الرابط للتفويض!',
      withdrawal: 'عمليات السحب',
      withdrawals: 'عمليات السحب',
      year: 'سنة',
      years: 'سنين',
      yes: 'نعم',
      you_have: 'لديك',
      your_country: 'بلدك',
      your_dashboard: 'هذه هي لوحة التحكم الخاصة بك.',
      your_link: 'الرابط التابع',
      zipcode: 'الرمز البريدي',
      withdrawal_sended: 'تم إرسال السحب',
      withdrawal_pending: 'تم طلب السحب بالفعل! \nيرجى انتظار الدفع لإجراء عملية سحب أخرى.',
      limite_80_top: 'لقد وصلت إلى {{perc}} الحد المسموح به.\n توقع التجديد وتجنب تعليق دخلك.',
      game: 'لعبة',
      games: 'ألعاب',
      winning_numbers: 'الأرقام المرسومة',
      your_numbers: 'أرقامك',
      add_numbers: 'إضافة أرقام',
      next_draw: 'السحب القادم',
      can_bet_until: 'يمكنك المراهنة على ما يصل إلى',
      your_bets: 'الرهانات الخاصة بك',
      add_bet: 'أضف الرهان',
      bet: 'رهان',
      bets: 'الرهانات',
      numbers: 'أعداد',
      special_numbers: 'أرقام مميزة',
      resume: 'ملخص',
      choosed_numbers: 'أرقام مختارة',
      choosed_special_numbers: 'تم اختيار أرقام مميزة',
      bets_finished: 'الرهانات المستقرة',
      prize_draw: 'جائزة السحب',
      balance_network: 'توازن الشبكة',
      telegram: 'برقية',
      logs: 'السجلات',
      release_withdrawals: 'الافراج عن الانسحابات',
      bot_active: 'الافراج عن بوت',
      available: 'متاح',
      sunday: 'الأحد',
      monday: 'ثانية',
      tuesday: 'ثالث',
      wednesday: 'الرابع',
      thursday: 'الخامس',
      friday: 'جمعة',
      saturday: 'السبت',
      title_binary: 'قم بتعيين نسب النقاط المزدوجة لكل يوم',
      ok_perc_change: 'تم تغيير النسب بنجاح',
      cred_deb_title: 'الرصيد الائتماني أو المدين',
      send: 'لترسل',
      voucher_pendent: 'قسيمة معلقة',
      leader_pendent: 'الزعيم المنتظر',
      voucher_approved: 'تمت الموافقة على القسيمة',
      leader_approved: 'القائد المعتمد',
      no_binary_at_today: 'لم يتم تسجيل Dual Point في ذلك اليوم',
      generate_file: 'إنشاء ملف',
      withdrawals_file_created: 'تم إنشاء ملف السحب',
      withdrawals_sended: 'تم إرسال طلبات السحب',
      see_generated_files: 'عرض الملفات التي تم إنشاؤها',
      files: 'ملفات',
      file: 'ملف',
      verify: 'للتأكد',
      rescue: 'ينقذ',
      awarded: 'منحت',
      rescued: 'تم إنقاذه',
      repeat: 'يكرر',
      draw_date: 'تاريخ السحب',
      bet_date: 'تاريخ الرهان',
      drawn_numbers: 'الأرقام المرسومة',
      awardeds_numbers: 'أرقام الجائزة',
      no_award: 'لا جائزة',
      you_hit_number: 'لقد حصلت على رقم {{qty}} بشكل صحيح',
      you_hit_numbers: 'لقد حصلت على {{qty}} أرقام صحيحة',
      lottery: 'اليانصيب',
      lotteries: 'اليانصيب',
      balls: 'كرات',
      special_balls: 'العروض الخاصة',
      levy: 'مجموعة',
      major_awarded: 'المزيد من الكرات',
      hits: 'الزيارات',
      balance_game: 'التوازن للألعاب',
      your_balance: 'رصيدك',
      your_balance_total: 'رصيدك الإجمالي',
      game_package: '{{qty}} عدد (يمكن الفوز بـ {{prize}} مرة)',
      choose_package: 'اختر الحزمة',
      error_bet: 'غير قادر على إرسال الرهان',
      do_complete: 'لإكمال',
      success_bet: 'تم إرسال الرهان',
      you_bet_number: 'أنت تراهن بأرقام {{qty}}.',
      winner_msg: 'وفاز بـ {{prize}} مرة',
      prize: 'جائزة',
      balance_not_enough: 'رصيد غير كاف',
      addbalance: 'إضافة رصيد إلى الألعاب',
      convertbalance: 'تحويل الأرصدة',
      convertbalance_title: 'تحويل الرصيد المتاح إلى رصيد اللعبة',
      can_award: 'يمكنك الفوز',
      emission: 'انبعاث',
      validation: 'تصديق',
      participant: 'مشارك',
      concourse: 'منافسة',
      draw_hours: 'رسم الوقت',
      qty_dezenas: 'كمية العشرات',
      bet_amount: 'مبلغ الرهان',
      share: 'للمشاركه',
      shared: 'جاهز للمشاركة',
      conversion: 'التحويل بين الأرصدة',
      better: 'مستشار الرهان',
      lottery_consultant: 'مستشار مشارك',
      consultant_indication: 'تعيين استشاري',
      betting_commission: 'لجنة زيادة رصيد اللعبة',
      be_a_consultant: 'كن مستشارًا لليانصيب واكسب المزيد',
      msg_be_a_consultant:
        'قسم الربح العالمي في Lotter.\nمكافأة الإحالة (على مبيعات الحزمة)\nمكافأة النقاط المزدوجة\nوأكثر من ذلك بكثير.',
      auto_renovations: 'التجديدات التلقائية',
      auto_renove_binary: 'التجديد الذاتي للنقطة المزدوجة',
      auto_renove_quotas: 'التجديد الذاتي للأهداف (220%)',
      auto_renove_anual: 'التجديد الذاتي السنوي',
      off: 'عن',
      on: 'متصل',
      change_success: 'تغيرت بنجاح',
      anual: 'سنوي',
      only_monday: 'ستكون عمليات السحب متاحة كل يوم اثنين',
      transferency: 'تحويل',
      max_amount: 'القيمة القصوى',
      info_better:
        '- يتلقى مستشار الرهان القيمة الإجمالية للحزمة في أرصدة الرهان.\n\n- يُسمح بإجراء ترقيات إلى حزم الرهان عن طريق دفع الفرق فقط واستلام الفرق في أرصدة الرهان الجديدة.\n\n- الترقيات مسموح بها أيضًا في الحزم المرتبطة عن طريق دفع حزمة جديدة بالقيمة الكاملة ومن هناك تتلقى مشاركة الأرباح.\n\n- يمكن إجراء التجديدات والتخفيضات باستخدام حزم Apostador أو الحزم المرتبطة إذا كانت متوفرة.',
      info_consultant:
        '- يقوم المستشار المساعد بتمرير تذاكر الرهان من مجموعته إلى Lotter Pro للتفاوض مع قاعدة عملائه، ويتلقى ما يصل إلى 1% على الحزمة المرتبطة المختارة في أيام العمل كمشاركة للأرباح.\n\n- يُسمح بإجراء ترقيات إلى الحزم المرتبطة عن طريق دفع الفارق فقط، ويصبح سقف المشاركة الجديد بنسبة 220% هو القيمة الجديدة للحزمة المرتبطة مع خصم ما تم استلامه بالفعل من جميع المكافآت حتى ذلك الحين.\n\n- يُسمح بالترقيات في الحزم الأفضل بحلول دفع حزمة جديدة بالقيمة الكاملة ومن هناك تتلقى القيمة الإجمالية للحزمة في أرصدة الرهان.\n\n- يمكن إجراء التجديدات والتخفيضات باستخدام حزم Bettor Packs أو Associate Packs.\n\n- الحزم والترقيات والتجديدات يجب أن يتم تنفيذ أكثر من 500 دولار حصريًا في الحزم المرتبطة.',
      confirm: 'يتأكد',
      send_new_token: 'بمجرد إرسال التأكيد إلى بريدك الإلكتروني، انقر على بريدك الإلكتروني للتأكيد',
      email_resended: 'تم إرسال التأكيد إلى بريدك الإلكتروني، اضغط على بريدك الإلكتروني للتأكيد',
      expired_send_new_token: 'تم إرسال تأكيد جديد إلى بريدك الإلكتروني، اضغط على بريدك الإلكتروني للتأكيد',
      become_a_consultant: 'قم بالترقية واحصل على المزيد',
      register_ok: 'مسجل بنجاح',
      ticket_number: 'رقم التذكرة',
      mensal_activation: 'تفعيل الشهري',
      conversion_to_bet: 'التحويل إلى الرهان',
      conversion_balance_games: 'تحويل الرصيد إلى رصيد اللعبة',
      conversion_balance_lotterprocoin: 'تحويل الرصيد إلى التنشيط',
      conversion_lotterprocoin_games: 'التنشيط لتحويل رصيد اللعبة',
      conta_corrente: 'الحساب الحالي',
      balance_games: 'توازن اللعبة',
      add_balance_games: 'إضافة رصيد إلى الألعاب',
      transf_debit_lotterProCoin: 'تحويل رصيد التنشيط',
      deb_saldo_lotterProCoin: 'الخصم من رصيد التنشيط',
      transf_credit_lotterProCoin: 'تحويل الرصيد إلى التنشيط',
      live_now: 'البث المباشر الآن',
      offline: 'غير متصل: في انتظار الرسم',
      tutorials: 'البرامج التعليمية',
      tutorial_created: 'تم إنشاء البرنامج التعليمي',
      english: 'الإنجليزية',
      spanish: 'الأسبانية',
      portuguese: 'البرتغالية',
      select_language: 'اختر اللغة',
      msg_renove_binary:
        'لقد أكملت بالفعل شهرًا آخر من آخر تنشيط شهري وليس لديك تنشيط شهري تلقائي.\nولهذا السبب أصبحت نقاطك المزدوجة غير نشطة.\nادفع طلبًا لإضافة رصيد اللعبة بمبلغ 25.00 دولارًا لتنفيذ التنشيط الشهري .',
      choose_side_preferential: 'اختر الجانب المفضل (حاليًا: {{side}})',
      count: 'العدد',
      deb_balance_games: 'الخصم من رصيد اللعبة',
      cred_saldo_lotterProCoin: 'الدفع لرصيد التنشيط',
      historics: 'التاريخ',
      balance_lotterProCoin: 'توازن التنشيط',
      msg_binary_tree_1: 'اضغط على المستخدم المطلوب لمدة ثانية واحدة لرؤية المعلومات',
      msg_binary_tree_2: 'انقر على المستخدم المطلوب مرة واحدة لرؤية المستخدم التالي في الملف الثنائي.',
      change_security_password: 'تغيير كلمة المرور الأمنية',
      info_pass_security: 'يتم استخدام كلمة المرور الأمنية لتنفيذ المعاملات المالية',
      redefine_security_password: 'تعيين كلمة مرور جديدة',
      invalid_wallet: 'المحفظة غير صالحة',
      valid_wallet: 'المحفظة صالحة',
      cpf_account:
        'من الضروري إبلاغ CPF بصاحب الحساب البنكي الذي سيقوم بالدفع.\n\nستتم إعادة توجيهك بعد ذلك إلى صفحة تسجيل بوابة الدفع.\n\nبعد اكتمال التسجيل، ارجع إلى صفحة LOTTER PRO هذه وانقر فوق إنشاء QR CODE PIX.',
      confirm_email: 'قم بتأكيد بريدك الإلكتروني',
      error_confirm_email: 'البريد الإلكتروني المقدم غير متطابق',
      info_choose_package: 'قم بشراء إحدى الباقات المتوفرة لتفعيلها وجمع النقاط وإلا سيتم حذفها خلال 15 يوماً',
      title_info_register: 'اقرأ بعناية قبل تأكيد التسجيل',
      info_register:
        '- تتراكم النقاط فقط بعد تفعيل الحد الأدنى 10 دولارات \n- يتم دفع النقاط المزدوجة في تمام الساعة 00:00 بتوقيت لندن يوميًا \n- تنشيط النقاط المزدوجة هو حسابان بقيمة 25 دولارًا على كل جانب \n- قم بتأكيد الجانب المنسكب من نقطتك المزدوجة في المكتب \n ن- قبل التسجيل قم بتأكيد المعلومات المذكورة أعلاه',
      no_binaries: 'لا يمكن عرض شبكة هذا المستخدم',
      register_gateway: 'انقر هنا للتسجيل في بوابة الدفع',
      packs_better: 'حزم عضوية مستشار الرهان (احصل على أرصدة للرهانات)',
      packs_consultant: 'حزم عضوية المستشارين المساعدين (تتلقى مشاركة الأرباح)',
      read_choose: 'اقرأ بعناية واختر الحزمة الأكثر ملاءمة لك',
      footer_choose: 'تتضمن جميع الباقات المشاركة في مزايا برنامج Lotter Pro Affiliate',
      day: 'يوم',
      days: 'يوم',
      olds: 'القديمة أولا',
      recents: 'الأخيرة أولا',
      accept_title: 'لإجراء الدفعات عبر PIX، يجب قبول الشروط الواردة أدناه',
      acept1:
        'أدرك أن SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 هي شركة مسجلة في البرازيل، وأنها تمتثل لجميع قواعد البنك المركزي والإيرادات الفيدرالية، حيث يتم تنفيذ جميع عمليات شراء أو بيع USDT. وتكون مصحوبة بفاتورة ويتم إبلاغها إلى هيئة الإيرادات الفيدرالية في البرازيل، وفقًا لما تحدده التعليمات المعيارية 1888/19.',
      acept2:
        'أدرك أن SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 لا تقدم أي خدمات أو منتجات استثمارية وأن النشاط يقتصر على شراء أو بيع USDT.',
      acept3:
        'أوافق على مشاركة بياناتي الشخصية مع SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 حتى تتمكن من تنفيذ خدمة شراء أو بيع USDT-Polygon.',
      acept4:
        'أدرك وأوافق على أنني أشتري USDT من خلال SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 وأسمح بإرسال التشفير إلى عنوان المحفظة 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc الذي يحتفظ به: \nSMART PAY INTERMEDIACOES | 42.913.912/0001-02 \nالعنوان: Av.: Paulista, N.° 171 - Complement: PAVMTO4 and 3 VG - Bela Vista - 01311.904 - São Paulo - SP \nالبريد الإلكتروني: Smartpayintermediacoes@gmail.com',
      acept5:
        'أعفي SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 من أي مسؤولية تجارية عن المنتج أو الخدمات التي يقدمها هذا الموقع و/أو الشركة التي تحمل عنوان USDT.',
      acept6:
        "أوافق على أنه من خلال دفع Pix QRCODE الذي تم إنشاؤه أدناه، سأوافق على شروط شراء أو بيع USDT من SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 وأنه سيتم تسجيل العملية على Polygon's Blockchain بإرسال معرف عملية البنك المركزي البرازيلي (من طرف إلى طرف) في حقل الرسالة إلى العنوان 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc",
      aceptAlert:
        'عندما تنقر على التحقق من الصحة، سيتم إنشاء مفتاح Pix بقيمة عشوائية تتراوح بين 0.10 ريال برازيلي و0.99 ريال برازيلي.\n\nيجب إجراء هذا الدفع للتحقق من صحة تسجيلك في SmartPay Serviços Digitais Ltda.',
      validate: 'التحقق من الصحة (سيتم إجراء هذه الرسوم مرة واحدة فقط).',
      modal_acpet_title: 'هل تدفع لأول مرة باستخدام PIX ​​في LOTTER PRO؟',
      only_time: 'سيتم تنفيذ هذه العملية مرة واحدة فقط.\nسيتم التحقق من صحة الدفعات الأخرى عبر PIX تلقائيًا.',
      terms_smartpay: 'أوافق على شروط وأحكام Smartpay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96',
      success_activation: 'لقد تم التحقق من صحة حسابك بنجاح ويمكنك الآن إجراء الدفعات عبر PIX',
      warning1: 'لا يتم الدفع مقابل طلبك',
      warning2:
        'هذا هو رمز الاستجابة السريعة للتحقق من صحة تسجيلك لدى SmartPay Serviços Digitais Ltda وللتمكن من إجراء الدفعات عبر PIX. \n هذه ليست دفعة مقابل طلبك.\n بمجرد تأكيد الدفع، ارجع إلى صفحة LOTTER PRO هذه وانقر على إنشاء رمز QR PIX لإنشاء رمز QR للدفع عبر PIX لطلبك.',
      only_brazil: 'فقط في البرازيل',
      changeUserGroup: 'تغيير الفئة',
      alert_change_category:
        'إذا قمت بتغيير الفئة، فإن الحزمة الحالية الخاصة بك لن تكون صالحة بعد الآن.\nلن تتلقى المكاسب من الحزمة الحالية بعد الآن.\nسوف تتلقى الآن المكاسب من الحزمة الجديدة الخاصة بك.\nسيتم إعادة تعديل حد الفوز الخاص بك إلى 220% من باقتك الجديدة.\nلن تتغير أرصدتك.\n\nهل أنت متأكد من رغبتك في تغيير الفئة؟',
      exceeded: 'تجاوزت',
      vinculate_whatsapp: 'ارتباط الواتساب',
      verification_code: 'رمز التحقق',
      verification_msg: 'أدخل رمز التحقق الذي تم إرساله إلى الواتساب الخاص بك',
      balance_awards: 'رصيد الجائزة',
      addprocoin: 'إضافة رصيد التنشيط',
      withdrawal_awards: 'سحب الجائزة',
      conversion_awards_games: 'تحويل رصيد الجائزة إلى رصيد اللعبة',
      last_draws: 'أحدث السحوبات',
      today: 'اليوم',
      yesterday: 'أمس',
      tomorrow: 'غداً',
      others: 'آحرون',
      draws: 'سحوبات',
      learn_more_text:
        'اختر بين {{choose}} العشرات \nواربح من {{rangeWin.min}} إلى {{rangeWin.max}} أضعاف مبلغ الرهان \nالذي يطابق {{win}} العشرات المسحوبة.',
      or: '  أو',
      good_luck: 'حظ سعيد!',
      add_balance_awards: 'إضافة رصيد الجائزة',
      deb_balance_awards: 'الخصم من رصيد الجائزة',
      key_pix: 'المفتاح_PIX',
      choose_game: 'اختر لعبة',
      do_bet: 'إلى الرهان',
      response_bets_lot: 'استجابة الكثير الرهان',
      lottery_not_found: 'لم يتم العثور على اليانصيب',
      lottery_expired: 'اليانصيب منتهية الصلاحية',
      amount_invalid: 'قيمة غير صالحة',
      balance_game_not_enough: 'رصيد غير كاف',
      game_package_not_found: 'لم يتم العثور على حزمة اللعبة',
      please_choose_other_tens: 'الرجاء اختيار عشرات أخرى',
      continue_beting: 'مواصلة الرهان',
      balls_invalid: 'أرقام غير صالحة',
      max_bet: 'الحد الأقصى لقيمة الرهان \n يعتمد على عدد العشرات',
      copy_paste: 'ألعاب متعددة',
      amount_copy_paste: 'قيمة كل رهان',
      list_copy_paste: 'قائمة الرهانات المرغوبة',
      total_valid: 'مجموع صالح',
      erase_my_account: 'حذف حسابي',
      erase_alert_caution:
        'هذه العملية لا يمكن التراجع عنها. هل انت متأكد انك تريد حذف حسابك؟ \nاكتب "احذف حسابي" للتأكيد',
      success_delete_account: 'تم حذف حسابك بنجاح',
      age_major:
        'قبل المتابعة، نحتاج إلى التأكد من أن عمرك 18 عامًا أو أكثر. وفقًا للقوانين واللوائح، تُحظر المقامرة على الأشخاص الذين تقل أعمارهم عن 18 عامًا.\n\nيُرجى النقر فوق مربع الاختيار أدناه للتأكد من أن عمرك 18 عامًا أو أكثر.',
      age_major_button: 'أؤكد أن عمري 18 عامًا أو أكثر',
      title_age_major: 'تأكيد العمر',
      about_us:
        'نحن في {{company}} نؤمن بأنه يجب أن تتاح للجميع الفرصة لتحقيق أحلام كبيرة. ولهذا السبب نقدم لك الفرصة للمشاركة في أكبر اليانصيب حول العالم، أينما كنت. \n\nنحن نفخر بالعمل وفقًا لأعلى المعايير الأخلاقية والقانونية.\nإن التزامنا بالشفافية والسلامة والمساءلة يضمن الثقة لتوفير تجربة ألعاب عادلة وممتعة.',
      site_map: 'خريطة الموقع',
      kyc: 'التحقق من الهوية (KYC)',
      kyc_welcome: 'للتحقق من هويتك، يرجى تحميل الصور أدناه:',
      kyc_msg:
        'عندما تتم الموافقة على التحقق من الهوية، ستتلقى رسالة تأكيد عبر البريد الإلكتروني وسيتم إطلاق جميع وظائف {{company}}.',
      kyc_error: 'حدث خطأ أثناء تحميل الصور، يرجى المحاولة مرة أخرى',
      driving: 'رخصة السائق',
      identity: 'هوية',
      front: 'أمام',
      front_msg: 'صورة لواجهة الوثيقة',
      back_doc: 'بيت شعر',
      back_doc_msg: 'صورة للجزء الخلفي من الوثيقة',
      selfie: 'صورة شخصية',
      selfie_msg: 'صورة شخصية تحمل وثيقة',
      kyc_pending: 'في انتظار التحقق',
      kyc_rejected: 'تم رفض التحقق',
      kyc_approved: 'تم التحقق',
      two_fa: 'المصادقة الثنائية (2FA)',
      two_fa_msg: 'للتأكد من أن وصولك أكثر أمانًا، يرجى تمكين المصادقة الثنائية (2FA).',
      two_fa_step1: 'اقرأ رمز الاستجابة السريعة باستخدام تطبيق المصادقة الخاص بك (Google Authenticator، Authy، إلخ).',
      two_fa_step2: 'أدخل رمز التحقق الذي أرسله لك تطبيق المصادقة.',
      two_fa_activate: 'تمكين المصادقة الثنائية (2FA)',
      two_fa_deactivate: 'تعطيل المصادقة الثنائية (2FA)',
      two_fa_activated: 'لقد تم تمكين المصادقة الثنائية (2FA)، \n لديك الآن طبقة إضافية من الأمان.',
      two_fa_verify: 'التحقق من المصادقة الثنائية (2FA)',
      yout_bets: 'المراهنات الخاصة بك',
      draw_due: 'الحد الأقصى للرهان',
      close_game: 'لعبة قريبة',
      draw_due_greater_than_draw_date: 'يجب أن يكون حد الرهان على الأقل {{timeLimit}} قبل السحب',
      draw_date_less_than_now: 'يجب أن يكون تاريخ السحب أكبر من التاريخ الحالي بما لا يقل عن {{timeLimit}} دقيقة',
      simulation: 'محاكاة',
      valuePay: 'المبلغ المطلوب دفعه',
      quotas_paid_success: 'الدفع الناجح',
      paids_today: 'المدفوعات التي تمت اليوم',
      no_paids_today: 'لم يتم سداد أي مدفوعات اليوم',
      perc: 'نسبة مئوية',
      define_percent: 'تعيين النسبة المئوية',
      success_update: 'تم التحديث بنجاح',
      define_percent_next_payment: 'تعيين النسبة المئوية للدفع التالي',
      create: 'إنشاء',
      doclose: 'غلق',
      lottery_updated: 'لعبة تم تحديثها',
      lottery_created: 'لعبة تم انشاءها',
      award_packages: 'المكافآت',
      qty: 'كمية',
      multiplier: 'مضاعف',
      maxValue: 'القيمة الأعلى',
      limitRepeat: 'الحد الأقصى للتكرار',
      draw_day: 'يوم السحب',
      hour: 'ساعة',
      minutes: 'دقيقة',
      ytb_channel_id: 'معرف قناة YouTube',
      tutorial: 'درس',
      video_url: 'رابط الفيديو',
      tutorial_deleted: 'تم حذف الدرس',
      tutorial_error_delete: 'خطأ في حذف الدرس',
      tutorial_updated: 'تم تحديث الدرس',
      tutorial_error_update: 'خطأ في تحديث الدرس',
      language: 'لغة',
      live_hours: 'البث المباشر في الساعة',
      exact: 'بالضبط',
      partiallly: 'جزئي',
      closed: 'مغلق',
      statistics: 'الاحصائيات',
      your_link2: 'رابط المراهنة',
      deb_20_to_games: 'نقل إلى رصيد اللعبة',
      cred_20_to_games: 'نقل إلى رصيد اللعبة',
      transf_debit_games: 'لعبة تحويل الرصيد',
      transf_credit_games: 'نقل إلى رصيد اللعبة',
      file_too_large: 'الملف كبير جدا. الحد الأقصى: {{max}}',
      kyc_warning: 'أكمل التحقق من الهوية للمراهنة بما يصل إلى 100 دولار لكل لعبة',
      msg_rules_footer:
        '1. هذا المنتج مخصص للاستخدام من قبل المستخدمين الذين تزيد أعمارهم عن 18 عامًا وهو مخصص للأغراض الترفيهية فقط.\n 2. يحتوي هذا الموقع على عمليات شراء داخل التطبيق. \n 3. حقيقة أن اللاعب يلعب أو يفوز في لعبة المراهنة الاجتماعية لا تعني أنه سيفوز في المراهنة بأموال حقيقية والألعاب ذات الصلة في المستقبل.',
      terms_conditions: 'الأحكام والشروط',
      aml_policy: 'سياسة مكافحة غسيل الأموال',
      kyc_policy: 'سياسة التحقق من الهوية',
      self_exclusion_policy: 'سياسة الاستبعاد الذاتي',
      responsible_gambling_policy: 'سياسة الألعاب المسؤولة',
      clause_18: 'سياسة الألعاب الثانوية (18+ شرط اللعب)',
      policies: 'سياسات',
      join_us: 'انضم إلى مجتمعنا',
      max_prize: 'الحد الأقصى للجائزة: {{maxPrize}}',
      error_weak_password:
        'يجب أن تتكون كلمة المرور الخاصة بك من 8 أحرف على الأقل، بأحرف كبيرة وأحرف صغيرة وأرقام وأحرف خاصة',
      register_error_age: 'يجب أن تكون عمرك أكثر من 18 عامًا للتسجيل',
      months: 'شهور',
      raffels: 'رافلز',
      tickets: 'التذاكر',
      ticket: 'تذكرة',
      contest: 'منافسة',
      your_raffels: 'منافساتك',
      your_tickets: 'تذاكرك',
      result_lottery: 'نتيجة المسابقة',
      buy_now: 'شراء الآن',
      generate_link: 'إنشاء رابط',
      mobile_provider: 'مقدم الخدمة',
      mobile: 'محمول',
      bank_transfer: 'تحويل بنكي',
      accountNumber: 'حساب',
      ifscCode: 'IFSC',
      swiftCode: 'رمز السرعة',
      bankName: 'اسم البنك',
      documentNumber: 'رقم المستند',
      accountHolderName: 'اسم صاحب الحساب',
      accountAgencyNumber: 'رقم الوكالة',
      accountTypeCode: 'رمز نوع الحساب',
      payMode: 'طريقة الدفع',
      transitNumber: 'رقم العبور',
      financialInstitutionNumber: 'رقم المؤسسة المالية',
      bankBranchName: 'اسم الوكالة',
      bankBranchCode: 'رمز الوكالة',
      click_to_open: 'انقر لفتح',
      send_proof: 'ارسال الشهادة',
      proof_payment_sent: 'تم ارسال الشهادة',
      indicate_a_friend_and_win: 'قم بإحالة صديق واكسب مع كل عملية إعادة شحن',
      conversion_slim: 'تحويل',
      link: 'وصلة',
      recomendeds: 'مُستَحسَن',
      all_games: 'جميع الألعاب',
      selected_country: 'الدولة المختارة',
      games_of_today: 'الألعاب اليوم',
      you_win: 'لقد فزت للتو!',
      times: 'مرات',
      over_your_bet: 'حول قيمة الرهان الخاص بك',
      accumulated: 'مجموع',
      accumulated_points: 'مجموع المراهنات',
      start: 'بدئا',
      end: 'نهائي',
      new_raffle: 'قرعة جديدة',
      add_raffel_success: 'تم إنشاء السحب الجديد بنجاح',
      add_prize_success: 'تم إنشاء الجائزة الجديدة بنجاح',
      edit_prize_success: 'تم تعديل الجائزة بنجاح',
      add_prize_img_success: 'تم إنشاء صورة الجائزة بنجاح',
      add_order_tickets_success: 'تمت إضافة التذاكر بنجاح',
      edit_raffel_success: 'تم تعديل السحب بنجاح',
      error_orderPayment: 'خطأ عند إجراء الدفع',
      error_currentOrder: 'حدث خطأ أثناء تحديث الطلب',
      error_editedOrder: 'حدث خطأ أثناء تعديل الطلب',
      partially_paid: 'مدفوعة جزئيا',
      add_paid: 'العضوية المدفوعة',
      upgrade_paid: 'ترقية مدفوعة الأجر',
      renew_paid: 'التجديد المدفوع',
      monthly_paid: 'رسوم شهرية مدفوعة',
      mandala_paid: 'مصفوفة مدفوعة الأجر',
      bet_activated: 'الرهان مفعل',
      add_tickets_raffel: 'تمت إضافة التذاكر إلى السحب',
      order_not_canceled: 'لم يتم إلغاء الطلب',
      cancelOrder: 'تم إلغاء الطلب',
      price_consultant: 'التسعير الاستشاري',
      price_consumer: 'سعر المستهلك',
      maxTickets: 'تذاكر ماكس',
      edit_raffle: 'تحرير السحب',
      prizes: 'الجوائز',
      new_prize: 'جائزة جديدة',
      add_prize: 'أضف الجائزة',
      edit_prize: 'جائزة التحرير',
      prize_name: 'اسم الجائزة',
      conversion_to_games: 'التحويل إلى الألعاب',
      conversion_to_lotterprocoin: 'التحويل إلى التنشيط',
      trans_debit_awards: 'تحويل رصيد الجائزة',
      conversion_awards_lotterprocoin: 'تحويل رصيد الجائزة إلى رصيد التنشيط',
      pay_with_balance_awards: 'ادفع باستخدام رصيد الجائزة',
      your_minor_side_is: 'الجائزة الأخرى هي {{side}}',
      promotion_title: 'الترويج LotterPro يأخذك إلى كوراكاو',
			picture_recomend: 'الصورة الموصى بها',
			picture_list: 'قائمة الصور',
			picture: 'صورة',
			local_hour: 'ساعة محلية',
			timezone_hour: 'ساعة الوحدة الزمنية',
    },
  },
}
export { messages }
