import { i18n } from '../../utils/translates/i18n'
import { useRef } from 'react'
import { toPng } from 'html-to-image'
import { toast } from 'react-toastify'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'

export default function ModalPrintBet({ printBet }) {
  const btnClose = useRef('')
  const elementRef = useRef(null)

  const doPrintBet = async () => {
    toPng(elementRef.current, { cacheBust: false })
      .then(dataUrl => {
        const link = document.createElement('a')
        link.download = `${printBet?.lottery?.lottery}_${printBet?.id}_${printBet?.user?.nick}.jpg`
        link.href = dataUrl
        link.click()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const shareBet = async () => {
    toPng(elementRef.current, { cacheBust: false })
      .then(dataUrl => {
        fetch(dataUrl)
          .then(response => response.blob())
          .then(blob => {
            const file = new File([blob], `${printBet?.lottery?.lottery}_${printBet?.id}_${printBet?.user?.nick}.jpg`, {
              type: blob.type,
            })
            if (navigator.share) {
              navigator
                .share({
                  files: [file],
                  title: i18n.t('share'),
                  text: `${printBet?.lottery?.lottery} # ${printBet?.id}`,
                })
                .then(() => toast.success(i18n.t('shared')))
                .catch(console.error)
            } else {
              console.log('not_supported')
            }
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div
      className="modal fade"
      id="modalPrintBet"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('print_bet')}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body" style={{ maxHeight: 'calc(80vh)', overflowY: 'auto' }}>
            <div ref={elementRef} style={{ position: 'relative', width: '500px' }}>
              <img src="/img/bg-info-lotter.jpg" alt="bg-info-lotter" style={{ width: '500px' }} />
              <div
                style={{
                  position: 'absolute',
                  // backgroundColor: 'red',
                  width: '90%',
                  margin: '0 auto',
                  top: '56%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}>
                <div className="text-center" style={{ height: '310px' }}>
                  <div
                    className="text-center text-italic"
                    style={{
                      fontSize: '35px',
                      fontWeight: '900',
                      color: 'rgb(255,255,255)',
                      textShadow: '2px 2px 2px #000',
                    }}>
                    {i18n.t('you_win')}
                  </div>
                  <div
                    className="text-center text-italic "
                    style={{
                      marginTop: '-20px',
                      fontSize: '80px',
                      fontWeight: '900',
                      color: 'rgb(6,200,9)',
                      textShadow: '2px 2px 2px #000',
                    }}>
                    {printBet?.prize % printBet?.amount === 0
                      ? parseInt(printBet?.prize / printBet?.amount)
                      : parseFloat(printBet?.prize / printBet?.amount).toFixed(1)}{' '}
                    {i18n.t('times')}
                  </div>
                  <div
                    className="text-center text-italic "
                    style={{
                      marginTop: '-20px',
                      fontSize: '25px',
                      fontWeight: '900',
                      color: 'rgb(152,255,138)',
                      textShadow: '1px 1px 1px #000',
                    }}>
                    {i18n.t('over_your_bet')}
                  </div>
                  <div
                    className="text-center text-italic "
                    style={{
                      fontSize: '20px',
                      fontWeight: '900',
                      color: 'rgb(155,255,138)',
                      textShadow: '2px 2px 2px #000',
                    }}>
                    {i18n.t('prize')}:
                  </div>
                  <div
                    className="text-center text-italic "
                    style={{
                      marginTop: '-20px',
                      fontSize: '60px',
                      fontWeight: '900',
                      color: 'rgb(6,255,9)',
                      textShadow: '2px 2px 2px #000',
                    }}>
                    {FormateFiatCurrency(printBet?.prize)}
                  </div>
                </div>
                <div className="mt-3" style={{ marginLeft: '10px', height: '200px', width: '430px' }}>
                  <div
                    className="text-left text-italic "
                    style={{
                      marginTop: '-20px',
                      fontSize: '20px',
                      fontWeight: '900',
                      color: 'rgb(37,24,16)',
                      textShadow: '1px 1px 1px #fff',
                    }}>
                    {printBet?.lottery?.lottery}: {printBet?.lottery?.id}
                  </div>
                  <div
                    className="text-center"
                    style={{
                      fontFamily: 'bonheurroyale',
                      marginTop: '-30px',
                      fontSize: '100px',
                      // fontWeight: '900',
                      color: '#000',
                      textShadow: '0px 0px 1px #000',
                    }}>
                    {i18n.t('congratulations')}!
                  </div>
                  <div
                    className="text-center"
                    style={{
                      marginTop: '-50px',
                      fontSize: '40px',
                      fontWeight: '900',
                      color: 'rgb(37,24,16)',
                      textShadow: '1px 1px 1px #fff',
                    }}>
                    {printBet?.user?.name?.split(' ')[0]}
                  </div>
                  <div
                    className="text-center"
                    style={{
                      marginTop: '-15px',
                      fontSize: '20px',
                      fontWeight: '900',
                      color: 'rgb(37,24,16)',
                      textShadow: '1px 1px 1px #fff',
                    }}>
                    (<small style={{ fontSize: '15px' }}>{i18n.t('user')}:</small> {printBet?.user?.nick})
                  </div>
                  <div
                    className="text-center"
                    style={{
                      fontSize: '20px',
                      fontWeight: '900',
                      color: '#000',
                    }}>
                    {i18n.t('draw_date')}: {FormateDate(printBet?.lottery?.drawDate, true)}
                  </div>
                </div>
              </div>
              {/* <pre>{JSON.stringify(printBet, null, 2)}</pre> */}
            </div>
            <button className="btn btn-primary my-3 me-3" onClick={doPrintBet}>
              <i className="fa fa-save me-2"></i> {i18n.t('save')}
            </button>
            <button className="btn btn-primary my-3" onClick={shareBet}>
              <i className="fa fa-save me-2"></i> {i18n.t('share')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
