const messages = {
  cn: {
    translations: {
      a_crase: '这',
      about: '关于我们',
      above_the_same: '和上面完全一样',
      action: '行动',
      actionTemplates: '行动模型',
      activate_date: '激活日期',
      active_automations: '主动自动化',
      active_connections: '活跃连接数',
      active_monitors: '活动监视器',
      active_users: '活跃用户',
      ad: '公告',
      add: '加入',
      add_option: '添加选项',
      add_payment: '添加付款方式',
      add_question: '添加问题',
      add_receipt: '添加收据',
      add_wallet: '添加钱包',
      address: '地址',
      admin: '行政',
      advance: '进步',
      affected: '到达',
      after: '后',
      air_transport: '航空运输',
      ajust_limite: '限制调整',
      ajust_saldo: '平衡调整',
      alert: '警报',
      alerts: '警报',
      all: '全部',
      all_departments: '所有部门',
      allies_of: '的盟友',
      allRightsReserved: '版权所有。',
      almost_there: '差不多了',
      already_offset: '我已经补偿了',
      already_registered: '已经注册？ 单击此处并输入',
      amount: '价值',
      amount_above_balance: '不充足的资金',
      and: '这是',
      and_more: '和更多',
      answer: '回复',
      ao: '到',
      approve: '审批',
      April: '四月',
      asc: '上升',
      at: '在',
      August: '八月',
      automations: '自动化',
      awaiting_email_confirmation: '等待电子邮件确认！',
      b_order: '命令',
      b_orders: '命令',
      back: '回去',
      bairroInteresse: '邻里',
      balance: '平衡',
      balance_available: '可用余额',
      banned: '禁止',
      before_contact: '联系我们之前',
      binary: '双点',
      birth: '出生',
      blind_man: '反转',
      body_monthly: '您的月费已过期，您的BOT TRADER已被停用，请支付付款订单并再次享受您的福利',
      bonus_saldo_trader: '交易者余额奖金',
      book_health: '图书健康',
      but_possible_register: '仍然可以注册，但您不会被链接到任何推荐的朋友。',
      buy_volume: '购买量',
      buyer: '买方',
      by: '每',
      bye: '回头见',
      call: '被称为',
      call_to: '拨电至',
      calls: '支持',
      cancel: '取消',
      canceled: '取消',
      career_plan: '职业路线',
      casa_verde_amarela: '黄绿屋',
      cash_payment: '现金支付',
      cash_payment_return: '现金支付返还',
      cash_receipt: '现金收据',
      change_indicant: '变化指标',
      change_photo: '更改照片',
      change_plan: '改变计划',
      check_payment: '支票付款',
      check_payment_return: '通过支票退回付款',
      check_receipt: '支票收据',
      choose: '来选择',
      cidadeInteresse: '城市',
      city: '城市',
      claims: '索赔',
      clean: '清洁',
      click_and_know: '点击并发现',
      click_to_copy_the_wallet: '点击复制钱包',
      client: '客户',
      clients: '顾客',
      close: '关闭',
      cnh: '离岸人民币（许可证）',
      cnhDate: '离岸人民币发行日期',
      cnpj: 'CNPJ',
      cnpj_invalid: '无效的 CNPJ',
      coin: '硬币',
      color: '颜色',
      commercial: '商业的',
      company: '公司名称',
      complement: '补充',
      comporRenda: '你要和某人一起赚钱吗？',
      compRenda: '收入证明类型',
      confirm_approve: '确认批准',
      confirm_delete: '确认删除',
      confirm_new_password: '确认新密码',
      confirm_new_password_security: '确认新的安全密码',
      confirm_password: '确认访问密码',
      confirm_password_security: '确认安全密码',
      confirm_your_new_password: '再次输入您的新密码',
      confirmed: '确认的',
      confirmed_email: '已确认的电子邮件',
      congratulations: '恭喜你',
      contact: '接触',
      contract: '合同',
      copied: '已复制',
      copy: '复制',
      copy_success: '复制成功！',
      correspondences: '一致',
      countries: '国家',
      country: '国家',
      cpf: '公积金',
      cpf_invalid: '公积金无效',
      create_order_success: '订单创建成功！',
      created: '注册于',
      cred: '信用',
      cred_binario: '双积分积分',
      cred_deb: '贷方/借方',
      cred_game_donate: '游戏捐赠积分',
      cred_ind_direta: '直接推荐信用',
      cred_ind_indireta: '间接推荐信用',
      cred_mandala: '曼陀罗信用',
      cred_pagar_com_saldo: '余额信用支付',
      cred_pontos: '积分',
      cred_recb_trader: '信用接收交易者',
      cred_renovacao: '更新信用',
      cred_saldo_disp: '信用可用余额',
      cred_upgrade: '信用升级',
      credit_card_payment: '通过信用卡付款',
      credit_card_payment_return: '信用卡付款退回',
      credit_card_receipt: '信用卡收据',
      current: '当前的',
      current_password: '当前密码',
      current_wallet: '目前的投资组合',
      dashboard: '控制面板',
      data_added: '添加数据',
      data_saved: '保存的数据',
      datas: '数据',
      date: '日期',
      date_first_installment: '首次付款日期',
      ddi: '国家代码',
      deb_de_trader: '交易者债务',
      deb_game_donate: '游戏捐赠借方',
      deb_saldo: '借方余额',
      deb_saldo_disp: '借记可用余额',
      deb_saldo_pendent: '未结余额借方',
      deb_val_indev: '借记不当金额',
      debit_card_payment: '通过借记卡付款',
      debit_card_payment_return: '通过借记卡退回付款',
      debit_card_receipt: '通过借记卡收据',
      debt: '债务',
      debt_td: '借方',
      December: '十二月',
      decImpRenda: '申报所得税',
      deleted_document: '文档已从您的记录中删除',
      department: '部门',
      dependents: '家属',
      desc: '向下',
      description: '描述',
      details: '细节',
      dev_econom: '经济发展',
      developer: '开发商',
      directly_indicated: '直接指示',
      directly_indicateds: '直接推荐',
      directs: '直接的',
      district: '邻里/区',
      divorced: '离婚',
      doa_men_receb: '收到每月平台捐款',
      doacao_td: '捐款',
      doctorate_complete: '博士学位 - 完成',
      doctorate_incomplete: '博士学位 - 不完整',
      document: '文档',
      document_already_registered: '文件已注册',
      documents: '文件',
      donate: '捐',
      donor: '捐赠者',
      dont_know_zip: '不知道邮政编码？ 点击这里',
      dou_fe: '我确认以上信息正确无误。',
      downloads: '下载文件',
      drop_images: '单击或将图像拖动到此处',
      due_date: '到期',
      edit: '编辑',
      edit_option: '编辑选项',
      edit_question: '编辑问题',
      email: '电子邮件',
      email_already_registered: '邮箱已注册',
      email_and_or_password_wrong: '电子邮件和/或密码错误！',
      email_for_login: '该电子邮件将作为您的登录名',
      email_or_password_wrong: '错误的邮箱帐号或密码',
      employee: '员工',
      employees: '雇员',
      empty: '空的',
      enter_your_new_password: '输入您的新密码',
      entrada: '有入门价值吗？ 如果是，值是多少',
      entries_until_today: '迄今为止的条目',
      error_confirm_password: '确认密码和新密码必须相同',
      error_email: '电子邮件格式无效',
      error_password: '密码错误',
      error_phone: '无效的电话号码',
      estadoInteresse: '状态',
      estimate: '估计的',
      exchange: '替代品',
      expired: '已到期',
      extorno_td: '逆转',
      extra: '额外的',
      extract: '提炼',
      extracts: '提取物',
      fantasy: '幻想',
      fatherName: '父亲的名字',
      February: '二月',
      fee: '提款费',
      feedback: '返回',
      feminine: '女性化',
      fgts: '您的 FGTS 的价值',
      field_required: '强制完成',
      final: '最终的',
      finance: '金融的',
      financial: '金融的',
      financial_payment: '金融支付',
      financial_payment_return: '财务付款返还',
      financial_receipt: '财务收据',
      financing: '融资',
      financings: '融资',
      first_10_pages: '前 10 页',
      fisica: '自然人',
      for_year: '每年',
      forget_password: '我忘记了密码',
      forgot_password: '忘记密码',
      form: '形式',
      form_of_payment: '付款方式',
      found: '成立',
      founds: '成立',
      free: '自由的',
      friend_indicated: '我的朋友推荐的',
      friend_indicateds: '我的朋友推荐的',
      fuel: '燃料',
      full_name: '全名',
      funcPublico: '是一名公务员',
      fundamental_complete: '基础 - 完整',
      fundamental_incomplete: '基础 - 不完整',
      ganh_diario: '每日收益',
      gender: '性别',
      grand_total: '累计',
      group: '团体',
      has_withdraw_today: '今天已经请求提款',
      high: '高的',
      home: '开始',
      how_create_binance_apikey: '如何在币安上创建 API 密钥',
      how_it_works: '怎么运行的',
      how_to_call: '你想被称为什么？',
      id_td: '直接推荐',
      images: '图片',
      in_construction: '建设中',
      in_order: '为了',
      in_stock: '有存货',
      indicant: '指标',
      indicated_by: '您被推荐给',
      indicated_not_found: '未找到指定的推荐人...',
      indicateds: '提名者',
      indirects: '间接',
      info: '时事通讯',
      info_contact: '联系信息',
      initial: '家',
      insert_new: '输入新的',
      installments: '分期付款',
      intelligence: '智力',
      interesse: '感兴趣的区域是什么',
      invalid_contact: '联系方式无效',
      invalid_images: '无效图像',
      invalid_token: '令牌无效',
      invite: '邀请',
      invite_friends: '邀请你的朋友',
      January: '一月',
      July: '七月',
      June: '六月',
      juridica: '法人',
      know_exact_result: '你知道确切的价值吗？',
      lead: '我们将共同建设新的未来。',
      learn_more: '了解更多',
      left: '左边',
      left_preferential: '更喜欢左侧',
      legalNature: '法律性质',
      level: '等级',
      limite_80: '您已达到限额的 {{perc}}。\n 进行改进并避免收入暂停。',
      limite_excedido: '超过借记限额',
      linear: '线性',
      link_email: '点击发送到注册邮箱的链接！',
      link_invite_copy: '分享链接复制成功',
      liquid: '液体',
      list: '列表',
      loading: '加载中',
      location: '地点',
      locked: '被阻止',
      login: '进入',
      logout: '出去',
      low: '低的',
      male: '男性',
      manage_users: '管理用户',
      manager: '经理',
      mandala: '曼陀罗',
      mandalas: '曼陀罗',
      manufacturer: '制造商',
      March: '行进',
      maritalStatus: '婚姻状况',
      market: '市场',
      married: '已婚',
      master_complete: '硕士学位 - 完成',
      master_incomplete: '硕士学位 - 不完整',
      max_payment: '最高赔付额',
      May: '可能',
      message: '信息',
      messageNotSent: '无法发送您的消息',
      messageSent: '您的留言已成功发送',
      middle_complete: '中 - 全',
      middle_incomplete: '中等 - 不完整',
      min_amount_error: '该值必须至少为：',
      min_withdraw: '最低提款金额',
      missing: '丢失的',
      mission: '使命',
      mission_vision_values: '使命、愿景和价值观',
      model: '模型',
      monitors: '显示器',
      monthly: '每月支付',
      motherName: '母亲的名字',
      movements: '动作',
      msg_renovacao: '您已达到收入限额，请续订您的套餐。',
      my_account: '我的账户',
      my_wallet: '我的钱包',
      mystock: '我的股票',
      name: '姓名',
      nascMaisVelhoRenda2: '最大的出生日期是哪一天？',
      nationality: '国籍（出生国）',
      naturalness: '出生地（出生城市）',
      nature: '自然',
      network: '网络',
      new: '新的',
      new_call: '新的召唤',
      new_financing: '新融资',
      new_order: '新命令',
      new_password: '新密码',
      new_password_security: '新的安全密码',
      new_seller: '新卖家',
      new_wallet: '新钱包',
      news: '消息',
      next: '下一个',
      nick: '用户登录）',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS 已注册',
      no: '不',
      no_approve: '尚未批准',
      no_delete: '请勿删除',
      no_literate: '不识字',
      no_repeat: '数据不能重复',
      no_results_found: '未找到结果',
      no_services_or_products_added: '未添加任何服务或产品',
      no_settings: '前往“设置”并填写您的详细信息。',
      none_friends_indicated: '我还没有推荐任何朋友',
      not_authorized: '未经授权',
      not_found: '未找到',
      not_login: '无法登录，请检查您的详细信息并重试！',
      not_orders: '没有付款订单',
      not_permission: '您没有权限访问此页面',
      not_registered: '还没有注册？ 单击此处并注册',
      not_results: '没有找到记录',
      not_self_register: '无法注册',
      not_today_entry: '今天我们还没买到票',
      notfound_ad: '没有找到广告',
      November: '十一月',
      number: '数字',
      obs: '评论',
      obs_pay: '如果您的经纪商从发送的金额中扣除费用，您必须通过添加这些费用的金额来手动进行更正。',
      October: '十月',
      of: '在',
      on_total: '占总数的',
      open: '打开',
      opening: '开幕',
      operation: '表现',
      ordenation: '订购',
      order: '命令',
      order_book: '订单簿',
      order_not_alowed_to_pay: '未经授权请求使用余额付款',
      order_not_found: '未找到订单',
      order_payed: '订单 #{{id}} 支付成功',
      orders: '要求',
      other: '其他',
      other_services_or_products: '其他服务或产品',
      paid: '支付',
      paid_date: '发薪日',
      partial: '部分付费',
      passport: '护照',
      password: '密码',
      password_security: '安全密码',
      pay: '支付',
      pay_exact: '支付 {{coin}} 金额',
      pay_generate: '生成付款',
      pay_selected: '支付选择',
      pay_value_in: '支付金额',
      pay_with_balance: '用余额支付',
      payable: '支付',
      payer: '付款人',
      payment: '支付',
      ped_retirada: '提款请求',
      ped_retirada_gan_diario: '要求提取每日收入',
      pendent: '待办的',
      pendent_order: '您有待处理的订单',
      people: '人们',
      person: '人',
      person_found_in_other_prefecture: '已在另一个城市登记的人',
      personal: '伙计们',
      personal_addresses: '地址',
      personal_contacts: '联系方式',
      personal_data: '个人资料',
      personal_infos: '信息',
      personal_pcva: '绿屋和黄屋计划',
      phone: '电话号码',
      photo: '照片',
      pix: 'PIX',
      pix_payment: 'PIX 支付',
      pix_payment_return: 'PIX 中的付款退回',
      pix_receipt: '在 PIX 中接收',
      pl_carreira: '职业路线',
      plan: '平坦的',
      plates: '盘子',
      please_wait: '请稍等',
      plus: '加',
      point: '观点',
      points: '积分',
      portfolio: '文件夹',
      pos_complete: 'Lato sensu - 完整',
      pos_incomplete: 'Lato sensu - 不完整',
      prefecture: '市政府',
      prefecture_addresses: '地址',
      prefecture_contacts: '联系方式',
      prefecture_data: '市政厅数据',
      prefecture_infos: '信息',
      prefectures: '市政厅',
      prev: '以前的',
      price: '价格',
      print: '打印出',
      privacy_policies: '隐私政策',
      private_works: '私人工程',
      profession: '职业',
      profile: '轮廓',
      program: '程序',
      programs: '软件',
      project: '项目',
      projects: '项目',
      public_works: '公共工程',
      purchase: '购买',
      qualify_binary: '双积分资格',
      qualquer: '任何',
      quantity: '数量',
      quotation: '价格',
      read: '读书',
      receivable: '受到',
      recipient: '接收者',
      recommendations: '建议',
      redefine_password: '重新定义密码',
      register: '记录',
      register_people: '人员登记',
      register_wallet: '注册钱包进行提款',
      registers: '记录',
      registers_users: '用户注册',
      reject: '拒绝',
      remaining: '其余的',
      remove: '去除',
      rendaIndividual: '个人总收入',
      renew: '更新',
      renew_mandala: '曼陀罗更新',
      reply: '回复',
      reports: '报告',
      required_data_not_sent: '未发送所需数据',
      resend_email: '重新发送电子邮件',
      residential: '住宅',
      responded: '已回答',
      restricao: '你的名字有限制吗？ （SPC/塞拉萨）',
      result: '结果',
      results: '结果',
      resume_data: '数据汇总',
      revoked: '已废除',
      rg: '一般注册（身份）',
      rgAgency: 'RG发行人',
      rgDate: 'RG 发行日期',
      rgUf: 'RG 州',
      right: '正确的',
      right_preferential: '更喜欢右侧',
      roi: '每日收益',
      sale: '销售',
      saq_duplicado: '双倍提款',
      save: '保存',
      schooling: '教育',
      search: '搜索',
      secretary: '秘书',
      security_password: '安全密码',
      see: '查看',
      see_advents: '看广告',
      see_call: '查看通话',
      see_details: '查看具体信息',
      see_details_wallet: '查看钱包详情',
      see_financing: '查看融资',
      see_order: '查看订单',
      see_user: '查看用户',
      select_vehicle: '选择车辆',
      self_register: '登记',
      sell_volume: '销售数量',
      seller: '卖方',
      send_call: '发送呼叫',
      send_to: '发给',
      September: '九月',
      settings: '设置',
      share_your_link: '分享您的链接',
      show: '显示直到',
      show_from: '显示自',
      side: '边',
      sign_in_to_start_your_session: '登录并开始您的会话',
      sign_up: '登记',
      single: '单身的',
      sold: '卖',
      state: '状态',
      status: '地位',
      strategies: '策略',
      street: '路',
      subject: '主题',
      submit: '发送',
      submit_placeholder: '请填写您的电子邮件',
      subscribe: '注册以接收我们的新闻',
      subtitle: '标题',
      success_update_user: '用户更新成功！',
      success_wallet: '作品集更新成功！',
      suggestions: '建议',
      superior_complete: '毕业了',
      superior_incomplete: '不完全高等',
      supervisor: '导师',
      support: '支持',
      support_materials: '支持材料',
      suspended: '暂停',
      symbol: '象征',
      symbols: '符号',
      system: '系统',
      target: '目标',
      telegram_chat_id: '电报聊天 ID',
      tempoCarteira: '您在投资组合中注册多久了？',
      terms: '我接受使用条款',
      text_hint_binance:
        '在币安上创建 API 密钥时，在访问限制中输入 IP：\n172.105.78.139\n然后选择选项：\n 启用欧洲期权\n 启用现货和保证金交易',
      this_value_in: '该值在',
      ticker_health: '股票健康',
      title: '标题',
      to: '直到',
      to_pay: '关闭',
      to_receive: '应收账款',
      today_entry: '今天的入场',
      token_not_found_or_already_used: '未找到令牌或已使用令牌',
      total: '全部的',
      total_entry: '总参赛人数',
      traderbot: '机器人交易者',
      transf_credito: '转移学分',
      transf_debito: '转账借方',
      transfer_payment: '转账付款',
      transfer_payment_return: '通过转账方式退回付款',
      transfer_receipt: '转账收据',
      transparency: '透明度',
      tx_adm_cred: '管理税务',
      tx_adm_deb: '管理税务',
      tx_retirada: '提款费',
      tx_retirada_gan_diario: '每日收益提现费用',
      type: '类型',
      update: '更新',
      update_wallet: '更新钱包',
      upgrade: '改进',
      used: '用过的',
      user: '用户',
      user_active: '活跃用户',
      user_already_exists: '用户已存在！',
      user_and_or_password_wrong: '用户名和/或密码错误！',
      user_inactive: '不活跃用户',
      user_not_found: '未找到用户',
      user_not_logged: '用户未登录',
      user_or_password_wrong: '用户名或密码错误',
      users: '用户',
      users_list: '用户列表',
      valid_images: '有效图像',
      valorRenda2: '第二收入的价值是多少',
      value: '价值',
      value_donate_on: '捐赠价值',
      value_financed: '融资金额',
      value_installment: '分期付款价值',
      values: '价值观',
      vehicle: '车辆',
      vehicles: '汽车',
      view_order: '查看订单',
      vision: '想象',
      visitor: '游客',
      voucher: '代金券',
      wait_confirm: '等待确认',
      waiting: '等待',
      waiting_email: '注册成功，现在您需要做的就是确认您的电子邮件。',
      waiting_payment: '等待付款',
      wallet: '文件夹',
      wallet_generate: '生成投资组合',
      wallets: '钱包',
      want_donate: '我想捐款',
      want_now_more_about: '我想了解更多',
      warning: '注意力',
      we_are: '是',
      webhooks: '网络钩子',
      welcome: '欢迎',
      who_we_are: '我们是谁',
      who_we_are_subtitle: '简要说明',
      widower: '鳏夫',
      will_expire_in: '将到期于',
      with: '和',
      withdraw: '退出',
      withdraw_sended_email: '请求提现，请检查您的电子邮件并点击链接进行授权！',
      withdrawal: '提款',
      withdrawals: '提款',
      year: '年',
      years: '年',
      yes: '是的',
      you_have: '你有',
      your_country: '你的国家',
      your_dashboard: '这是您的控制面板。',
      your_link: '附属链接',
      zipcode: '邮政编码',
      withdrawal_sended: '提款已发送',
      withdrawal_pending: '已请求提款！ \n请等待付款后再进行提款。',
      limite_80_top: '您已达到 {{perc}} 限额。\n 预计续订并避免暂停您的收入。',
      game: '游戏',
      games: '游戏',
      winning_numbers: '抽奖号码',
      your_numbers: '你的号码',
      add_numbers: '添加号码',
      next_draw: '下一次抽奖',
      can_bet_until: '您最多可以投注',
      your_bets: '你的赌注',
      add_bet: '添加赌注',
      bet: '赌注',
      bets: '投注',
      numbers: '数字',
      special_numbers: '特殊号码',
      resume: '概括',
      choosed_numbers: '所选号码',
      choosed_special_numbers: '选择特殊号码',
      bets_finished: '已结算投注',
      prize_draw: '抽奖',
      balance_network: '网络平衡',
      telegram: '电报',
      logs: '日志',
      release_withdrawals: '释放提款',
      bot_active: '发布机器人',
      available: '可用的',
      sunday: '星期日',
      monday: '第二',
      tuesday: '第三',
      wednesday: '第四',
      thursday: '第五',
      friday: '星期五',
      saturday: '周六',
      title_binary: '设置每天的双积分百分比',
      ok_perc_change: '百分比更改成功',
      cred_deb_title: '贷方或借方余额',
      send: '发送',
      voucher_pendent: '优惠券待处理',
      leader_pendent: '待定领导者',
      voucher_approved: '优惠券已批准',
      leader_approved: '认可领导者',
      no_binary_at_today: '当天没有登记双积分',
      generate_file: '生成文件',
      withdrawals_file_created: '提款文件已创建',
      withdrawals_sended: '已发送提款请求',
      see_generated_files: '查看生成的文件',
      files: '文件',
      file: '文件',
      verify: '去检查',
      rescue: '救援',
      awarded: '荣获',
      rescued: '获救',
      repeat: '重复',
      draw_date: '抽奖日期',
      bet_date: '投注日期',
      drawn_numbers: '抽奖号码',
      awardeds_numbers: '奖品号码',
      no_award: '无奖',
      you_hit_number: '您答对了 {{qty}} 个号码',
      you_hit_numbers: '您答对了 {{qty}} 个数字',
      lottery: '彩票',
      lotteries: '彩票',
      balls: '球',
      special_balls: '特价商品',
      levy: '收藏',
      major_awarded: '更多球',
      hits: '点击数',
      balance_game: '游戏平衡',
      your_balance: '您的余额',
      your_balance_total: '您的总余额',
      game_package: '{{qty}} 个号码（可赢得 {{prize}} 次）',
      choose_package: '选择套餐',
      error_bet: '无法发送投注',
      do_complete: '去完成',
      success_bet: '投注已发送',
      you_bet_number: '您下注 {{qty}} 个数字',
      winner_msg: '并赢得了 {{prize}} 次',
      prize: '奖',
      balance_not_enough: '不充足的资金',
      addbalance: '为游戏添加平衡',
      convertbalance: '转换余额',
      convertbalance_title: '将可用余额转换为游戏余额',
      can_award: '你能赢',
      emission: '排放',
      validation: '验证',
      participant: '参加者',
      concourse: '比赛',
      draw_hours: '开奖时间',
      qty_dezenas: '数量 十个',
      bet_amount: '投注金额',
      share: '分享',
      shared: '准备分享',
      conversion: '余额之间的换算',
      better: '博彩顾问',
      lottery_consultant: '助理顾问',
      consultant_indication: '顾问预约',
      betting_commission: '游戏余额充值佣金',
      be_a_consultant: '成为彩票顾问并赚取更多',
      msg_be_a_consultant: 'Lotter 全球利润部门。\n推荐奖金（打包销售）\n双点奖金\n等等。',
      auto_renovations: '自动续订',
      auto_renove_binary: '双点自我更新',
      auto_renove_quotas: '自我更新目标（220%）',
      auto_renove_anual: '每年自我更新',
      off: '离开',
      on: '连接的',
      change_success: '更改成功',
      anual: '每年',
      only_monday: '每周一可提款',
      transferency: '转移',
      max_amount: '最大值',
      info_better:
        '- 投注顾问以投注积分的形式收到该包的总价值。\n\n- 允许通过仅支付差额并在新的投注积分中接收差额来升级投注包。\n\n- 升级通过全额支付新套餐，您也可以在关联包中获得利润分享。\n\n- 可以使用 Apostador 包或关联包（如果有）进行续订和降级。',
      info_consultant:
        '- 助理顾问将投注券从他的包中传递给 Lotter Pro 与他的客户群进行协商，并在工作日收到所选关联包的高达 1% 作为利润分享。\n\n- 允许升级只需支付差额即可升级至关联包，220% 的新参与上限将成为关联包的新价值，扣除在此之前已从所有奖金中收到的金额。\n\n- BETTER PACKS 中允许升级全额支付新套餐，您将收到该套餐总价值的投注积分。\n\n- 可以使用 Bettor Packs 或 Associate Packs 进行续订和降级。\n\n- 套餐、升级和续订超过 500 美元必须仅在关联包中完成。',
      confirm: '确认',
      send_new_token: '确认信息已发送到您的电子邮件后，请单击您的电子邮件进行确认',
      email_resended: '确认信息已发送至您的邮箱，点击您的邮箱进行确认',
      expired_send_new_token: '新的确认信息已发送到您的电子邮件，点击您的电子邮件进行确认',
      become_a_consultant: '升级并获得更多',
      register_ok: '注册成功',
      ticket_number: '门票号码',
      mensal_activation: '月度激活',
      conversion_to_bet: '转换为投注',
      conversion_balance_games: '余额转换为游戏余额',
      conversion_balance_lotterprocoin: '余额转换为 激活',
      conversion_lotterprocoin_games: 'LotterPro-硬币到游戏余额转换',
      conta_corrente: '往来账户',
      balance_games: '游戏平衡',
      add_balance_games: '为游戏添加平衡',
      transf_debit_lotterProCoin: '激活余额转账',
      deb_saldo_lotterProCoin: '激活 余额借记',
      transf_credit_lotterProCoin: '将积分转移至 激活',
      live_now: '现在直播',
      offline: '等待绘制',
      tutorials: '教程',
      official_channel: '官方频道',
      english: '英语',
      spanish: '西班牙语',
      portuguese: '葡萄牙语',
      select_language: '选择语言',
      msg_renove_binary:
        '您已经完成了最后一个月的激活，并且没有激活自动每月激活。\n这就是您的双积分处于非活动状态的原因。\n支付 25.00 美元添加游戏余额的请求以执行每月激活。',
      choose_side_preferential: '选择首选一侧（当前：{{side}}）',
      count: '计数',
      deb_balance_games: '借记游戏余额',
      cred_saldo_lotterProCoin: '借记 激活 余额',
      historics: '历史',
      balance_lotterProCoin: '激活平衡',
      msg_binary_tree_1: '按下所需用户按钮 1 秒钟，即可查看信息',
      msg_binary_tree_2: '点击一次所需的用户，即可看到二进制中的下一个用户。',
      change_security_password: '修改安全密码',
      info_pass_security: '安全密码用于进行金融交易',
      redefine_security_password: '重新定义安全密码',
      invalid_wallet: '无效的钱包',
      valid_wallet: '有效的钱包',
      cpf_account:
        '需要通知CPF将进行付款的银行账户持有人。\n\n然后您将被重定向到支付网关注册页面。\n\n注册完成后，返回到此LOTTER PRO页面并单击生成 QR 码 PIX。',
      confirm_email: '确认您的电子邮件',
      error_confirm_email: '提供的电子邮件不匹配',
      info_choose_package: '购买可用套餐之一即可激活并累积积分，否则将在 15 天内删除',
      title_info_register: '确认注册前请仔细阅读',
      info_register:
        '- 仅在激活最低 10 美元后才可累积积分 \n- 双积分于伦敦每天 00:00 支付 \n- 双积分激活是 2 个账户，每侧 25 美元 \n- 在办公室确认您的双积分的溢出侧 \n n- 注册前请确认以上信息',
      no_binaries: '无法显示该用户的网络',
      register_gateway: '点击此处注册支付网关',
      packs_better: '投注顾问会员包（投注可获得积分）',
      packs_consultant: '助理顾问会员包（接收利润分享）',
      read_choose: '仔细阅读并选择最适合您的包',
      footer_choose: '所有套餐均包含参与 Lotter Pro 联盟计划的权益',
      day: '天',
      days: '天',
      olds: '老者优先',
      recents: '最近的第一',
      accept_title: '要通过 PIX 付款，必须接受以下条款',
      acept1:
        '我知道 SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 是一家在巴西注册的公司，并且它遵守中央银行和联邦税务局的所有规则，所有 USDT 购买或销售操作均在巴西进行根据 1888/19 号规范指令的规定，它们附有发票并向巴西联邦税务局报告。',
      acept2:
        '我知道 SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 不提供任何投资服务或产品，且活动仅限于购买或出售 USDT。',
      acept3:
        '我同意与 SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 共享我的个人数据，以便其执行 USDT-Polygon 购买或销售服务。',
      acept4:
        '我知道并接受我正在通过 SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 购买 USDT，并且我授权将加密货币发送到钱包地址 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc，持有者： \nSMART PAY INTERMEDIACOES | 42.913.912/0001-02 \n地址：大道：保利斯塔，N.° 171 – 补充：PAVMTO4 和 3 VG – Bela Vista – 01311.904 – 圣保罗 - SP \n电子邮件：smartpayintermediacoes@gmail.com',
      acept5:
        '我免除 SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 对该网站和/或持有 USDT 地址的公司提供的产品或服务的任何商业责任。',
      acept6:
        '我同意，通过支付下面生成的 Pix 二维码，我将同意从 SmartPay Serviços Digitais Ltda - CNPJ: 32.546.471/0001-96 购买或销售 USDT 的条款，并且该操作将在 Polygon 的区块链上注册，发送消息字段中的巴西中央银行操作 ID（端到端）发送至地址 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc',
      aceptAlert:
        '当您单击“验证”时，将生成一个 Pix 密钥，其随机值介于 R$0.10 到 R$0.99 之间。\n\n必须进行此付款以验证您在 SmartPay Serviços Digitais Ltda 的注册。',
      validate: '验证（此费用仅收取一次）。',
      modal_acpet_title: '第一次在 LOTTER PRO 使用 PIX 付款？',
      only_time: '此过程只会执行一次。\n通过 PIX 进行的其他付款将自动验证。',
      terms_smartpay: '我同意 Smartpay Serviços Digitais Ltda 的条款和条件 - CNPJ：32.546.471/0001-96',
      success_activation: '您已成功通过验证，现在可以通过 PIX 进行付款',
      warning1: '这不是您的订单付款',
      warning2:
        '这是验证您在 SmartPay Serviços Digitais Ltda 注册并能够通过 PIX 付款的二维码。 \n 这不是您订单的付款。\n 确认付款后，请返回此 LOTTER PRO 页面，然后单击“生成二维码 PIX”以生成用于通过订单的 PIX 付款的二维码。',
      only_brazil: '仅在巴西',
      changeUserGroup: '更改类别',
      alert_change_category:
        '如果您更改类别，您当前的礼包将不再有效。\n您将不再收到当前礼包中的奖金。\n您现在将从新礼包中收到奖金。\n您的中奖限额将重新调整为 220%您的新套餐的名称。\n您的余额不会改变。\n\n您确定要更改类别吗？',
      exceeded: '超额',
      vinculate_whatsapp: '关联 WhatsApp',
      verification_code: '验证码',
      verification_msg: '输入发送到您的 WhatsApp 的验证码',
      balance_awards: '奖金余额',
      addprocoin: '添加激活余额',
      withdrawal_awards: '提领奖金',
      conversion_awards_games: '将奖金余额转换为游戏余额',
      last_draws: '最新开奖',
      today: '今天',
      yesterday: '昨天',
      tomorrow: '明天',
      others: '其他的',
      draws: '开奖',
      learn_more_text:
        '在 {{choose}} 几十个\n之间进行选择，并从 {{rangeWin.min}} 到 {{rangeWin.max}} 乘以投注金额\n匹配 {{win}} 几十个抽取的奖金。',
      or: '  或者',
      good_luck: '祝你好运！',
      add_balance_awards: '添加奖金余额',
      deb_balance_awards: '奖金余额借方',
      key_pix: 'Pix 密钥',
      choose_game: '选择游戏',
      do_bet: '下注',
      response_bets_lot: '投注反应',
      lottery_not_found: '未找到彩票',
      lottery_expired: '过期彩票',
      amount_invalid: '无效值',
      balance_game_not_enough: '不充足的资金',
      game_package_not_found: '找不到游戏包',
      please_choose_other_tens: '请再选择几十个',
      continue_beting: '继续投注',
      balls_invalid: '无效号码',
      max_bet: '下注的最大值\n取决于十的数量',
      copy_paste: '多种游戏',
      amount_copy_paste: '每次投注的价值',
      list_copy_paste: '所需投注列表',
      total_valid: '有效总数',
      erase_my_account: '删除我的账户',
      erase_alert_caution: '此操作无法撤消。 您确定要删除您的帐户吗？ \n写"删除我的账户"进行确认',
      success_delete_account: '账户删除成功',
      age_major:
        '在继续之前，我们需要确认您已年满 18 岁或以上。 根据法律法规，未满 18 周岁的人士禁止赌博。\n\n请点击下面的复选框以确认您已年满 18 周岁。',
      age_major_button: '我确认我已年满 18 岁',
      title_age_major: '年龄确认',
      about_us:
        '我们{{company}}相信每个人都应该有机会实现远大的梦想。 这就是为什么我们为您提供参与全球最大彩票的机会，无论您身在何处。 \n\n我们为按照最高道德和法律标准运营而感到自豪。\n我们对透明度、安全性和问责制的承诺确保了我们对提供公平和有趣的游戏体验的信任。',
      site_map: '站点地图',
      kyc: '身份验证（KYC）',
      kyc_welcome: '为了验证您的身份，请上传以下图片：',
      kyc_msg: '身份验证获得批准后，您将收到一封确认电子邮件，并且所有 {{company}} 功能将被释放。',
      kyc_error: '上传图片时出错，请重试',
      driving: '驾照',
      identity: '身份',
      front: '正面',
      front_msg: '文件正面照片',
      back_doc: '诗',
      back_doc_msg: '文件背面照片',
      selfie: '自拍',
      selfie_msg: '拿着证件自拍',
      kyc_pending: '待验证',
      kyc_rejected: '验证被拒绝',
      kyc_approved: '验证通过',
      two_fa: '双因素身份验证 (2FA)',
      two_fa_msg: '为确保您的访问更加安全，请启用双因素身份验证 (2FA)。',
      two_fa_step1: '使用您的身份验证应用程序（Google Authenticator、Authy 等）读取二维码。',
      two_fa_step2: '输入身份验证应用程序发送给您的验证码。',
      two_fa_activate: '启用双因素身份验证 (2FA)',
      two_fa_deactivate: '禁用双因素身份验证 (2FA)',
      two_fa_activated: '您的双因素身份验证 (2FA) 已启用，\n 您现在拥有额外的安全层。',
      two_fa_verify: '验证双因素身份验证 (2FA)',
      draw_due: '投注限额',
      close_game: '关闭游戏',
      draw_due_greater_than_draw_date: '投注限额必须在抽奖前至少 {{timeLimit}} 分钟',
      draw_date_less_than_now: '抽奖日期必须比当前日期晚至少 {{timeLimit}} 分钟',
      simulation: '模拟',
      valuePay: '支付数量',
      quotas_paid_success: '付款成功',
      paids_today: '今天付款',
      no_paids_today: '今天没有付款',
      perc: '百分比',
      define_percent: '设置百分比',
      success_update: '更新成功',
      define_percent_next_payment: '设置下一个付款百分比',
      create: '创建',
      doclose: '关闭',
      lottery_updated: '抽奖已更新',
      lottery_created: '抽奖已创建',
      award_packages: '奖励包',
      qty: '数量',
      multiplier: '乘数',
      maxValue: '最大值',
      limitRepeat: '限制重复',
      draw_day: '抽奖日',
      hour: '小时',
      minutes: '分钟',
      ytb_channel_id: 'YouTube 频道 ID',
      tutorial: '教程',
      video_url: '视频网址',
      tutorial_created: '教程已创建',
      tutorial_deleted: '教程已删除',
      tutorial_error_delete: '无法删除教程',
      tutorial_updated: '教程已更新',
      tutorial_error_update: '无法更新教程',
      language: '语言',
      live_hours: '直播时间',
      exact: '确切地',
      partiallly: '部分的',
      closed: '关闭',
      statistics: '统计',
      your_link2: '投注链接',
      deb_20_to_games: '转移到游戏余额',
      cred_20_to_games: '转移到游戏余额',
      transf_debit_games: '游戏余额转账',
      transf_credit_games: '转移到游戏余额',
      file_too_large: '文件太大。最大尺寸: {{max}}',
      kyc_warning: '完成身份验证，每场比赛最高投注 100 美元',
      msg_rules_footer:
        '1. 本产品仅供 18 岁以上用户使用，仅供娱乐。\n 2. 本网站包含应用内购买。 \n 3. 玩家在社交博彩游戏中玩或获胜的事实并不意味着他将来会在真钱博彩及相关游戏中获胜。',
      terms_conditions: '条款和条件',
      aml_policy: '反洗钱政策',
      kyc_policy: '身份验证政策',
      self_exclusion_policy: '自我排除政策',
      responsible_gambling_policy: '负责任的博彩政策',
      clause_18: '小型博彩政策（18 岁以上参与条款）',
      policies: '政策',
      join_us: '加入我们的社区',
      max_prize: '最高奖金：{{maxPrize}}',
      error_weak_password: '您的密码长度必须至少8个字符，包含大写字母、小写字母、数字和特殊字符',
      register_error_age: '您必须年满18岁才能注册',
      months: '月',
      raffels: '莱佛士',
      tickets: '门票',
      ticket: '票',
      contest: '竞赛',
      your_raffels: '您的莱佛士',
      your_tickets: '您的门票',
      result_lottery: '抽奖结果',
      buy_now: '立即购买',
      generate_link: '生成链接',
      mobile_provider: '手机服务商',
      mobile: '手机',
      bank_transfer: '银行转账',
      accountNumber: '帐户',
      ifscCode: '国际金融服务中心',
      swiftCode: 'SWIFT代码',
      bankName: '银行名',
      documentNumber: '文件号码',
      accountHolderName: '账户持有人姓名',
      accountAgencyNumber: '代理机构号码',
      accountTypeCode: '账户类型代码',
      payMode: '付款方式',
      transitNumber: '中转号码',
      financialInstitutionNumber: '金融机构号码',
      bankBranchName: '机构名称',
      bankBranchCode: '机构代码',
      click_to_open: '点击打开',
      send_proof: '发送凭证',
      proof_payment_sent: '付款凭证已发送',
      indicate_a_friend_and_win: '推荐朋友并通过每次充值赚取收入',
      conversion_slim: '转换',
      link: '关联',
      recomendeds: '受到推崇的',
      all_games: '所有游戏',
      selected_country: '已选择国家',
      games_of_today: '今天的游戏',
      you_win: '你刚刚赢了！',
      times: '次',
      over_your_bet: '关于您的赌注价值',
      accumulated: '累计',
      accumulated_points: '累计积分',
      start: '开始',
      end: '结束',
      new_raffle: '新抽奖',
      add_raffel_success: '新抽奖成功创建',
      add_prize_success: '新奖项创建成功',
      edit_prize_success: '奖项编辑成功',
      add_prize_img_success: '奖品图片创建成功',
      add_order_tickets_success: '门票添加成功',
      edit_raffel_success: '抽奖编辑成功',
      error_orderPayment: '付款时出错',
      error_currentOrder: '更新订单时出错',
      error_editedOrder: '编辑订单时出错',
      partially_paid: '部分付款',
      add_paid: '付费会员',
      upgrade_paid: '付费升级',
      renew_paid: '付费续订',
      monthly_paid: '已缴纳月费',
      mandala_paid: '付费矩阵',
      bet_activated: '投注已激活',
      add_tickets_raffel: '门票已添加到抽奖中',
      order_not_canceled: '订单未取消',
      cancelOrder: '订单已取消',
      price_consultant: '顾问定价',
      price_consumer: '消费者价格',
      maxTickets: '最多门票',
      edit_raffle: '编辑抽奖',
      prizes: '奖项',
      new_prize: '新奖',
      add_prize: '添加奖品',
      edit_prize: '编辑奖',
      prize_name: '奖品名称',
      conversion_to_games: '转换为游戏',
      conversion_to_lotterprocoin: '转换为激活',
      trans_debit_awards: '奖金余额转账',
      conversion_awards_lotterprocoin: '将奖金余额转换为激活余额',
      pay_with_balance_awards: '将奖金余额支付',
      your_minor_side_is: '您的次要侧是 {{side}}',
      promotion_title: '促销LotterPro 带您畅游库拉索岛',
			picture_recomend: '推荐图',
			picture_list: '列表图',
			picture: '图片',
			local_hour: '本地时间',
			timezone_hour: '时区时间',
    },
  },
}
export { messages }
