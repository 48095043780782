/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getGames } from '../../services/GamesService.js'
import { i18n } from '../../utils/translates/i18n'
import GameRow from './GameRow'
import ModalGameDetails from './ModalGameDetails.js'
import { getTimezone } from '../../services/LotteriesService.js'
import ModalGameEdit from './ModalGameEdit.js'
import { decrypt } from '../../utils/crypto.js'
import ModalStatisticsDetails from './ModalStatisticsDetails.js'

function Games() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const defaultLocation = useLocation()
  const history = useHistory()

  const [games, setGames] = useState([])
  const [editDetails, setEditDetails] = useState({})
  const [timezones, setTimezones] = useState([])

  const getTimezones = async token => {
    const success = await getTimezone(token)
    if (success.status) {
      setTimezones(success.timezones)
    }
  }

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [status, setStatus] = useState('')
  const [lottery, setLottery] = useState('')
  const [lotteries, setLotteries] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(50)

  const doEditDetails = game => {
    setEditDetails(game)
  }

  const doGetGames = async () => {
    const success = await getGames(token, page, lim, status, lottery)
    setEditDetails({})
    if (success.status) {
      setGames(success.list.rows)
      setCount(success.list.count)
      setLotteries(success.lotteries)
    }
  }

  useEffect(() => {
    doGetGames()
  }, [token, page, lim, status, lottery])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  useEffect(() => {
    if (token) getTimezones(token)
  }, [token])

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h2 className="h4">{i18n.t('games')}</h2>
            </div>
          </div>
          <Pagination count={count} pageSize={lim}>
            <div className="align-items-center">
              <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                <i className="fas fa-gamepad"></i>
              </div>
            </div>
            <div className="input-group mx-3">
              <select className="form-select" value={lottery} onChange={e => setLottery(e.target.value)}>
                <option value="">{i18n.t('lotteries')}</option>
                {lotteries?.map((l, i) => (
                  <option value={l.id} key={i}>
                    {l.lottery}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-group mx-3">
              <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                <option value="">{i18n.t('status')}</option>
                <option value={1}>{i18n.t('waiting')}</option>
                <option value={4}>{i18n.t('confirmed')}</option>
                <option value={12}>{i18n.t('affected')}</option>
              </select>
            </div>
            <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
              <option className="bg-light" value="5">
                5 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="10">
                10 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="25">
                25 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="50">
                50 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="100">
                100 {i18n.t('registers')}
              </option>
            </select>
          </Pagination>
          <div className="card card-body border-0 shadow table-wrapper table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200">{i18n.t('lottery')}</th>
                  <th className="border-gray-200">{i18n.t('draw_date')}</th>
                  <th className="border-gray-200">{i18n.t('balls')}</th>
                  {/* <th className="border-gray-200">{i18n.t('special_balls')}</th> */}
                  <th className="border-gray-200">{i18n.t('levy')}</th>
                  <th className="border-gray-200">{i18n.t('action')}</th>
                </tr>
              </thead>
              <tbody>
                {games?.map((game, i) => (
                  <GameRow game={game} key={i} doEditDetails={doEditDetails} rule={rule} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
      <ModalStatisticsDetails editDetails={editDetails} />
      {['developer', 'lotteries', 'admlotteries'].includes(rule) && (
        <ModalGameDetails editDetails={editDetails} doGetGames={doGetGames} timezones={timezones} />
      )}
      {['developer', 'lotteries', 'admlotteries'].includes(rule) && (
        <ModalGameEdit editDetails={editDetails} doGetGames={doGetGames} timezones={timezones} />
      )}
    </React.Fragment>
  )
}

export default Games
