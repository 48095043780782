/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { getBetsByLotterryId } from '../../services/GamesService'
import { FormateFiatCurrency } from '../../utils/CustomFormatations'

export default function ModalStatisticsDetails({ editDetails }) {
  const btnClose = useRef('')
  const token = sessionStorage.getItem('token')
  const [bets, setBets] = useState({})

  const doGetBets = async () => {
    const success = await getBetsByLotterryId(token, editDetails?.id)
    if (success.status)
      for (const bet of success.bets)
        setBets(p => ({ ...p, [bet.balls?.split(',')?.length]: [...(p[bet.balls?.split(',')?.length] || []), bet] }))
  }

  useEffect(() => {
    setBets({})
    if (editDetails.id) doGetBets()
  }, [editDetails])

  return (
    <div
      className="modal fade"
      id="modalStatisticsDetails"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('statistics')} ${i18n.t(editDetails?.mainLottery?.lottery?.toUpperCase())} # ${i18n.t(
                editDetails?.id
              )}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body  bg-secondary">
            <div className="bg-primary p-2 px-3 text-light rounded d-flex justify-content-between">
              <span className="fw-bolder">
                {i18n.t('tickets_sold')}: {Object.entries(bets)?.reduce((a, b) => a + b[1]?.length, 0)}
              </span>
              <span className="fw-bolder">
                {i18n.t('tt_recieved').toUpperCase()}:{' '}
                {FormateFiatCurrency(
                  Object.entries(bets).reduce((a, b) => a + b[1]?.reduce((a, b) => a + b.amount, 0), 0)
                )}
              </span>
              <span className="fw-bolder">
                {i18n.t('tickets_awards')}:{' '}
                {Object.entries(bets)?.reduce((a, b) => a + b[1]?.filter(f => f.status == 18)?.length, 0)}
              </span>
              <span className="fw-bolder">
                {i18n.t('tt_awards').toUpperCase()}:{' '}
                {FormateFiatCurrency(
                  Object.entries(bets).reduce((a, b) => a + b[1]?.reduce((a, b) => a + b.prize, 0), 0)
                )}
              </span>
            </div>
            <div className="row">
              {Object.entries(bets)?.map((b, i) => (
                <div className="col-md-4 p-2" key={i}>
                  <div className="card shadow">
                    <div className="card-header p-2 bg-dark text-light text-center">Dezenas: {b[0]}</div>
                    <div className="card-body bg-light p-2">
                      <div className="list-group">
                        <div className="list-group-item bg-light d-flex justify-content-between align-items-center">
                          {i18n.t('tickets_sold')}: {b[1]?.length}
                        </div>
                        <div className="list-group-item bg-light d-flex justify-content-between align-items-center">
                          {i18n.t('tt_recieved')}: {FormateFiatCurrency(b[1]?.reduce((a, b) => a + b.amount, 0))}
                        </div>
                        <div className="list-group-item bg-light d-flex justify-content-between align-items-center">
                          {i18n.t('tickets_awards')}: {b[1]?.filter(f => f.status == 18)?.length}
                        </div>
                        <div className="list-group-item bg-light d-flex justify-content-between align-items-center">
                          {i18n.t('tt_awards')}: {FormateFiatCurrency(b[1]?.reduce((a, b) => a + b.prize, 0))}
                        </div>
                      </div>
                      {/* {JSON.stringify(b[1], null, 2)} */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
