/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getOrders, payOrder } from '../../services/OrdersService'
import { i18n } from '../../utils/translates/i18n'
import OrderRow from './OrderRow'
import OrderModal from './OrderModal/OrderModal'
import { decrypt } from '../../utils/crypto'
import OrderPaymentsModal from './OrderModal/OrderPaymentsModal'
import { toast } from 'react-toastify'
import LoaderPage from '../../utils/LoaderPage'

function Orders() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const defaultLocation = useLocation()
  const history = useHistory()
  const [show, setShow] = useState(false)

  const [orders, setOrders] = useState([])
  const [editOrder, setEditOrder] = useState(null)

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [searchUser, setSearchUser] = useState('')
  const [exact, setExact] = useState(false)
  const [euro, setEuro] = useState(false)
  const [searchOrderId, setSearchOrderId] = useState(null)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [ordenation, setOrdenation] = useState('asc')

  const doGetOrders = async () => {
    setShow(false)
    const success = await getOrders(
      token,
      page,
      lim,
      search,
      status,
      searchUser,
      ordenation,
      searchOrderId,
      exact,
      euro
    )
    if (success.status) {
      setOrders(success.list.rows)
      setCount(success.list.count)
      setShow(true)
    } else {
      setShow(true)
    }
  }

  useEffect(() => {
    doGetOrders()
  }, [token, page, lim, search, status, searchUser, ordenation, searchOrderId, exact, euro])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const doEditOrder = order => {
    if (!order) return
    setEditOrder(order)
  }

  const doPayOrder = async (order, type) => {
    setShow(false)
    const success = await payOrder(token, order, type)
    if (success.status) doGetOrders()
    else {
      toast.error(success.msg)
      setShow(true)
    }
  }

  return (
    <>
      <Menu />

      {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
        <main className="content">
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('b_orders')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="input-group me-2">
                    <input
                      type="text"
                      className="form-control"
                      value={searchUser}
                      onChange={e => setSearchUser(e.target.value)}
                      placeholder={`${i18n.t('search')} ${i18n.t('user')}`}
                    />
                    <button className={`btn btn-${exact ? 'success' : 'secondary'}`} onClick={() => setExact(!exact)}>
                      {i18n.t(exact ? 'exact' : 'partiallly')}
                    </button>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="input-group me-2">
                    <input
                      type="text"
                      className="form-control"
                      value={searchOrderId}
                      onChange={e => setSearchOrderId(e.target.value)}
                      placeholder={`${i18n.t('search')} ${i18n.t('id')}`}
                    />
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="input-group me-2">
                    <select className="form-select" value={search} onChange={e => setSearch(e.target.value)}>
                      <option value="%">{i18n.t('type')}</option>
                      <option value="add">{i18n.t('add')}</option>
                      <option value="upgrade">{i18n.t('upgrade')}</option>
                      <option value="renew">{i18n.t('renew')}</option>
                      <option value="addbalance">{i18n.t('addbalance')}</option>
                    </select>
                    <button className={`btn btn-${euro ? 'success' : 'secondary'}`} onClick={() => setEuro(!euro)}>
                      €
                    </button>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="input-group me-2">
                    <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                      <option value="">{i18n.t('status')}</option>
                      <option value="1">{i18n.t('waiting')}</option>
                      <option value="2">{i18n.t('partial')}</option>
                      <option value="3">{i18n.t('paid')}</option>
                      <option value="6">{i18n.t('canceled')}</option>
                      {/* <option value="7">{i18n.t('blind_man')}</option>
									<option value="9">{i18n.t('off')}</option>
									<option value="11">{i18n.t('expired')}</option> */}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <select className="form-select me-2" value={ordenation} onChange={e => setOrdenation(e.target.value)}>
                    <option className="bg-light" value="asc">
                      {i18n.t('olds')}
                    </option>
                    <option className="bg-light" value="desc">
                      {i18n.t('recents')}
                    </option>
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <select className="form-select me-2" value={lim} onChange={e => setLims(e.target.value)}>
                    <option className="bg-light" value="5">
                      5 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="10">
                      10 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="25">
                      25 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="50">
                      50 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="100">
                      100 {i18n.t('registers')}
                    </option>
                  </select>
                </div>
              </div>
            </Pagination>
            {!show ? (
              <LoaderPage />
            ) : (
              <div className="card card-body border-0 shadow table-wrapper table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-gray-200">{i18n.t('id')}</th>
                      <th className="border-gray-200">{i18n.t('date')}</th>
                      <th className="border-gray-200">{i18n.t('user')}</th>
                      <th className="border-gray-200">{i18n.t('type')}</th>
                      <th className="border-gray-200">{i18n.t('total')}</th>
                      <th className="border-gray-200">{i18n.t('remaining')}</th>
                      <th className="border-gray-200">{i18n.t('euro')}</th>
                      {/* <th className="border-gray-200">{i18n.t('status')}</th> */}
                      {/* <th className="border-gray-200">{i18n.t('due_date')}</th> */}
                      <th className="border-gray-200">{i18n.t('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders?.map((o, i) => (
                      <OrderRow o={o} key={i} editOrder={doEditOrder} rule={rule} />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </main>
      )}
      {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
        <>
          <OrderModal order={editOrder} payOrder={doPayOrder} rule={rule} />
          <OrderPaymentsModal order={editOrder} editOrder={doEditOrder} rule={rule} />
        </>
      )}
    </>
  )
}

export default Orders
