/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Menu from "../../components/Menu/Menu";
import {
  getTutorialById,
  updateTutorial,
} from "../../services/TutorialService";
import { i18n } from "../../utils/translates/i18n";

export default function EditTutorial() {
  const { id } = useParams();

  const DEFAULT_POST = {
    name: "",
    description: "",
    video: "",
  };
  const [post, setPost] = useState(DEFAULT_POST);

  const getTutorial = async () => {
    const response = await getTutorialById(id);
    setPost(response);
  };

  const saveTutorial = async () => {
    try {
      await updateTutorial(id, post);
      toast.success(i18n.t("tutorial_updated"));
    } catch (error) {
      toast.error(i18n.t("tutorial_error_update"));
    }
  };

  useEffect(() => {
    if (id) {
      getTutorial();
    }
  }, [id]);

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h2 className="h4">
                {`${i18n.t("edit")} ${i18n.t(post?.name?.toUpperCase())}`}
              </h2>
            </div>
          </div>
          <div className="card p-2 mb-3">
            <div className="row">
              <div className="col-lg-4 mb-2">
                <div className="input-group">
                  <div className="btn bg-dark text-light">{i18n.t("name")}</div>
                  <input
                    type="text"
                    className="form-control"
                    value={post.name}
                    onChange={(e) => setPost({ ...post, name: e.target.value })}
                    onBlur={(e) =>
                      setPost({
                        ...post,
                        name: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-8 mb-2">
                <div className="input-group">
                  <div className="btn bg-dark text-light">
                    {i18n.t("description")}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={post.description}
                    onChange={(e) =>
                      setPost({ ...post, description: e.target.value })
                    }
                    onBlur={(e) =>
                      setPost({
                        ...post,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="input-group">
                  <div className="btn bg-dark text-light">
                    {i18n.t("video_url")}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={post.video}
                    onChange={(e) =>
                      setPost({ ...post, video: e.target.value })
                    }
                    onBlur={(e) =>
                      setPost({
                        ...post,
                        video: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="input-group mt-2">
                  <div className="btn bg-dark text-light">
                    {i18n.t("language")}
                  </div>
                  <select
                    className="form-select"
                    value={post.language}
                    defaultValue={"en"}
                    onChange={(e) =>
                      setPost({ ...post, language: e.target.value })
                    }
                  >
                    <option selected value={post.language}>
                      {i18n.t("select_language")}
                    </option>
                    <option value="en">{i18n.t("english")}</option>
                    <option value="pt">{i18n.t("portuguese")}</option>
                    <option value="es">{i18n.t("spanish")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2 mb-3">
          <button
            className="btn btn-success"
            type="button"
            onClick={saveTutorial}
          >
            <i className="fa fa-save me-2"></i>
            {i18n.t("save")}
          </button>
          <Link className="btn btn-info" to="/tutorial">
            <i className="fa fa-arrow-left me-2"></i>
            {i18n.t("back")}
          </Link>
        </div>
      </main>
    </React.Fragment>
  );
}
