import React from 'react'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'
import { i18n } from '../../utils/translates/i18n'

export default function BetRow({ bet, doPrintBet }) {
  return (
    <>
      <tr>
        <td>{bet.lottery?.lottery}</td>
        <td>{FormateDate(bet.lottery?.drawDate, true, true)}</td>
        <td>{bet.user?.nick}</td>
        <td>
          <span className={`badge text-bg-${bet.statuses?.bg} p-2`}>{i18n.t(bet.statuses?.status)}</span>
        </td>
        <td>{bet.awarded}</td>
        <td>{FormateFiatCurrency(bet.amount)}</td>
        <td>{FormateFiatCurrency(bet.prize)}</td>
        <td>
          {bet.prize > 0 && (
            <button
              className="btn btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#modalPrintBet"
              onClick={() => doPrintBet(bet)}>
              <i className="fa fa-print"></i>
            </button>
          )}
        </td>
      </tr>
    </>
  )
}
