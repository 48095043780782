import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/binaries`

export async function getPercentageBinaries(token) {
  const endPoint = `${ENDPOINT}/percentages`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.get(endPoint, { headers })
  return response.data
}

export async function savePercentages(token, percentageBinaries) {
  const endPoint = `${ENDPOINT}/savepercentages`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.put(endPoint, percentageBinaries, { headers })
  return response.data
}

export async function getAllPointsOnPeriod(start, end, page, lim, search, exact, ordenation) {
  const endPoint = `${process.env.REACT_APP_API_URL}/indications/getallpointsonperiod`
  const response = await axios.post(endPoint, {start, end, page, lim, search, exact, ordenation})
  return response.data
}
