/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { i18n } from '../../utils/translates/i18n'
import { decrypt } from '../../utils/crypto'
import LoaderPage from '../../utils/LoaderPage'
import { getLogsN, getInitList } from '../../services/LogsService'
import LogsRow from './reportComponents/logsRow'
import ObjectList from './reportComponents/ObjectList'

export default function ReportLogsN() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const defaultLocation = useLocation()
  const history = useHistory()
  const [show, setShow] = useState(false)

  const [content, setContent] = useState({})
  const [viewContentModal, setViewContentModal] = useState(false)

  const [logs, setLogs] = useState([])

  // PARA PAGINAÇÃO
  const [users, setUsers] = useState([])
  const [methods, setMethods] = useState([])
  const [user, setUser] = useState('3')
  const [body, setBody] = useState('')
  const [headers, setHeaders] = useState('')
  const [response, setResponse] = useState('')
  const [method, setMethod] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [ordenation, setOrdenation] = useState('desc')

  const doGetLogs = async () => {
    setShow(false)
    const success = await getLogsN(page, lim, ordenation, body, headers, response, method, user)
    if (success.status) {
      setLogs(success.list.rows)
      setCount(success.list.count)
      setShow(true)
    } else {
      setShow(true)
    }
  }

  const setContentModal = data => {
    setContent('')
    if (data) {
      setContent(data)
      setViewContentModal(true)
    } else {
      setContent('')
      setViewContentModal(false)
    }
  }

  useEffect(() => {
    doGetLogs()
  }, [page, lim, ordenation, method, user, method])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => setPage(getPage(location)))
  }, [history])
  // FIM PARA PAGINAÇÃO

  useEffect(() => {
    const initList = async () => {
      const success = await getInitList()
      if (success.status) {
        setUsers(success.admins)
        setMethods(success.methods)
      }
    }
    initList()
  }, [])

  return (
    <>
      <Menu />

      {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
        <main className="content">
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('logs')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="row">
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={user} onChange={e => setUser(e.target.value)}>
                    <option className="bg-light" value="">
                      Qualquer Admin
                    </option>
                    {users.map((user, i) => (
                      <option className="bg-light" value={user.id} key={i}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={method} onChange={e => setMethod(e.target.value)}>
                    <option className="bg-light" value="">
                      Qualquer Operação
                    </option>
                    {methods.map((method, i) => (
                      <option className="bg-light" value={method} key={i}>
                        {method}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={ordenation} onChange={e => setOrdenation(e.target.value)}>
                    <option className="bg-light" value="asc">
                      {i18n.t('olds')}
                    </option>
                    <option className="bg-light" value="desc">
                      {i18n.t('recents')}
                    </option>
                  </select>
                </div>
                <div className="col-lg-3 mb-3">
                  <select className="form-select me-2" value={lim} onChange={e => setLims(e.target.value)}>
                    <option className="bg-light" value="5">
                      5 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="10">
                      10 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="25">
                      25 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="50">
                      50 {i18n.t('registers')}
                    </option>
                    <option className="bg-light" value="100">
                      100 {i18n.t('registers')}
                    </option>
                  </select>
                </div>
                <div className="col-lg mb-3">
                  <div className="input-group me-2">
                    <input
                      type="text"
                      className="form-control"
                      value={body}
                      onChange={e => setBody(e.target.value)}
                      placeholder="Corpo da Requisição"
                    />
                  </div>
                </div>
                <div className="col-lg mb-3">
                  <div className="input-group me-2">
                    <input
                      type="text"
                      className="form-control"
                      value={headers}
                      onChange={e => setHeaders(e.target.value)}
                      placeholder="Cabeçalho da Requisição"
                    />
                  </div>
                </div>
                <div className="col-lg mb-3">
                  <div className="input-group me-2">
                    <input
                      type="text"
                      className="form-control"
                      value={response}
                      onChange={e => setResponse(e.target.value)}
                      placeholder="Resposta da requisição"
                    />
                  </div>
                </div>

                <div className="col-lg mb-3">
                  <button className="btn btn-primary" onClick={doGetLogs}>
                    <i className="fa fa-search me-2"></i> {i18n.t('search')}
                  </button>
                </div>
              </div>
            </Pagination>
            {!show ? (
              <LoaderPage />
            ) : (
              <div className="card card-body border-0 shadow table-wrapper table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-gray-200">{i18n.t('date')}</th>
                      <th className="border-gray-200">Quem fez a Requisição</th>
                      <th className="border-gray-200">Operação</th>
                      <th className="border-gray-200">Cabeçalho da Requisição</th>
                      <th className="border-gray-200">Corpo da Requisição</th>
                      <th className="border-gray-200">Resposta da Requisição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs?.map((log, i) => (
                      <LogsRow key={i} log={log} setContentModal={setContentModal} />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </main>
      )}
      {viewContentModal && (
        <Modal
          size="lg"
          title={i18n.t(content.title)}
          contentClassName="bg-custom-light"
          show={viewContentModal}
          onHide={() => setViewContentModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{content.title}
						</Modal.Title>
					</Modal.Header>
          <Modal.Body>
            <ObjectList data={content.data} />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
