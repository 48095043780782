import React, { useRef } from 'react'
import { i18n } from '../../../utils/translates/i18n'

/**
 * props:
 * - id
 * - onSubmit
 */
function OrderModal({ order, payOrder, rule }) {
  const btnClose = useRef('')

  const doPayOrder = (order, type) => {
    if (!['developer', 'finance'].includes(rule) && ['admin', 'leaderapproved'].includes(type)) btnClose.current.click()
    else {
      payOrder(order, type)
      btnClose.current.click()
    }
  }

  return (
    <div
      className="modal fade"
      id="modalOrder"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('pay')} ${i18n.t('order')} # ${order?.id}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="btn-group w-100">
              {/* {JSON.stringify(order?.type)} */}
              {['developer', 'finance'].includes(rule) && (
                <button className="btn btn-success btn-lg" onClick={() => doPayOrder(order, 'admin')}>
                  {i18n.t('pay')}
                </button>
              )}
              {!['bet', 'addbalance','raffel'].includes(order?.type) && (
                <>
                  <button className="btn btn-secondary btn-lg" onClick={() => doPayOrder(order, 'voucher')}>
                    {i18n.t('voucher')}
                  </button>
                  {/* 
                  <button className="btn btn-info btn-lg" onClick={() => doPayOrder(order, 'leader')}>
                    {i18n.t('leader_pendent')}
                  </button>
                  {['developer', 'finance'].includes(rule) && (
                    <button className="btn btn-primary btn-lg" onClick={() => doPayOrder(order, 'leaderapproved')}>
                      {i18n.t('leader_approved')}
                    </button>
                  )}
										<button className='btn btn-danger btn-lg' onClick={() => btnClose.current.click()}>
											{i18n.t('cancel')}
										</button>
									*/}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderModal
