import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/raffels`

export async function getRaffels(search, page, limit, status, ordenation) {
  const endPoint = `${ENDPOINT}/list${page ? `?page=${page}` : ''}`
  const response = await axios.post(endPoint, { status, limit, search, ordenation })
  return response.data
}

export async function addRaffel(post) {
	const endPoint = `${ENDPOINT}/add`
	const response = await axios.post(endPoint, post)
  return response.data
}

export async function editRaffel(post) {
  const endPoint = `${ENDPOINT}/edit`
  const response = await axios.patch(endPoint, post)
  return response.data
}

export async function editPrizeImg(data) {
  const endPoint = `${ENDPOINT}/editprizeimg`
  const response = await axios.post(endPoint, data)
  return response.data
}

export async function addPrize(post) {
	let endPoint = `${ENDPOINT}/addprize`
	if(post.id) endPoint = `${ENDPOINT}/editprize`
  const response = await axios.post(endPoint, post)
  return response.data
}

export async function deletePrize(id) {
  const endPoint = `${ENDPOINT}/deleteprize`
  const response = await axios.post(endPoint, { id })
  return response.data
}

export const getTicketsByRaffleId = async raffelId => {
	const endPoint = `${ENDPOINT}/tickets/${raffelId}`
	const response = await axios.get(endPoint)
	return response.data
}