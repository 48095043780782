import React from 'react'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'
import { i18n } from '../../utils/translates/i18n'
import { Link } from 'react-router-dom'

export default function GameRow({ game, doEditDetails, rule }) {
  return (
    <>
      <tr>
        <td>{i18n.t(game?.mainLottery?.lottery?.toUpperCase())}</td>
        <td>
          <span>{FormateDate(game.drawDate, true, true)}</span>
          <div className={`bg-${game.statuses?.bg} rounded py-1 px-2 text-center`}>{i18n.t(game.statuses?.status)}</div>
        </td>
        <td>
          <div className="d-flex flex-wrap justify-content-start gap-2">
            {game?.balls
              ? game.balls.split(',').map((ball, bix) => (
                  <span
                    className={`d-flex align-items-center justify-content-center border border-primary rounded-circle text-dark fw-bold bg-light`}
                    style={{ width: '40px', height: '40px' }}
                    key={bix}>
                    {!!ball && ball.length > 1 ? ball : `0${ball}`}
                  </span>
                ))
              : i18n.t('waiting')}
          </div>
        </td>
        <td>{FormateFiatCurrency(game.levy)}</td>
        <td className="d-flex flex-wrap gap-1">
          <button
            className="btn btn-primary btn-sm py-1 px-2"
            data-bs-toggle="modal"
            data-bs-target="#modalStatisticsDetails"
            onClick={() => doEditDetails(game)}>
            <small>{i18n.t('statistics')}</small>
          </button>
          <Link to={`/reports/${game.id}`} className="btn btn-success btn-sm py-1 px-2">
            <small>{i18n.t('reports')}</small>
          </Link>
          {['developer', 'lotteries', 'admlotteries'].includes(rule) &&
            [1, 12].includes(game.status) &&
            new Date(game.drawDate).getTime() < new Date().getTime() && (
              <button
                className="btn btn-warning btn-sm py-1 px-2"
                data-bs-toggle="modal"
                data-bs-target="#modalGameDetails"
                onClick={() => doEditDetails(game)}>
                <small>{i18n.t('close')}</small>
              </button>
            )}
          {['developer', 'lotteries', 'admlotteries'].includes(rule) && [1, 12].includes(game.status) && (
            <button
              className="btn btn-info btn-sm py-1 px-2"
              data-bs-toggle="modal"
              data-bs-target="#modalGameEdit"
              onClick={() => doEditDetails(game)}>
              <i className="fa fa-edit me-2"></i>
              <small>{i18n.t('edit')}</small>
            </button>
          )}
        </td>
      </tr>
      {/* <pre>{JSON.stringify(game, null, 2) }</pre> */}
    </>
  )
}
