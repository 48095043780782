import React from 'react'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import Dashboard from './private/Dashboard/Dashboard'
// import Limits from "./private/Limits/Limits";
import Orders from './private/Orders/Orders'
import Tutorial from './private/Tutorial/Tutorial'
import Users from './private/Users/Users'
import Login from './public/Login/Login'
// import Logs from './private/Logs/Logs'
import Binary from './private/Binary/Binary'
import Calls from './private/Calls/Calls'
import ReadCall from './private/Calls/ReadCall'
import CredDeb from './private/CredDeb/CredDeb'
import CreateEditLottery from './private/Games/CreateEditLottery'
import Games from './private/Games/Games'
import Lotteries from './private/Games/Lotteries'
import ResultsLottery from './private/Games/ResultsLottery'
import QuotasPayment from './private/QuotasPayment/QuotasPayment'
import ReportGame from './private/Reports/ReportGame'
import Profile from './private/Settings/Profile'
// import Symbols from "./private/Symbols/Symbols";
import CreateTutorial from './private/Tutorial/CreateTutorial'
import EditTutorial from './private/Tutorial/EditTutorial'
// import TelegramChat from "./private/Users/TelegramChat";
import Withdrawals from './private/Withdrawals/Withdrawals'
import WithdrawalsFiles from './private/Withdrawals/WithdrawalsFiles'
import Logout from './public/Login/Logout'
import Bets from './private/Games/Bets'
// import ReportLogs from './private/Reports/ReportLogs'
import Kyc from './private/Users/Kyc'
import Points from './private/Users/Points'
import ReportLogsN from './private/Reports/ReportLogsN'
import Raffels from './private/Raffels'

function Routes() {
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => {
          return sessionStorage.getItem('token') ? children : <Redirect to="/logout" />
        }}
      />
    )
  }

  return (
    <BrowserRouter>
      <Route path="/" exact><Login /></Route>
      <Route path="/logout" exact><Logout /></Route>

      <PrivateRoute path="/dashboard"><Dashboard /></PrivateRoute>
      <PrivateRoute path="/users"><Users /></PrivateRoute>
      <PrivateRoute path="/kyc"><Kyc /></PrivateRoute>
			<PrivateRoute path="/points"><Points /></PrivateRoute>
			<PrivateRoute path="/orders"><Orders /></PrivateRoute>
			
			<PrivateRoute path="/raffels"><Raffels /></PrivateRoute>
			
			<PrivateRoute path="/games"><Games /></PrivateRoute>
			
			<PrivateRoute path="/reports/:id"><ReportGame /></PrivateRoute>
			<PrivateRoute path="/bets"><Bets /></PrivateRoute>
			<PrivateRoute path="/lotteries"><Lotteries /></PrivateRoute>
			<PrivateRoute path="/lottery/create"><CreateEditLottery /></PrivateRoute>
			<PrivateRoute path="/lottery/edit/:id"><CreateEditLottery /></PrivateRoute>
			<PrivateRoute path="/lottery/results/:id"><ResultsLottery /></PrivateRoute>
			<PrivateRoute path="/withdrawals"><Withdrawals /></PrivateRoute>
			<PrivateRoute path="/withdrawalsfiles"><WithdrawalsFiles /></PrivateRoute>
			<PrivateRoute path="/calls"><Calls /></PrivateRoute>
			<PrivateRoute path="/readcall"><ReadCall /></PrivateRoute>
			<PrivateRoute path="/creddeb"><CredDeb /></PrivateRoute>
			<PrivateRoute path="/dailygains"><QuotasPayment /></PrivateRoute>
			<PrivateRoute path="/binary"><Binary /></PrivateRoute>
			<PrivateRoute path="/tutorial"><Tutorial /></PrivateRoute>
			<PrivateRoute path="/tutorials/create"><CreateTutorial /></PrivateRoute>
			<PrivateRoute path="/tutorials/edit/:id"><EditTutorial /></PrivateRoute>
			<PrivateRoute path="/profile"><Profile /></PrivateRoute>
			<PrivateRoute path="/logs">{/* <ReportLogs /> */}<ReportLogsN /></PrivateRoute>
    </BrowserRouter>
  )
}

export default Routes
