import axios from "./BaseService";

const ENDPOINT = `${process.env.REACT_APP_API_URL}/tutorial`;

export async function getAllTutorials(token) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };
  const response = await axios.get(`${ENDPOINT}/all`, { headers });

  return response.data;
}

export async function getTutorialById(id) {
  const response = await axios.get(`${ENDPOINT}/one/${id}`);
  return response.data;
}

export async function createTutorial(newTutorial) {
  let response;

  response = await axios.post(`${ENDPOINT}/`, newTutorial);

  return response.data;
}

export async function deleteTutorial(id) {
  const response = await axios.delete(`${ENDPOINT}/${id}`);
  return response.data;
}

export async function updateTutorial(id, tutorial) {
  const response = await axios.patch(`${ENDPOINT}/${id}`, tutorial);
  return response.data;
}
