/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { FormateDate } from '../../utils/CustomFormatations'
import { sendEditGame } from '../../services/GamesService'
import { toast } from 'react-toastify'
import { zeroPad } from 'react-countdown'

export default function ModalGameEdit({ editDetails, doGetGames, timezones }) {
  const token = sessionStorage.getItem('token')
  const btnClose = useRef('')
  // const timeLimit = 10
  const [resume, setResume] = useState(false)
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  const DEFAULT_POST = {
    lottery: {},
    drawDate: '',
    drawHours: 0,
    drawMinutes: 0,
    drawDue: '',
    dueHours: 0,
    dueMinutes: 0,
  }

  const [post, setPost] = useState(DEFAULT_POST)

  const doCloseGame = async () => {
    const success = await sendEditGame(post, token)
    if (success.status) {
      doGetGames()
      setResume(false)
      setPost(DEFAULT_POST)
      toast.success('Jogo editado com sucesso!')
    } else {
      toast.error(i18n.t(success.msg))
      setPost(DEFAULT_POST)
      setResume(false)
    }
    btnClose.current.click()
  }

  useEffect(() => {
    setResume(false)
    setPost({ ...DEFAULT_POST, lottery: editDetails })
  }, [editDetails.id])

  return (
    <div
      className="modal fade"
      id="modalGameEdit"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('details')} ${i18n.t(editDetails?.mainLottery?.lottery?.toUpperCase())} # ${i18n.t(
                editDetails?.id
              )}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            {!resume ? (
              <>
                <div className="alert bg-warning">
                  {`${timezones?.filter(t => t.id == editDetails?.mainLottery?.timezone)[0]?.text}`}
                  <ul className="list-group list-group-flush">
                    {editDetails?.mainLottery?.dayAwards?.map((d, i) => (
                      <li className="list-group-item" key={i}>{`${i18n.t(days[d.dayWeek])}, ${zeroPad(
                        d.hour,
                        2
                      )}:${zeroPad(d.minutes, 2)}`}</li>
                    ))}
                  </ul>
                </div>

                <div className="col-12 bg-dark mb-2 rounded p-2">
                  <div className="input-group mb-2">
                    <div className="btn bg-dark text-light">{i18n.t('draw_date')}</div>
                    <input
                      type="date"
                      className="form-control"
                      value={post.drawDate}
                      onChange={e => setPost({ ...post, drawDate: e.target.value })}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('hour')}</div>
                        <select
                          className="form-select"
                          value={post.drawHours}
                          onChange={e => setPost({ ...post, drawHours: e.target.value })}>
                          {[...Array(24)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('minutes')}</div>
                        <select
                          className="form-select"
                          value={post.drawMinutes}
                          onChange={e => setPost({ ...post, drawMinutes: e.target.value })}>
                          {[...Array(60)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2 bg-dark rounded p-2">
                  <div className="input-group mb-2">
                    <div className="btn bg-dark text-light">{i18n.t('draw_due')}</div>
                    <input
                      type="date"
                      className="form-control"
                      value={post.drawDue}
                      onChange={e => setPost({ ...post, drawDue: e.target.value })}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('hour')}</div>
                        <select
                          className="form-select"
                          value={post.dueHours}
                          onChange={e => setPost({ ...post, dueHours: e.target.value })}>
                          {[...Array(24)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-2">
                        <div className="btn bg-dark text-light">{i18n.t('minutes')}</div>
                        <select
                          className="form-select"
                          value={post.dueMinutes}
                          onChange={e => setPost({ ...post, dueMinutes: e.target.value })}>
                          {[...Array(60)].map((x, i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {post.drawDate && post.drawDue && (
                  <div className="col-12 mb-2">
                    <button className="btn btn-success" onClick={() => setResume(true)}>
                      {i18n.t('save')}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="card-body">
                  <ul className="list-group list-group-hover list-group-striped mb-5">
                    <li className="list-group-item">
                      <strong>{i18n.t('draw_date')}: </strong>
                      {`${FormateDate(post.drawDate)}, ${zeroPad(post.drawHours, 2)}:${zeroPad(post.drawMinutes, 2)}`}
                    </li>
                    <li className="list-group-item">
                      <strong>{i18n.t('draw_due')}: </strong>
                      {`${FormateDate(post.drawDue)}, ${zeroPad(post.dueHours, 2)}:${zeroPad(post.dueMinutes, 2)}`}
                    </li>
                  </ul>

                  <div className="d-flex gap-3">
                    <button className="btn btn-success" onClick={doCloseGame}>
                      {i18n.t('confirm_approve')}
                    </button>
                    <button className="btn btn-danger" onClick={() => setResume(false)}>
                      {i18n.t('back')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <pre>{JSON.stringify(post, null, 2)}</pre> */}
        </div>
      </div>
    </div>
  )
}
