/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getBets } from '../../services/GamesService.js'
import { i18n } from '../../utils/translates/i18n'
import BetRow from './BetRow.js'
import ModalPrintBet from './ModalPrintBet.js'

export default function Bets() {
  const defaultLocation = useLocation()
  const history = useHistory()
	const [printBet, setPrintBet] = useState({})

  const [bets, setBets] = useState([])

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const doGetBets = async () => {
    const success = await getBets(token, page, lim, search, status)

    if (success.status) {
      setBets(success.list.rows)
      setCount(success.list.count)
    }
	}
	
	const doPrintBet = (bet) => {
		setPrintBet(bet)
	}

  useEffect(() => {
    doGetBets()
  }, [token, page, lim, status, search])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <h2 className="h4">{i18n.t('bets')}</h2>
            </div>
          </div>
          <Pagination count={count} pageSize={lim}>
            <div className="align-items-center me-2">
              <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                <i className="fas fa-gamepad"></i>
              </div>
            </div>
            <div className="input-group me-2">
              <input
                type="text"
                className="form-control"
                value={search}
                placeholder={`${i18n.t('search')} ${i18n.t('user')}`}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <div className="input-group me-2">
              <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
							<option value={'all'}>{`${i18n.t('status')} (${i18n.t('all')})`}</option>
              <option value={16}>{i18n.t('hits')}</option>
              <option value={17}>{i18n.t('no_award')}</option>
              <option value={18}>{i18n.t('awarded')}</option>
              <option value={1}>{i18n.t('waiting')}</option>
              <option value={4}>{i18n.t('confirmed')}</option>
              <option value={6}>{i18n.t('canceled')}</option>
              <option value={9}>{i18n.t('off')}</option>
              <option value={11}>{i18n.t('expired')}</option>
              </select>
            </div>
            <select className="form-select me-2" value={lim} onChange={e => setLims(e.target.value)}>
              <option className="bg-light" value="5">
                5 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="10">
                10 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="25">
                25 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="50">
                50 {i18n.t('registers')}
              </option>
              <option className="bg-light" value="100">
                100 {i18n.t('registers')}
              </option>
            </select>
          </Pagination>
          <div className="card card-body border-0 shadow table-wrapper table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200">{i18n.t('lottery')}</th>
                  <th className="border-gray-200">{i18n.t('draw_date')}</th>
                  <th className="border-gray-200">{i18n.t('user')}</th>
                  <th className="border-gray-200">{i18n.t('status')}</th>
                  <th className="border-gray-200">{i18n.t('hits')}</th>
                  <th className="border-gray-200">{i18n.t('amount')}</th>
                  <th className="border-gray-200">{i18n.t('prize')}</th>
                </tr>
              </thead>
              <tbody>
                {bets?.map((bet, i) => (
                  <BetRow bet={bet} key={i} doPrintBet={doPrintBet} />
                ))}
              </tbody>
            </table>
          </div>
				</div>
				<ModalPrintBet printBet={printBet} />
      </main>
    </React.Fragment>
  )
}
