/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu/Menu'
import { i18n } from '../../utils/translates/i18n'
import { getQuotasPaid, payQuotas, simulation } from '../../services/QuotasService'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'
import { toast } from 'react-toastify'

export default function QuotasPayment() {
  const token = sessionStorage.getItem('token')
  const [percent, setPercent] = useState(0)
  const [resultSimulation, setResultSimulation] = useState(null)
  const [quotasPaid, setQuotasPaid] = useState([])

  const doSimulation = async () => {
    const success = await simulation(token, percent)
    if (success.status) {
      setResultSimulation(success)
    }
  }

  const doPayQuotas = async () => {
    const success = await payQuotas(token, percent)
    if (success.status) {
      setResultSimulation(success)
      doGetQuotasPaid()
      toast.success(i18n.t('success_update'))
    }
  }

  const doGetQuotasPaid = async () => {
    const success = await getQuotasPaid(token)
    if (success.status) {
      setQuotasPaid(success.quotas)
      setPercent(success.percent)
    }
  }

  useEffect(() => {
    if (token) doGetQuotasPaid()
  }, [token])

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <>
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('ganh_diario')}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="card card-body border-0 shadow">
                  <div className="row mt-3">
										<div className="form-group col-md">
											<h5>{ i18n.t('define_percent_next_payment')}</h5>
                      <div className="input-group">
                        <span className="bg-primary p-3">{i18n.t('perc')}</span>
                        <input
                          type="number"
                          min={0}
                          step={0.01}
                          value={percent}
                          className="form-control"
                          onChange={e => setPercent(e.target.value < 0 ? 0 : e.target.value > 1 ? 1 : e.target.value)}
                        />
                        <button className="btn btn-success text-light" onClick={doSimulation}>
                          <i className="fa fa-question-circle fa-lg"></i> {i18n.t('simulation')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {resultSimulation && resultSimulation.total > 0 && (
                <div className="col-lg-6 mb-3">
                  <div className="alert alert-info h-100">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="fs-5 fw-bold">
                        {`${FormateDate(new Date())} - ${i18n.t('valuePay')} ${FormateFiatCurrency(
                          resultSimulation?.total
                        )}`}
                      </div>
                      {resultSimulation?.total > 0 && (
                        <button className="btn btn-success fs-5" onClick={doPayQuotas}>
                          <i className="fa fa-check me-2"></i> {i18n.t('define_percent')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 mb-4">
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                  <h2 className="h4">
                    {i18n.t('paids_today')}: {FormateFiatCurrency(quotasPaid?.reduce((a, b) => a + b.amount, 0))}
                  </h2>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="border-gray-200">{i18n.t('date')}</th>
                        <th className="border-gray-200">{i18n.t('nick')}</th>
                        <th className="border-gray-200">{i18n.t('amount')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quotasPaid?.map((q, i) => (
                        <tr key={i}>
                          <td>{FormateDate(q.referDate)}</td>
                          <td>{i18n.t(q.nick)}</td>
                          <td>{FormateFiatCurrency(q.amount)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      </main>
    </React.Fragment>
  )
}
