import { i18n } from '../../utils/translates/i18n'
import { Link } from 'react-router-dom'
import LotteryRowImg from './LotteryRowImg'
import LotteryRowImgRecomend from './LotteryRowImgRecomend'
import LotteryRowImgList from './LotteryRowImgList'

export default function LotteryRow({ lottery, timezones, doGetLotteries, rule }) {
  return (
    <>
      <tr>
        <td>
          <div>{lottery.lottery?.toUpperCase()}</div>
          <div>
            <span className={`p-1 fs-7 rounded bg-${lottery.statuses?.bg}`}>{i18n.t(lottery.statuses?.status)}</span>
          </div>
        </td>
        <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <div>{lottery.country?.ptbr}</div>
          <div>{timezones.filter(t => t.id.toString() === lottery.timezone.toString())[0]?.text}</div>
        </td>
        <td>
          <LotteryRowImg lottery={lottery} doGetLotteries={doGetLotteries} />
        </td>
        <td>
          <LotteryRowImgRecomend lottery={lottery} doGetLotteries={doGetLotteries} />
        </td>
        <td>
          <LotteryRowImgList lottery={lottery} doGetLotteries={doGetLotteries} />
        </td>
        <td className="d-flex flex-column gap-1">
          {['developer', 'lotteries', 'admlotteries'].includes(rule) && (
            <Link className="btn btn-warning btn-sm me-2 p-1" to={`/lottery/edit/${lottery.id}`}>
              <i className="fa fa-edit me-2"></i>
              {i18n.t('edit')}
            </Link>
          )}
          <Link className="btn btn-info btn-sm me-2 p-1" to={`/lottery/results/${lottery.id}`}>
            <i className="fa fa-edit me-2"></i>
            {i18n.t('results')}
          </Link>
        </td>
      </tr>
    </>
  )
}
