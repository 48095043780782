/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getCalls } from '../../services/CallsService'
import { i18n } from '../../utils/translates/i18n'
import CallRow from './CallRow'

export default function Calls() {
  const defaultLocation = useLocation()
  const history = useHistory()

  const [calls, setCalls] = useState([])

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const doGetCalls = async () => {
    const success = await getCalls(token, page, lim, search, status)
    if (success.status) {
      setCalls(success.list.rows)
      setCount(success.list.count)
    }
  }

  useEffect(() => {
    doGetCalls()
  }, [token, page, lim, search, status])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const ReadCall = id => {
    sessionStorage.setItem('idCall', id)
    window.location = '/readcall'
  }

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <>
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('calls')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="align-items-center me-2">
                <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                  <i className="fas fa-search"></i>
                </div>
              </div>
              <div className="input-group me-2">
                <input
                  className="form-control"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={`${i18n.t('search')} ${i18n.t('by')} ${i18n.t('nick')}, ${i18n.t('subject')} ${i18n.t(
                    'or'
                  )} ${i18n.t('description')}`}
                />
              </div>
              <select className="form-select w-50 me-2" value={status} onChange={e => setStatus(e.target.value)}>
                <option className="bg-light" value="">
                  {i18n.t('status')}
                </option>
                <option className="bg-light" value="1">
                  {i18n.t('waiting')}
                </option>
                <option className="bg-light" value="15">
                  {i18n.t('responded')}
                </option>
                <option className="bg-light" value="19">
                  {i18n.t('closed')}
                </option>
              </select>
              <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
                <option className="bg-light" value="5">
                  5 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="10">
                  10 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="25">
                  25 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="50">
                  50 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="100">
                  100 {i18n.t('registers')}
                </option>
              </select>
            </Pagination>
            <div className="card card-body bcall-0 shadow">
              {calls?.map((c, i) => (
                <CallRow c={c} key={i} ReadCall={ReadCall} />
              ))}
            </div>
          </div>
        </>
      </main>
    </React.Fragment>
  )
}
