import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteTutorial } from "../../services/TutorialService";
import { i18n } from "../../utils/translates/i18n";

export default function TutorialRow({ tutorial }) {
  const history = useHistory();

  const handleDelete = async () => {
    try {
      await deleteTutorial(tutorial.id);
      toast.success(i18n.t("tutorial_deleted"));

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error(i18n.t("tutorial_error_delete"));
    }
  };

  return (
    <tr>
      <td style={{ flexWrap: "wrap" }}>
        <p style={{ whiteSpace: "pre-wrap", flexWrap: "wrap", maxWidth: 350 }}>
          {tutorial.name}
        </p>
      </td>
      <td style={{ flexWrap: "wrap" }}>
        <p style={{ whiteSpace: "pre-wrap", flexWrap: "wrap", maxWidth: 350 }}>
          {tutorial.video}
        </p>
      </td>
      <td style={{ flexWrap: "wrap" }}>
        <p style={{ whiteSpace: "pre-wrap", flexWrap: "wrap", maxWidth: 350 }}>
          {tutorial.description}
        </p>
      </td>
      <td style={{ flexWrap: "wrap" }}>
        <p style={{ whiteSpace: "pre-wrap", flexWrap: "wrap", maxWidth: 350 }}>
          {tutorial.language}
        </p>
      </td>
      <td>
        <button
          className="btn btn-warning btn-sm me-2"
          onClick={() => history.push(`/tutorials/edit/${tutorial.id}`)}
        >
          <i className="fas fa-edit"></i>
        </button>
        <button className="btn btn-danger btn-sm" onClick={handleDelete}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  );
}
