import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { i18n } from '../translates/i18n'

const PAGE_SIZE = 10

const Pagination = props => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const queryPage = parseInt(query.get('page')) ? parseInt(query.get('page')) : 1

  const getPageClasses = page => {
    const isActive = queryPage === page || (!queryPage && page === 1)
    return isActive ? 'active' : ''
  }

  const getPageLink = page => {
    return `${window.location.pathname}?page=${page}`
  }

  let pagesQty = Math.ceil(props.count / (props.pageSize ? props.pageSize : PAGE_SIZE))
  let pages = []
  queryPage - 4 > 0 && queryPage + 2 > pagesQty && pages.push(queryPage - 4)
  queryPage - 3 > 0 && queryPage + 1 > pagesQty && pages.push(queryPage - 3)
  queryPage - 2 > 0 && pages.push(queryPage - 2)
  queryPage - 1 > 0 && pages.push(queryPage - 1)
  queryPage > 0 && pages.push(queryPage)
  queryPage + 1 <= pagesQty && pages.push(queryPage + 1)
  queryPage + 2 <= pagesQty && pages.push(queryPage + 2)
  queryPage + 3 <= pagesQty && queryPage - 1 <= 0 && pages.push(queryPage + 3)
  queryPage + 4 <= pagesQty && queryPage - 2 <= 0 && pages.push(queryPage + 4)

  return (
    <>
      <div className="border-0 mb-0 p-2 d-flex flex-column flex-lg-row align-items-center justify-content-between">
        {props.children && (
          <div className="d-flex flex-grow-1 flex-column flex-lg-row align-items-center justify-content-start ms-3">
            {props.children}
          </div>
        )}
        <nav aria-label="Page navigation">
          <ul className="pagination mb-0 mx-2">
            {pages.map(p => (
              <li key={p} className={`page-item ${getPageClasses(p)}`}>
                <Link to={getPageLink(p)} className="page-link">
                  {p}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        {props.count > 1
          ? `${i18n.t('founds')} ${props.count} ${i18n.t('results')}`
          : props.count < 1
          ? `${i18n.t('no_results_found')}`
          : `${i18n.t('found')} 1 ${i18n.t('result')}`}
      </div>
    </>
  )
}

export default Pagination
