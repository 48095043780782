/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getGame } from '../../services/GamesService'
import Menu from '../../components/Menu/Menu'
import { i18n } from '../../utils/translates/i18n'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'
import jsPDF from 'jspdf'

export default function ReportGame() {
  const param = useParams()
  const token = sessionStorage.getItem('token')
  const reportTemplateRef = useRef(null)
  const [prizes, setPrizes] = useState([])

  const [bets, setBets] = useState([])

  const getBets = async id => {
    const response = await getGame(id, token)
    if (response.status) {
      setBets(response.bets)
      setPrizes(response.gamePackages)
    }
  }

  const handleGeneratePdf = () => {
    const doc = new jsPDF('l', 'pt', 'a4', true)

    doc.setFont('Inter-Regular', 'normal')

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save(
          `${bets[0]?.lottery?.lottery?.toUpperCase()}_${bets[0]?.lottery?.id}_${
            bets[0]?.lottery?.drawDate?.split('T')[0]
          }`
        )
      },
    })
  }

  useEffect(() => {
    if (param.id && token) {
      getBets(param.id)
    }
  }, [param])

  return (
    <>
      <Menu />
      <main className="content">
        <div className="container-fluid">
          <button className="btn btn-primary" onClick={handleGeneratePdf}>
            {i18n.t('download_pdf')}
          </button>
          <div
            className="card card-body border-0 shadow"
            ref={reportTemplateRef}
            style={{ width: '22cm', fontSize: '10pt' }}>
            <h3>
              {i18n.t('reports')} {bets[0]?.lottery?.lottery?.toUpperCase()}
            </h3>
            <div className="d-flex flex-wrap gap-4">
              <div>
                {i18n.t('concourse')}: {bets[0]?.lottery?.id}
              </div>
              <div>
                <label className="me-3">{i18n.t('draw_date')}: </label>
                {FormateDate(bets[0]?.lottery?.drawDate, true, true)}
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>ID</th>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                    {i18n.t('user')}
                  </th>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                    {i18n.t('emission')}
                  </th>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                    {i18n.t('choosed_numbers')}
                  </th>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                    {i18n.t('qty_dezenas')}
                  </th>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                    {i18n.t('bet_amount')}
                  </th>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                    {i18n.t('can_award')}
                  </th>
                  <th style={{ border: 'solid 1px', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                    {i18n.t('status')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {bets?.map((bet, i) => (
                  <tr>
                    <td style={{ border: 'solid 1px #dadada', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                      {bet?.id}
                    </td>
                    <td style={{ border: 'solid 1px #dadada', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                      {bet?.user?.nick.toUpperCase()}
                      <br />
                      {bet?.user?.name}
                    </td>
                    <td style={{ border: 'solid 1px #dadada', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                      {FormateDate(bet?.createdAt, true, true)}
                    </td>
                    <td
                      style={{
                        border: 'solid 1px #dadada',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: '6pt',
                        maxWidth: '100px',
                      }}>
                      <div className="d-flex flex-wrap gap-1 justify-content-center">
                        {bet?.balls?.split(',').map((ball, bix) => (
                          <span
                            className={`d-flex align-items-center justify-content-center border border-primary rounded-circle text-dark fw-bold bg-light`}
                            style={{ width: '15px', height: '15px', fontSize: '6pt' }}
                            key={bix}>
                            {!!ball && ball.length > 1 ? ball : `0${ball}`}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td style={{ border: 'solid 1px #dadada', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                      {bet?.balls?.split(',').length}
                    </td>
                    <td style={{ border: 'solid 1px #dadada', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                      {FormateFiatCurrency(bet?.amount)}
                    </td>
                    <td style={{ border: 'solid 1px #dadada', padding: '5px', textAlign: 'center', fontSize: '6pt' }}>
                      {FormateFiatCurrency(
                        parseFloat(bet?.amount) *
                          parseInt(prizes?.filter(f => f.qty == bet?.balls?.split(',').length)[0]?.multiplier)
                      )}
                    </td>
                    <td
                      style={{
                        border: 'solid 1px #dadada',
                        padding: '5px',
                        textAlign: 'center',
                        fontSize: '6pt',
                        wordWrap: 'break-word',
                      }}>
                      {i18n.t(bet?.statuses?.status)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  )
}
