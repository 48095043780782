import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/logs/`

export async function getLogs(search, dataPost, date) {
  const endPoint = `${ENDPOINT}search`
  const response = await axios.post(endPoint, { search, dataPost, date })
  return response.data
}

export async function getLogsN(page, limit, ordenation, body, headers, response, method, user) {
  const endPoint = `${ENDPOINT}n${page ? `?page=${page}` : ''}`
  const result = await axios.post(endPoint, { limit, ordenation, body, headers, response, method, user })
  return result.data
}

export async function getInitList() {
  const response = await axios.get(`${ENDPOINT}initlist`)
  return response.data
}

export async function getLogList(userId, page) {
  const response = await axios.get(`${ENDPOINT}?page=${page}&userId=${userId ? userId : ''}`)
  return response.data
}
