import React, { useEffect, useRef, useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { RemoveSpaceAccents } from '../../utils/CustomFormatations'
import { editImg } from '../../services/LotteriesService'
import { toast } from 'react-toastify'

export default function LotteryRowImg({ lottery, doGetLotteries }) {
  const [file, setFile] = useState(null)
  const btnFile = useRef('')
  const token = sessionStorage.getItem('token')

  const doUpload = async () => {
    const formData = new FormData()
    let fileName = file.name.split('.')
    let extension = fileName[fileName.length - 1]
    formData.append(
      'file',
      file,
      `${new Date().getTime()}_${RemoveSpaceAccents(fileName.slice(0, -1).join('_'))}.${extension}`
    )

    const result = await editImg(token, lottery.id, formData)
    if (result.status) {
      toast.success(i18n.t('lottery_updated'))
      if (doGetLotteries) doGetLotteries()
    }
  }

  useEffect(() => {
    if (file) doUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <>
      <div className={`text-center`} style={{ cursor: 'pointer' }} onClick={() => btnFile.current.click()}>
        <img
          src={
            file
              ? URL.createObjectURL(file)
              : !!lottery.img
              ? `${process.env.REACT_APP_API_URL}/lotteries/${lottery.img}`
              : `${process.env.REACT_APP_API_URL}/img/noimage.jpg`
          }
          crossOrigin="anonymous"
          alt="lottery"
          className={`img-fluid`}
          style={{ maxWidth: '60px', maxHeight: '60px' }}
        />
      </div>
      <div className="text-center">
        <input type="file" id="file" ref={btnFile} className="d-none" onChange={e => setFile(e.target.files[0])} />
      </div>
    </>
  )
}
