import { useEffect, useRef, useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { toast } from 'react-toastify'
import LoaderPage from '../../utils/LoaderPage'
import { editPrizeImg } from '../../services/RaffelsService'

export default function PrizeImg({ data, doGetRaffels, setNewRaffel }) {
  const btnFile = useRef('')
  const [show, setShow] = useState(true)
  const [file, setFile] = useState()

  const doUpload = async () => {
    setShow(false)
    const formData = new FormData()
    let fileName = file.name.split('.')
    let extension = fileName[fileName.length - 1]
    formData.append(
      'file',
      file,
      `${new Date().getTime()}_${fileName
        .slice(0, -1)
        .join('_')
        .replace(/[^a-zA-Z0-9\s]/g, '')}.${extension}`
    )
    formData.append('id', data.id)
    formData.append('raffelId', data.raffelId)

    const success = await editPrizeImg(formData)
    if (success.status) {
      toast.success(i18n.t(success.msg))
      setNewRaffel(p => ({ ...p, prizes: success.prizes }))
      doGetRaffels()
      setFile(null)
      setShow(true)
		} else {
			toast.error(i18n.t(success.msg))
			setShow(true)
		}
  }

  useEffect(() => {
    if (file) doUpload()
  }, [file])

  return !show ? (
    <LoaderPage />
  ) : (
    <>
      <div style={{ cursor: 'pointer' }} onClick={() => btnFile.current.click()}>
        <img
          src={
            file
              ? URL.createObjectURL(file)
              : data.imgs?.length > 0
              ? `${process.env.REACT_APP_API_URL}/images/${data.imgs[0].img}`
              : '/logos/favicon.png'
          }
          crossOrigin="anonymous"
          alt="user"
          className={`img-thumbnail img-fluid shadow`}
          // style={{ height: '90px' }}
        />
      </div>
      <div className="text-center">
        <input type="file" id="file" ref={btnFile} className="d-none" onChange={e => setFile(e.target.files[0])} />
      </div>
    </>
  )
}
