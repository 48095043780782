/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { i18n } from '../../utils/translates/i18n'
import { toast } from 'react-toastify'
import { createDayAward, doEditDayAward } from '../../services/LotteriesService'

export default function ModalDayAward({ editDayAward, doGetDayAwards }) {
  const token = sessionStorage.getItem('token')
  const btnClose = useRef('')

  const DEFAULT_POST = {
    id: '',
    mainLotteryId: '',
    dayWeek: 0,
    hour: 0,
    minutes: 0,
    status: 4,
  }
  const [post, setPost] = useState(DEFAULT_POST)

  const saveDayAward = async () => {
    if (post.id) {
      const success = await doEditDayAward(token, post)
      if (success.status) {
        toast.success(i18n.t('lottery_updated'))
        doGetDayAwards(success.editedDayAward?.mainLotteryId)
        btnClose.current.click()
      }
    } else {
      const success = await createDayAward(token, post)
      if (success.status) {
        toast.success(i18n.t('lottery_created'))
        doGetDayAwards(success.newDayAward?.mainLotteryId)
        btnClose.current.click()
      }
    }
  }

  useEffect(() => {
    setPost(DEFAULT_POST)
    if (editDayAward.mainLotteryId) {
      setPost(editDayAward)
    }
  }, [editDayAward])

  return (
    <div
      className="modal fade"
      id="modalDayAward"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {post.id ? `${i18n.t('edit')} ${i18n.t('draw_date')}` : `${i18n.t('create')} ${i18n.t('draw_date')}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="input-group mb-2">
              <div className="btn bg-dark text-light">{i18n.t('day')}</div>
              <select
                className="form-select"
                value={post.dayWeek}
                onChange={e => setPost({ ...post, dayWeek: e.target.value })}>
                <option value={0}>{i18n.t('sunday')}</option>
                <option value={1}>{i18n.t('monday')}</option>
                <option value={2}>{i18n.t('tuesday')}</option>
                <option value={3}>{i18n.t('wednesday')}</option>
                <option value={4}>{i18n.t('thursday')}</option>
                <option value={5}>{i18n.t('friday')}</option>
                <option value={6}>{i18n.t('saturday')}</option>
              </select>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="btn bg-dark text-light">{i18n.t('hour')}</div>
                  <select
                    className="form-select"
                    value={post.hour}
                    onChange={e => setPost({ ...post, hour: e.target.value })}>
                    {[...Array(24)].map((x, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="btn bg-dark text-light">{i18n.t('minutes')}</div>
                  <select
                    className="form-select"
                    value={post.minutes}
                    onChange={e => setPost({ ...post, minutes: e.target.value })}>
                    {[...Array(60)].map((x, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-2">
                  <div className="btn bg-dark text-light">{i18n.t('status')}</div>
                  <select
                    className="form-select"
                    value={post.status}
                    onChange={e => setPost({ ...post, status: e.target.value })}>
                    <option value="4">{i18n.t('confirmed')}</option>
                    <option value="6">{i18n.t('canceled')}</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="d-flex gap-2">
              <button className="btn btn-success" type="button" onClick={saveDayAward}>
                <i className="fa fa-save me-2"></i>
                {i18n.t('save')}
              </button>
              <button className="btn btn-secondary" type="button" onClick={() => btnClose.current.click()}>
                <i className="fa fa-close me-2"></i>
                {i18n.t('doclose')}
              </button>
              {/* <pre>{JSON.stringify(post, null, 2)}</pre> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
