const messages = {
  de: {
    translations: {
      a_crase: 'zu',
      about: 'Über Uns',
      above_the_same: 'Über genau dasselbe',
      action: 'Aktion',
      actionTemplates: 'Aktionsvorlagen',
      activate_date: 'Aktivierungsdatum',
      active_automations: 'Aktive Automatisierungen',
      active_connections: 'Aktive Verbindungen',
      active_monitors: 'Aktive Monitore',
      active_users: 'Aktive Benutzer',
      ad: 'Anzeige',
      add: 'Hinzufügen',
      add_option: 'Option hinzufügen',
      add_payment: 'Zahlung hinzufügen',
      add_question: 'Frage hinzufügen',
      add_receipt: 'Quittung hinzufügen',
      add_wallet: 'Geldbörse hinzufügen',
      address: 'Adresse',
      admin: 'Administrator',
      advance: 'Vorschuss',
      affected: 'Betroffen',
      after: 'Nach',
      air_transport: 'Lufttransport',
      ajust_limite: 'Grenzwertanpassung',
      ajust_saldo: 'Saldo-Anpassung',
      alert: 'Alarm',
      alerts: 'Warnungen',
      all: 'Alle',
      all_departments: 'Alle Abteilungen',
      allies_of: 'Verbündete von',
      allRightsReserved: 'Alle Rechte vorbehalten',
      almost_there: 'Fast da',
      already_offset: 'Bereits ausgeglichen',
      already_registered: 'Bereits registriert? Hier klicken und einloggen',
      amount: 'Betrag',
      amount_above_balance: 'Betrag über dem Saldo',
      and: 'Und',
      and_more: 'und mehr',
      answer: 'Antwort',
      ao: 'zu',
      approve: 'Genehmigen',
      April: 'April',
      asc: 'Aufsteigend',
      at: 'An',
      August: 'August',
      automations: 'Automatisierungen',
      awaiting_email_confirmation: 'Warten auf E-Mail-Bestätigung!',
      b_order: 'Bestellung',
      b_orders: 'Bestellungen',
      back: 'Zurück',
      bairroInteresse: 'Stadtteil',
      balance: 'Saldo',
      balance_available: 'Verfügbarer Saldo',
      banned: 'Gesperrt',
      before_contact: 'Bevor Sie uns kontaktieren',
      binary: 'Binär',
      birth: 'Geburt',
      blind_man: 'Blind',
      body_monthly:
        'Ihr monatlicher Beitrag ist abgelaufen, und Ihr BOT TRADER wurde deaktiviert. Bitte bezahlen Sie die Zahlungsanweisung, um Ihre Vorteile wieder zu nutzen.',
      bonus_saldo_trader: 'Bonus-Saldo Trader',
      book_health: 'BookHealth',
      but_possible_register:
        'Es ist weiterhin möglich, sich zu registrieren, aber Sie werden nicht mit einem empfehlenden Freund verknüpft.',
      buy_volume: 'Kaufvolumen',
      buyer: 'Käufer',
      by: 'von',
      bye: 'Bis bald',
      call: 'Anruf',
      call_to: 'Anruf an',
      calls: 'Unterstützung',
      cancel: 'Stornieren',
      canceled: 'Abgesagt',
      career_plan: 'Karriereplan',
      casa_verde_amarela: 'Gelbes grünes Haus',
      cash_payment: 'Barzahlung',
      cash_payment_return: 'Rückzahlung der Barzahlung',
      cash_receipt: 'Barquittung',
      change_indicant: 'Indikator ändern',
      change_photo: 'Foto ändern',
      change_plan: 'Plan ändern',
      check_payment: 'Scheckzahlung',
      check_payment_return: 'Rückzahlung der Scheckzahlung',
      check_receipt: 'Scheckquittung',
      choose: 'Wählen',
      cidadeInteresse: 'Stadt',
      city: 'Stadt',
      claims: 'Ansprüche',
      clean: 'Reinigen',
      click_and_know: 'Klicken und erfahren',
      click_to_copy_the_wallet: 'Klicken Sie, um das Portemonnaie zu kopieren.',
      client: 'Klient',
      clients: 'Kunden',
      close: 'Schließen',
      cnh: 'Fahrerlaubnis',
      cnhDate: 'Ausstellungsdatum der Fahrerlaubnis',
      cnpj: 'CNPJ',
      cnpj_invalid: 'Ungültige CNPJ',
      coin: 'Münze',
      color: 'Farbe',
      commercial: 'Kommerziell',
      company: 'Firmenname',
      complement: 'Ergänzen',
      comporRenda: 'Wird Einkommen mit jemandem teilen?',
      compRenda: 'Art der Einkommensbestätigung',
      confirm_approve: 'Genehmigung bestätigen',
      confirm_delete: 'Löschung bestätigen',
      confirm_new_password: 'Neues Passwort bestätigen',
      confirm_new_password_security: 'Sicherheitsneues Passwort bestätigen',
      confirm_password: 'Zugangspasswort bestätigen',
      confirm_password_security: 'Sicherheitspasswort bestätigen',
      confirm_your_new_password: 'Geben Sie Ihr neues Passwort erneut ein',
      confirmed: 'Bestätigt',
      confirmed_email: 'Bestätigte E-Mail',
      congratulations: 'Herzlichen Glückwunsch',
      contact: 'Kontakt',
      contract: 'Vertrag',
      copied: 'Kopiert',
      copy: 'Kopieren',
      copy_success: 'Erfolgreich kopiert!',
      correspondences: 'Korrespondenzen',
      countries: 'Länder',
      country: 'Land',
      cpf: 'CPF',
      cpf_invalid: 'Ungültige CPF',
      create_order_success: 'Bestellung erfolgreich erstellt!',
      created: 'Registriert am',
      cred: 'Kredit',
      cred_binario: 'Binärer Punktekredit',
      cred_deb: 'Kredit-/Debitkarten',
      cred_game_donate: 'Spielguthaben spenden',
      cred_ind_direta: 'Direkte Empfehlungsgutschrift',
      cred_ind_indireta: 'Indirekte Empfehlungsgutschrift',
      cred_mandala: 'Mandala-Gutschrift',
      cred_pagar_com_saldo: 'Zahlung mit Guthabenkredit',
      cred_pontos: 'Punktekredit',
      cred_recb_trader: 'Händlerguthaben empfangen',
      cred_renovacao: 'Gutschrift für Verlängerung',
      cred_saldo_disp: 'Verfügbares Kreditguthaben',
      cred_upgrade: 'UpGrade-Gutschrift',
      credit_card_payment: 'Kreditkartenzahlung',
      credit_card_payment_return: 'Rückzahlung der Kreditkartenzahlung',
      credit_card_receipt: 'Kreditkartenquittung',
      current: 'Aktuell',
      current_password: 'Aktuelles Passwort',
      current_wallet: 'Aktuelles Portemonnaie',
      dashboard: 'Dashboard',
      data_added: 'Daten hinzugefügt',
      data_saved: 'Gespeicherte Daten',
      datas: 'Daten',
      date: 'Datum',
      date_first_installment: 'Datum der ersten Zahlung',
      ddi: 'Ländercode',
      deb_de_trader: 'Händlerabbuchung',
      deb_game_donate: 'Game-Spende abbuchen',
      deb_saldo: 'Saldo abbuchen',
      deb_saldo_disp: 'Verfügbaren Saldo abbuchen',
      deb_saldo_pendent: 'Ausstehenden Saldo abbuchen',
      deb_val_indev: 'Fehlgeschlagener Betrag abbuchen',
      debit_card_payment: 'Debitkartenzahlung',
      debit_card_payment_return: 'Rückzahlung Debitkartenzahlung',
      debit_card_receipt: 'Debitkarteneinzahlung',
      debt: 'Schuld',
      debt_td: 'Schuld',
      December: 'Dezember',
      decImpRenda: 'Einkommensteuererklärung',
      deleted_document: 'Dokument wurde aus Ihrem Konto gelöscht',
      department: 'Abteilung',
      dependents: 'Abhängige',
      desc: 'Abstammung',
      description: 'Beschreibung',
      details: 'Details',
      dev_econom: 'Wirtschaftliche Entwicklung',
      developer: 'Entwickler',
      directly_indicated: 'Direkt angegeben',
      directly_indicateds: 'Direkt angegebene',
      directs: 'Direkt',
      district: 'Stadtteil',
      divorced: 'Geschieden',
      doa_men_receb: 'Spende der Plattform monatlich erhalten',
      doacao_td: 'Spende',
      doctorate_complete: 'Doktor - Abgeschlossen',
      doctorate_incomplete: 'Doktor - Unvollständig',
      document: 'Dokument',
      document_already_registered: 'Dokument bereits registriert',
      documents: 'Dokumente',
      donate: 'Spenden',
      donor: 'Spender',
      dont_know_zip: 'Weiß nicht, PLZ? Klicken Sie hier',
      dou_fe: 'Ich bestätige, dass die obigen Informationen korrekt sind.',
      downloads: 'Dateien herunterladen',
      drop_images: 'Klicken Sie hier oder ziehen Sie Bilder hierhin',
      due_date: 'Fälligkeit',
      edit: 'Bearbeiten',
      edit_option: 'Option bearbeiten',
      edit_question: 'Frage bearbeiten',
      email: 'E-Mail',
      email_already_registered: 'E-Mail bereits registriert',
      email_and_or_password_wrong: 'E-Mail und/oder Passwort falsch!',
      email_for_login: 'Diese E-Mail wird Ihr Login sein',
      email_or_password_wrong: 'E-Mail oder Passwort falsch',
      employee: 'Mitarbeiter',
      employees: 'Mitarbeiter',
      empty: 'Leer',
      enter_your_new_password: 'Geben Sie Ihr neues Passwort ein',
      entrada: 'Hat einen Einzahlungsbetrag? Wenn JA, welcher Betrag',
      entries_until_today: 'Einträge bis heute',
      error_confirm_password: 'Bestätigung und neues Passwort müssen gleich sein',
      error_email: 'Ungültiges E-Mail-Format',
      error_password: 'Falsches Passwort',
      error_phone: 'Ungültige Telefonnummer',
      estadoInteresse: 'Staat',
      estimate: 'Geschätzt',
      exchange: 'Austausch',
      expired: 'Abgelaufen',
      extorno_td: 'Stornierung',
      extra: 'Extra',
      extract: 'Auszug',
      extracts: 'Auszüge',
      fantasy: 'Fantasie',
      fatherName: 'Vatername',
      February: 'Februar',
      fee: 'Abhebungsgebühr',
      feedback: 'Rückmeldung',
      feminine: 'Weiblich',
      fgts: 'Ihr FGTS-Betrag',
      field_required: 'Pflichtfeld',
      final: 'Ende',
      finance: 'Finanzen',
      financial: 'Finanz',
      financial_payment: 'Finanzzahlung',
      financial_payment_return: 'Rückzahlung Finanzzahlung',
      financial_receipt: 'Finanzeinzahlung',
      financing: 'Finanzierung',
      financings: 'Finanzierungen',
      first_10_pages: 'Erste 10 Seiten',
      fisica: 'Natürliche Person',
      for_year: 'Pro Jahr',
      forget_password: 'Passwort vergessen',
      forgot_password: 'Passwort vergessen',
      form: 'Form',
      form_of_payment: 'Zahlungsart',
      found: 'Gefunden',
      founds: 'Gefunden',
      free: 'Frei',
      friend_indicated: 'Von meinen Freunden empfohlen',
      friend_indicateds: 'Von meinen Freunden empfohlene',
      fuel: 'Kraftstoff',
      full_name: 'Vollständiger Name',
      funcPublico: 'Ist öffentlicher Angestellter',
      fundamental_complete: 'Grundschule - Abgeschlossen',
      fundamental_incomplete: 'Grundschule - Unvollständig',
      ganh_diario: 'Tägliche Gewinne',
      gender: 'Geschlecht',
      grand_total: 'Gesamtsumme',
      group: 'Gruppe',
      has_withdraw_today: 'Heute wurde bereits eine Abhebung beantragt',
      high: 'Hoch',
      home: 'Startseite',
      how_create_binance_apikey: 'So erstellen Sie den API-Schlüssel auf Binance',
      how_it_works: 'Wie es funktioniert',
      how_to_call: 'Wie möchten Sie genannt werden',
      id_td: 'Direkte Empfehlung',
      images: 'Bilder',
      in_construction: 'In Bau',
      in_order: 'In Ordnung',
      in_stock: 'Auf Lager',
      indicant: 'Empfehlender',
      indicated_by: 'Sie werden empfohlen von',
      indicated_not_found: 'Der angegebene Empfehlende wurde nicht gefunden...',
      indicateds: 'Empfohlene',
      indirects: 'Indirekte',
      info: 'Informationen',
      info_contact: 'Kontaktinformationen',
      initial: 'Anfang',
      insert_new: 'Neu einfügen',
      installments: 'Raten',
      intelligence: 'Intelligenz',
      interesse: 'Interessengebiet',
      invalid_contact: 'Ungültiges Kontaktformat',
      invalid_images: 'Ungültige Bilder',
      invalid_token: 'Ungültiges Token',
      invite: 'Einladen',
      invite_friends: 'Lade deine Freunde ein',
      January: 'Januar',
      July: 'Juli',
      June: 'Juni',
      juridica: 'Juristische Person',
      know_exact_result: 'Kennen Sie das genaue Ergebnis?',
      lead: 'Gemeinsam werden wir eine neue Zukunft aufbauen.',
      learn_more: 'Erfahre mehr',
      left: 'Links',
      left_preferential: 'Bevorzugt die linke Seite',
      legalNature: 'Rechtsform',
      level: 'Niveau',
      limite_80:
        'Sie haben {{perc}} Ihres Limits erreicht.\n Verbessern Sie sich und vermeiden Sie die Aussetzung Ihrer Erträge.',
      limite_excedido: 'Überschreitung des Limit-Debits',
      linear: 'Linear',
      link_email: 'Klicken Sie auf den Link, der an die registrierte E-Mail gesendet wurde!',
      link_invite_copy: 'Link zum Teilen erfolgreich kopiert',
      liquid: 'Flüssig',
      list: 'Liste',
      loading: 'Laden',
      location: 'Ort',
      locked: 'Gesperrt',
      login: 'Einloggen',
      logout: 'Ausloggen',
      low: 'Niedrig',
      male: 'Männlich',
      manage_users: 'Benutzer verwalten',
      manager: 'Manager',
      mandala: 'Mandala',
      mandalas: 'Mandalas',
      manufacturer: 'Hersteller',
      March: 'März',
      maritalStatus: 'Familienstand',
      market: 'Markt',
      married: 'Verheiratet',
      master_complete: 'Master - Abgeschlossen',
      master_incomplete: 'Master - Unvollständig',
      max_payment: 'Maximale Zahlung',
      May: 'Mai',
      message: 'Nachricht',
      messageNotSent: 'Nachricht konnte nicht gesendet werden',
      messageSent: 'Ihre Nachricht wurde erfolgreich gesendet',
      middle_complete: 'Mittelschule - Abgeschlossen',
      middle_incomplete: 'Mittelschule - Unvollständig',
      min_amount_error: 'Der Betrag muss mindestens sein: ',
      min_withdraw: 'Mindestabhebungsbetrag',
      missing: 'Fehlen',
      mission: 'Mission',
      mission_vision_values: 'Mission, Vision und Werte',
      model: 'Modell',
      monitors: 'Monitore',
      monthly: 'Monatlich',
      motherName: 'Name der Mutter',
      movements: 'Bewegungen',
      msg_renovacao: 'Sie haben Ihr Gewinnlimit erreicht. Erneuern Sie Ihr Paket.',
      my_account: 'Mein Konto',
      my_wallet: 'Mein Portemonnaie',
      mystock: 'Mein Bestand',
      name: 'Name',
      nascMaisVelhoRenda2: 'Geburtsdatum des Ältesten',
      nationality: 'Nationalität (Land, in dem Sie geboren wurden)',
      naturalness: 'Geburtsort (Stadt, in der Sie geboren wurden)',
      nature: 'Natur',
      network: 'Netzwerk',
      new: 'Neu',
      new_call: 'Neuer Anruf',
      new_financing: 'Neue Finanzierung',
      new_order: 'Neue Bestellung',
      new_password: 'Neues Passwort',
      new_password_security: 'Neues Sicherheitspasswort',
      new_seller: 'Neuer Verkäufer',
      new_wallet: 'Neues Portemonnaie',
      news: 'Nachrichten',
      next: 'Nächstes',
      nick: 'Benutzer (Anmeldung)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS bereits registriert',
      no: 'Nein',
      no_approve: 'Noch nicht genehmigen',
      no_delete: 'Nicht löschen',
      no_literate: 'Nicht alphabetisiert',
      no_repeat: 'Die Daten können nicht wiederholt werden',
      no_results_found: 'Keine Ergebnisse gefunden',
      no_services_or_products_added: 'Keine Dienstleistungen oder Produkte hinzugefügt',
      no_settings: 'Gehe zu Einstellungen und fülle deine Daten aus.',
      none_friends_indicated: 'Ich habe noch keine Freunde empfohlen',
      not_authorized: 'Nicht autorisiert',
      not_found: 'Nicht gefunden',
      not_login: 'Anmeldung nicht möglich, überprüfen Sie die Daten und versuchen Sie es erneut!',
      not_orders: 'Keine Zahlungsaufträge',
      not_permission: 'Sie haben keine Berechtigung, auf diese Seite zuzugreifen',
      not_registered: 'Noch nicht registriert? Klicken Sie hier und registrieren Sie sich',
      not_results: 'Kein Eintrag gefunden',
      not_self_register: 'Registrierung nicht möglich',
      not_today_entry: 'Heute gab es noch keine Einträge',
      notfound_ad: 'Keine Anzeige gefunden',
      November: 'November',
      number: 'Nummer',
      obs: 'Bemerkungen',
      obs_pay:
        'Wenn Ihr Broker Gebühren vom gesendeten Betrag abzieht, müssen Sie die Werte dieser Gebühren manuell hinzufügen.',
      October: 'Oktober',
      of: 'von',
      on_total: 'Vom Gesamtbetrag',
      open: 'Offen',
      opening: 'Eröffnung',
      operation: 'Betrieb',
      ordenation: 'Ordnung',
      order: 'Bestellung',
      order_book: 'Auftragsbuch',
      order_not_alowed_to_pay: 'Bestellung nicht autorisiert, mit Guthaben zu zahlen',
      order_not_found: 'Bestellung nicht gefunden',
      order_payed: 'Bestellung #{{id}} erfolgreich bezahlt',
      orders: 'Bestellungen',
      other: 'Andere',
      other_services_or_products: 'Andere Dienstleistungen oder Produkte',
      paid: 'Bezahlt',
      paid_date: 'Zahlungsdatum',
      partial: 'Teilweise bezahlt',
      passport: 'Reisepass',
      password: 'Passwort',
      password_security: 'Sicherheitspasswort',
      pay: 'Bezahlen',
      pay_exact: 'Zahlen Sie den Betrag in {{coin}}',
      pay_generate: 'Zahlung generieren',
      pay_selected: 'Bezahlen ausgewählt',
      pay_value_in: 'Zahlen Sie den Betrag ein',
      pay_with_balance: 'Bezahlen Sie mit Guthaben',
      payable: 'Zahlen',
      payer: 'Zahler',
      payment: 'Zahlung',
      ped_retirada: 'Auszahlungsantrag',
      ped_retirada_gan_diario: 'Antrag auf Auszahlung des Tageseinkommens',
      pendent: 'Ausstehend',
      pendent_order: 'Sie haben ausstehende Bestellungen',
      people: 'Menschen',
      person: 'Person',
      person_found_in_other_prefecture: 'Person, die bereits in einer anderen Gemeinde gemeldet ist',
      personal: 'Jungs',
      personal_addresses: 'Adressen',
      personal_contacts: 'Kontakte',
      personal_data: 'persönliche Daten',
      personal_infos: 'Information',
      personal_pcva: 'Programm des Grünen und Gelben Hauses',
      phone: 'Telefonnummer',
      photo: 'Foto',
      pix: 'PIX',
      pix_payment: 'Zahlung in PIX',
      pix_payment_return: 'Rückerstattung der Zahlung in PIX',
      pix_receipt: 'Empfangen in PIX',
      pl_carreira: 'Karriereweg',
      plan: 'Wohnung',
      plates: 'Platten',
      please_wait: 'Bitte warten',
      plus: 'Plus',
      point: 'Punkt',
      points: 'Punkte',
      portfolio: 'Portfolio',
      pos_complete: 'Lato sensu – Vollständig',
      pos_incomplete: 'Lato sensu – Unvollständig',
      prefecture: 'Rathaus',
      prefecture_addresses: 'Adressen',
      prefecture_contacts: 'Kontakte',
      prefecture_data: 'Rathausdaten',
      prefecture_infos: 'Information',
      prefectures: 'Rathäuser',
      prev: 'Vorherige',
      price: 'Preis',
      print: 'Ausdrucken',
      privacy_policies: 'Datenschutzrichtlinien',
      private_works: 'Private Werke',
      profession: 'Beruf',
      profile: 'Profil',
      program: 'Programm',
      programs: 'Software',
      project: 'Projekt',
      projects: 'Projekte',
      public_works: 'Öffentliche Bauarbeiten',
      purchase: 'Kaufen',
      qualify_binary: 'Dual-Point-Qualifikation',
      qualquer: 'Beliebig',
      quantity: 'Menge',
      quotation: 'Preis',
      read: 'Lesen',
      receivable: 'Bekommen',
      recipient: 'Empfänger',
      recommendations: 'Empfehlungen',
      redefine_password: 'Passwort neu definieren',
      register: 'Aufzeichnen',
      register_people: 'Registrierung von Personen',
      register_wallet: 'Registrieren Sie ein Wallet, um Abhebungen vorzunehmen',
      registers: 'Aufzeichnungen',
      registers_users: 'Benutzerregistrierungen',
      reject: 'Ablehnen',
      remaining: 'Übrig',
      remove: 'Zu entfernen',
      rendaIndividual: 'Individuelles Bruttoeinkommen',
      renew: 'Erneuern',
      renew_mandala: 'Mandala-Erneuerung',
      reply: 'Antworten',
      reports: 'Berichte',
      required_data_not_sent: 'Erforderliche Daten wurden nicht gesendet',
      resend_email: 'Sende die E-Mail erneut',
      residential: 'Wohnen',
      responded: 'Antwortete',
      restricao: 'Gibt es Einschränkungen bezüglich Ihres Namens? (SPC/Serasa)',
      result: 'Ergebnis',
      results: 'Ergebnisse',
      resume_data: 'Datenzusammenfassung',
      revoked: 'Aufgehoben',
      rg: 'Allgemeine Registrierung (Identität)',
      rgAgency: 'RG-Emittent',
      rgDate: 'RG-Ausstellungsdatum',
      rgUf: 'Bundesstaat RG',
      right: 'Rechts',
      right_preferential: 'Bevorzuge die rechte Seite',
      roi: 'Tägliches Einkommen',
      sale: 'Verkauf',
      saq_duplicado: 'Doppelte Auszahlung',
      save: 'Speichern',
      schooling: 'Ausbildung',
      search: 'Suchen',
      secretary: 'Sekretär',
      security_password: 'Sicherheitspasswort',
      see: 'Um zu sehen',
      see_advents: 'Sehen Sie sich die Anzeigen an',
      see_call: 'Anruf ansehen',
      see_details: 'siehe Einzelheiten',
      see_details_wallet: 'Wallet-Details anzeigen',
      see_financing: 'Finanzierung ansehen',
      see_order: 'Bestellung ansehen',
      see_user: 'Benutzer anzeigen',
      select_vehicle: 'Fahrzeug auswählen',
      self_register: 'Registrieren',
      sell_volume: 'Verkaufsvolumen',
      seller: 'Verkäufer',
      send_call: 'Anruf senden',
      send_to: 'Senden an',
      September: 'September',
      settings: 'Einstellungen',
      share_your_link: 'Teilen Sie Ihren Link',
      show: 'Anzeigen bis',
      show_from: 'Anzeige ab',
      side: 'Seite',
      sign_in_to_start_your_session: 'Melden Sie sich an, um Ihre Sitzung zu starten',
      sign_up: 'Registrieren',
      single: 'Einzel',
      sold: 'Verkauft',
      state: 'Zustand',
      status: 'Status',
      strategies: 'Strategien',
      street: 'Straße',
      subject: 'Thema',
      submit: 'Senden',
      submit_placeholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
      subscribe: 'Melden Sie sich an, um unsere Neuigkeiten zu erhalten',
      subtitle: 'Untertitel',
      success_update_user: 'Benutzer erfolgreich aktualisiert!',
      success_wallet: 'Portfolio erfolgreich aktualisiert!',
      suggestions: 'Vorschläge',
      superior_complete: 'Absolvent',
      superior_incomplete: 'Unvollständig höher',
      supervisor: 'Aufsicht',
      support: 'Unterstützung',
      support_materials: 'Unterstützende Materialien',
      suspended: 'Ausgesetzt',
      symbol: 'Symbol',
      symbols: 'Symbole',
      system: 'System',
      target: 'Ziel',
      telegram_chat_id: 'Telegram-Chat-ID',
      tempoCarteira: 'Seit wann sind Sie in Ihrem Portfolio eingetragen?',
      terms: 'Ich akzeptiere die Nutzungsbedingungen',
      text_hint_binance:
        'Geben Sie beim Erstellen des API-Schlüssels auf Binance die IP in den Zugriffsbeschränkungen ein:\n172.105.78.139\nDann wählen Sie die Optionen aus:\n EUROPÄISCHE OPTIONEN AKTIVIEREN\n SPOT- & MARGIN-HANDEL AKTIVIEREN',
      this_value_in: 'Dieser Wert in',
      ticker_health: 'TickerHealth',
      title: 'Titel',
      to: 'Bis',
      to_pay: 'Ausschalten',
      to_receive: 'Forderungen',
      today_entry: 'Heutiger Eintrag',
      token_not_found_or_already_used: 'Token nicht gefunden oder bereits verwendet',
      total: 'Gesamt',
      total_entry: 'Gesamteintrag',
      traderbot: 'Bot-Händler',
      transf_credito: 'Überweisungskredit',
      transf_debito: 'Lastschrift überweisen',
      transfer_payment: 'Zahlung per Überweisung',
      transfer_payment_return: 'Rückerstattung der Zahlung per Überweisung',
      transfer_receipt: 'Quittung bei Überweisung',
      transparency: 'Transparenz',
      tx_adm_cred: 'Steuern verwalten',
      tx_adm_deb: 'Steuern verwalten',
      tx_retirada: 'Abhebegebühr',
      tx_retirada_gan_diario: 'Tägliche Auszahlungsgebühr',
      type: 'Typ',
      update: 'Aktualisieren',
      update_wallet: 'Wallet aktualisieren',
      upgrade: 'Verbesserung',
      used: 'Gebraucht',
      user: 'Benutzer',
      user_active: 'Aktiver Nutzer',
      user_already_exists: 'Benutzer existiert bereits!',
      user_and_or_password_wrong: 'Falscher Nutzername und / oder Passwort!',
      user_inactive: 'Inaktiver Benutzer',
      user_not_found: 'Benutzer nicht gefunden',
      user_not_logged: 'Benutzer nicht angemeldet',
      user_or_password_wrong: 'falscher Benutzername oder falsches Passwort',
      users: 'Benutzer',
      users_list: 'Benutzerliste',
      valid_images: 'Gültige Bilder',
      valorRenda2: 'Wie hoch ist das Zweiteinkommen?',
      value: 'Wert',
      value_donate_on: 'Spendenwert in',
      value_financed: 'Finanzierter Betrag',
      value_installment: 'Ratenwert',
      values: 'Werte',
      vehicle: 'Fahrzeug',
      vehicles: 'Fahrzeuge',
      view_order: 'Bestellung anzeigen',
      vision: 'Vision',
      visitor: 'Besucher',
      voucher: 'Gutschein',
      wait_confirm: 'Warte auf Bestätigung',
      waiting: 'Warten',
      waiting_email: 'Die Registrierung war erfolgreich, jetzt müssen Sie nur noch Ihre E-Mail bestätigen.',
      waiting_payment: 'Warten auf Zahlung',
      wallet: 'Portfolio',
      wallet_generate: 'Portfolio erstellen',
      wallets: 'Geldbörsen',
      want_donate: 'Ich möchte spenden',
      want_now_more_about: 'Ich möchte mehr darüber erfahren',
      warning: 'AUFMERKSAMKEIT',
      we_are: 'Sind',
      webhooks: 'Webhooks',
      welcome: 'Willkommen',
      who_we_are: 'Wer wir sind',
      who_we_are_subtitle: 'Eine kurze Beschreibung des',
      widower: 'Witwer',
      will_expire_in: 'läuft ab',
      with: 'mit',
      withdraw: 'Rückzug',
      withdraw_sended_email:
        'Auszahlung beantragt, überprüfen Sie Ihre E-Mails und klicken Sie zur Autorisierung auf den Link!',
      withdrawal: 'Abhebungen',
      withdrawals: 'Abhebungen',
      year: 'Jahr',
      years: 'Jahre',
      yes: 'Ja',
      you_have: 'Du hast',
      your_country: 'Dein Land',
      your_dashboard: 'Dies ist Ihre Systemsteuerung.',
      your_link: 'Affiliate-Link',
      zipcode: 'PLZ',
      withdrawal_sended: 'Auszahlung gesendet',
      withdrawal_pending:
        'Auszahlung bereits beantragt! \nBitte warten Sie auf die Zahlung, um eine weitere Auszahlung vorzunehmen.',
      limite_80_top:
        'Sie haben {{perc}} Ihr Limit erreicht.\n Erwarten Sie Ihre Verlängerung und vermeiden Sie eine Aussetzung Ihres Einkommens.',
      game: 'Spiel',
      games: 'Spiele',
      winning_numbers: 'Gezogene Zahlen',
      your_numbers: 'Ihre Zahlen',
      add_numbers: 'Zahlen hinzufügen',
      next_draw: 'Nächste Ziehung',
      can_bet_until: 'Sie können bis zu wetten',
      your_bets: 'Ihre Wetten',
      add_bet: 'Wette hinzufügen',
      bet: 'Wette',
      bets: 'Wetten',
      numbers: 'Zahlen',
      special_numbers: 'Sondernummern',
      resume: 'Zusammenfassung',
      choosed_numbers: 'Ausgewählte Zahlen',
      choosed_special_numbers: 'Spezielle Nummern ausgewählt',
      bets_finished: 'Abgerechnete Wetten',
      prize_draw: 'Gewinnspiel',
      balance_network: 'Netzwerkbalance',
      telegram: 'Telegramm',
      logs: 'Protokolle',
      release_withdrawals: 'Auszahlungen freigeben',
      bot_active: 'Bot freigeben',
      available: 'Verfügbar',
      sunday: 'Sonntag',
      monday: 'Zweite',
      tuesday: 'Dritte',
      wednesday: 'Vierte',
      thursday: 'Fünfte',
      friday: 'Freitag',
      saturday: 'Samstag',
      title_binary: 'Legen Sie für jeden Tag Dual-Point-Prozentsätze fest',
      ok_perc_change: 'Prozentsätze wurden erfolgreich geändert',
      cred_deb_title: 'Kredit- oder Sollsaldo',
      send: 'Senden',
      voucher_pendent: 'Gutschein ausstehend',
      leader_pendent: 'Ausstehender Anführer',
      voucher_approved: 'Gutschein genehmigt',
      leader_approved: 'Anerkannter Anführer',
      no_binary_at_today: 'An diesem Tag ist kein Dual Point registriert',
      generate_file: 'Datei generieren',
      withdrawals_file_created: 'Auszahlungsdatei erstellt',
      withdrawals_sended: 'Auszahlungsanfragen gesendet',
      see_generated_files: 'Generierte Dateien anzeigen',
      files: 'Dateien',
      file: 'Datei',
      verify: 'Überprüfen',
      rescue: 'Rettung',
      awarded: 'Ausgezeichnet',
      rescued: 'Gerettet',
      repeat: 'Wiederholen',
      draw_date: 'Ziehungsdatum',
      bet_date: 'Wettdatum',
      drawn_numbers: 'Gezogene Zahlen',
      awardeds_numbers: 'Preisnummern',
      no_award: 'Kein Preis',
      you_hit_number: 'Sie haben {{qty}} Nummer richtig angegeben',
      you_hit_numbers: 'Sie haben {{qty}} Zahlen richtig angegeben',
      lottery: 'Lotterie',
      lotteries: 'Lotterien',
      balls: 'Bälle',
      special_balls: 'Sonderangebote',
      levy: 'Sammlung',
      major_awarded: 'mehr Bälle',
      hits: 'Treffer',
      balance_game: 'Balance für Spiele',
      your_balance: 'Dein Kontostand',
      your_balance_total: 'Ihr Gesamtguthaben',
      game_package: '{{qty}} Zahlen (kann {{prize}} Mal gewinnen)',
      choose_package: 'Wählen Sie das Paket',
      error_bet: 'Wette kann nicht gesendet werden',
      do_complete: 'Fertigstellen',
      success_bet: 'Wette gesendet',
      you_bet_number: 'Sie setzen {{qty}} Zahlen',
      winner_msg: 'und gewann {{prize}} Mal',
      prize: 'Vergeben',
      balance_not_enough: 'Unzureichende Mittel',
      addbalance: 'Fügen Sie den Spielen Balance hinzu',
      convertbalance: 'Salden umrechnen',
      convertbalance_title: 'Wandeln Sie das verfügbare Guthaben in ein Spielguthaben um',
      can_award: 'Du kannst gewinnen',
      emission: 'Emission',
      validation: 'Validierung',
      participant: 'Teilnehmer',
      concourse: 'Wettbewerb',
      draw_hours: 'Ziehzeit',
      qty_dezenas: 'Anzahl der Zehner',
      bet_amount: 'Einsatzbetrag',
      share: 'Teilen',
      shared: 'Bereit zum Teilen',
      conversion: 'Umrechnung zwischen Salden',
      better: 'Wettberater',
      lottery_consultant: 'zugehöriger Berater',
      consultant_indication: 'Beratertermin',
      betting_commission: 'Aufladeprovision für das Spielguthaben',
      be_a_consultant: 'Werden Sie Lotterieberater und verdienen Sie viel mehr',
      msg_be_a_consultant:
        'Die Lotter Global Profit Division.\nEmpfehlungsbonus (auf Paketverkäufe)\nDual-Punkte-Bonus\nund vieles mehr.',
      auto_renovations: 'Automatische Verlängerungen',
      auto_renove_binary: 'Dual-Point-Selbsterneuerung',
      auto_renove_quotas: 'Selbsterneuerung der Ziele (220 %)',
      auto_renove_anual: 'Jährliche Selbsterneuerung',
      off: 'Aus',
      on: 'In Verbindung gebracht',
      change_success: 'Erfolgreich geändert',
      anual: 'Jährlich',
      only_monday: 'Auszahlungen sind jeden Montag möglich',
      transferency: 'Überweisen',
      max_amount: 'Höchster Wert',
      info_better:
        '- Der Wettberater erhält den Gesamtwert des Pakets in Form von Wettguthaben.\n\n- Es ist erlaubt, Upgrades für die Wettpakete vorzunehmen, indem er nur die Differenz bezahlt und die Differenz in neuen Wettguthaben erhält.\n\n- Upgrades sind auch in ASSOCIATE PACKS möglich, indem Sie ein NEUES Paket in voller Höhe bezahlen und von dort erhalten Sie eine GEWINNBETEILIGUNG.\n\n- Verlängerungen und Downgrades können mit Apostador Packs oder Associate Packs durchgeführt werden, sofern verfügbar.',
      info_consultant:
        '- Der Associate-Berater gibt die Wettscheine aus seinem Paket an Lotter Pro weiter, um mit seinem Kundenstamm zu verhandeln, und erhält an Werktagen bis zu 1 % auf das ausgewählte ASSOZIIERTE PACK als Gewinnbeteiligung.\n\n- Upgrades sind zulässig zu den ZUGEHÖRIGEN PACKS, indem NUR DIE DIFFERENZ bezahlt wird, und die neue Teilnahmeobergrenze von 220 % wird zum neuen Wert des ZUGEHÖRIGEN PACKS, abzüglich dessen, was bis dahin bereits von allen Boni erhalten wurde.\n\n- Upgrades sind in BESSEREN PACKS von zulässig Wenn Sie ein NEUES Paket im vollen Wert bezahlen, erhalten Sie den Gesamtwert des Pakets in Form von Wettguthaben.\n\n- Verlängerungen und Herabstufungen können mit Wettpaketen oder Partnerpaketen vorgenommen werden.\n\n- Pakete, Upgrades und Verlängerungen über 500 $ müssen ausschließlich in ZUGEHÖRIGEN PAKETEN erfolgen.',
      confirm: 'Bestätigen',
      send_new_token:
        'Sobald die Bestätigung an Ihre E-Mail gesendet wurde, klicken Sie zur Bestätigung auf Ihre E-Mail',
      email_resended: 'Bestätigung an Ihre E-Mail gesendet. Klicken Sie zur Bestätigung auf Ihre E-Mail',
      expired_send_new_token: 'Neue Bestätigung an Ihre E-Mail gesendet. Klicken Sie zur Bestätigung auf Ihre E-Mail',
      become_a_consultant: 'Upgraden Sie und erhalten Sie viel mehr',
      register_ok: 'Erfolgreich registriert',
      ticket_number: 'Zahlungskartennr',
      mensal_activation: 'Jährliche Aktivierung',
      conversion_to_bet: 'Umwandlung in Wette',
      conversion_balance_games: 'Umrechnung des Guthabens in Spielguthaben',
      conversion_balance_lotterprocoin: 'Guthabenumrechnung in Aktivierungen',
      conversion_lotterprocoin_games: 'Konvertierung von Aktivierungen in Spielguthaben',
      conta_corrente: 'Aktuelles Konto',
      balance_games: 'Spielbalance',
      add_balance_games: 'Fügen Sie den Spielen Balance hinzu',
      transf_debit_lotterProCoin: 'Aktivierungen-Guthabentransfer',
      deb_saldo_lotterProCoin: 'Aktivierungen-Guthabenabbuchung',
      transf_credit_lotterProCoin: 'Guthaben auf Aktivierungen übertragen',
      live_now: 'Jetzt live',
      offline: 'Offline',
      tutorials: 'Tutorials',
      tutorial_created: 'Tutorial erstellt',
      english: 'Englisch',
      spanish: 'Spanisch',
      portuguese: 'Portugiesisch',
      select_language: 'Sprache auswählen',
      msg_renove_binary:
        'Sie haben bereits einen weiteren Monat der letzten monatlichen Aktivierung abgeschlossen und haben die automatische monatliche Aktivierung nicht aktiviert.\nAus diesem Grund ist Ihr Dual Point inaktiv.\nZahlen Sie eine Aufforderung zum Hinzufügen eines Spielguthabens in Höhe von 25,00 $, um die monatliche Aktivierung durchzuführen .',
      choose_side_preferential: 'Wählen Sie die bevorzugte Seite (derzeit: {{side}})',
      count: 'Anzahl',
      deb_balance_games: 'Guthabenabbuchung in Spielguthaben',
      cred_saldo_lotterProCoin: 'Guthabenverlauf in Aktivierungen',
      historics: 'Historik',
      balance_lotterProCoin: 'Aktivierungsbilanz',
      msg_binary_tree_1: 'Drücken Sie den gewünschten Benutzer 1 Sekunde lang, um die Informationen anzuzeigen',
      msg_binary_tree_2:
        'Klicken Sie einmal auf den gewünschten Benutzer, um die nächsten Benutzer in der Binärdatei zu sehen.',
      change_security_password: 'Passwort für Sicherheit aendern',
      info_pass_security: 'Das Sicherheitspasswort wird zur Durchführung von Finanztransaktionen verwendet',
      redefine_security_password: 'Passwort für Sicherheit neu definieren',
      invalid_wallet: 'Ungültige Wallet',
      valid_wallet: 'Gültige Wallet',
      cpf_account:
        'Es ist notwendig, dem CPF den Bankkontoinhaber mitzuteilen, der die Zahlung vornehmen wird.\n\nSie werden dann zur Registrierungsseite des Payment Gateway weitergeleitet.\n\nNachdem die Registrierung abgeschlossen ist, kehren Sie zu dieser LOTTER PRO-Seite zurück und klicken Sie hinein Generieren Sie QR-CODE-PIX.',
      confirm_email: 'Bestätige deine E-Mail',
      error_confirm_email: 'Die angegebene E-Mail-Adresse stimmt nicht überein',
      info_choose_package:
        'Kaufen Sie eines der verfügbaren Pakete, um Punkte zu aktivieren und zu sammeln, andernfalls wird es innerhalb von 15 Tagen gelöscht',
      title_info_register: 'LESEN SIE SORGFÄLTIG DURCH, BEVOR SIE DIE REGISTRIERUNG BESTÄTIGEN',
      info_register:
        '- Punkte sammeln sich erst nach der Aktivierung von mindestens 10 $ an. \n- Dual Points werden täglich um 00:00 Uhr London ausgezahlt. \n- Die Dual Point-Aktivierung erfolgt für 2 Konten zu je 25 $ auf jeder Seite. \n- Bestätigen Sie im Büro die Überlaufseite Ihres Dual Points. \n n- Bestätigen Sie vor der Registrierung die oben genannten Informationen',
      no_binaries: 'Das Netzwerk dieses Benutzers kann nicht angezeigt werden',
      register_gateway: 'Klicken Sie hier, um sich für das Zahlungsgateway zu registrieren',
      packs_better: 'Wettberater-Mitgliedschaftspakete (Guthaben für Wetten erhalten)',
      packs_consultant: 'Mitgliedschaftspakete für Associate Consultants (Erhält eine Gewinnbeteiligung)',
      read_choose: 'Lesen Sie es sorgfältig durch und wählen Sie das für Sie am besten geeignete Paket aus',
      footer_choose: 'Alle Pakete beinhalten die Teilnahme an den Vorteilen des Lotter Pro-Partnerprogramms',
      day: 'Day',
      days: 'Days',
      olds: 'Die Alten zuerst',
      recents: 'Aktuelles zuerst',
      accept_title: 'Um Zahlungen über PIX zu tätigen, müssen die nachstehenden Bedingungen akzeptiert werden',
      acept1:
        'Mir ist bekannt, dass SmartPay Serviços Digitais Ltda – CNPJ: 32.546.471/0001-96 ein in Brasilien registriertes Unternehmen ist und alle Vorschriften der Zentralbank und des Bundesfinanzamtes einhält, wo alle USDT-Kauf- oder Verkaufsvorgänge durchgeführt werden Ihnen liegt eine Rechnung bei und sie werden gemäß der Normativen Anweisung 1888/19 an das Bundesfinanzamt Brasiliens gemeldet.',
      acept2:
        'Mir ist bekannt, dass SmartPay Serviços Digitais Ltda – CNPJ: 32.546.471/0001-96 keine Anlagedienstleistungen oder -produkte anbietet und dass sich die Tätigkeit auf den Kauf oder Verkauf von USDT beschränkt.',
      acept3:
        'Ich bin damit einverstanden, meine persönlichen Daten an SmartPay Serviços Digitais Ltda – CNPJ: 32.546.471/0001-96 weiterzugeben, damit das Unternehmen den USDT-Polygon-Kauf- oder Verkaufsservice durchführen kann.',
      acept4:
        'Mir ist bewusst und ich akzeptiere, dass ich USDT über SmartPay Serviços Digitais Ltda – CNPJ: 32.546.471/0001-96 kaufe, und ich genehmige den Versand des Kryptoaktivs an die Wallet-Adresse 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc, die von: \nSMART PAY INTERMEDIACOES | gehalten wird 42.913.912/0001-02 \nAdresse: Av.: Paulista, N.º 171 – Ergänzung: PAVMTO4 und 3 VG – Bela Vista – 01311.904 – São Paulo - SP \nE-Mail: smartpayintermediacoes@gmail.com',
      acept5:
        'Ich befreie SmartPay Serviços Digitais Ltda – CNPJ: 32.546.471/0001-96 von jeglicher kommerziellen Verantwortung für die auf dieser Website angebotenen Produkte oder Dienstleistungen und/oder das Unternehmen, das die USDT-Adresse besitzt.',
      acept6:
        'Ich stimme zu, dass ich durch die Zahlung des unten generierten Pix-QRCODEs den Bedingungen für den Kauf oder Verkauf von USDT von SmartPay Serviços Digitais Ltda – CNPJ: 32.546.471/0001-96 zustimme und dass der Vorgang auf der Blockchain von Polygon registriert wird, die das sendet Operations-ID der brasilianischen Zentralbank (End-To-End) im Nachrichtenfeld an die Adresse 0x02e41265a64377c8efdb7C4f76120Eaa827c0fDc',
      aceptAlert:
        'Wenn Sie auf „Bestätigen“ klicken, wird ein Pix-Schlüssel mit einem zufälligen Wert zwischen 0,10 R$ und 0,99 R$ generiert.\n\nDiese Zahlung muss erfolgen, um Ihre Registrierung bei SmartPay Serviços Digitais Ltda zu bestätigen.',
      validate: 'Bestätigen (Diese Gebühr wird nur einmal erhoben).',
      modal_acpet_title: 'Zum ersten Mal mit PIX bei LOTTER PRO bezahlen?',
      only_time:
        'DIESER VORGANG WIRD NUR EINMAL DURCHGEFÜHRT.\nAndere Zahlungen über PIX werden automatisch validiert.',
      terms_smartpay:
        'Ich stimme den Allgemeinen Geschäftsbedingungen von Smartpay Serviços Digitais Ltda – CNPJ: 32.546.471/0001-96 zu',
      success_activation: 'Sie wurden erfolgreich validiert und können nun Zahlungen über PIX durchführen',
      warning1: 'ES IST KEINE ZAHLUNG FÜR IHRE BESTELLUNG',
      warning2:
        'Dies ist der QR-Code, mit dem Sie Ihre Registrierung bei SmartPay Serviços Digitais Ltda validieren und Zahlungen über PIX durchführen können. \n ES IST KEINE ZAHLUNG FÜR IHRE BESTELLUNG.\n Sobald Ihre Zahlung bestätigt wurde, kehren Sie zu dieser LOTTER PRO-Seite zurück und klicken Sie auf „QR-CODE-PIX generieren“, um den QR-Code für die Zahlung per PIX Ihrer BESTELLUNG zu generieren.',
      only_brazil: 'Nur in Brasilien',
      changeUserGroup: 'Kategorie ändern',
      alert_change_category:
        'Wenn Sie die Kategorie ändern, verliert Ihr aktuelles Paket seine Gültigkeit.\nSie erhalten die Gewinne aus dem aktuellen Paket NICHT MEHR.\nSie erhalten jetzt die Gewinne aus Ihrem neuen Paket.\nIhr Gewinnlimit wird auf 220 % angepasst Ihres neuen Pakets.\nIhre Guthaben ändern sich nicht.\n\nSind Sie sicher, dass Sie die Kategorie ändern möchten?',
      exceeded: 'Ueberschreitung',
      vinculate_whatsapp: 'WhatsApp verknüpfen',
      verification_code: 'Bestätigungscode',
      verification_msg: 'Geben Sie den an Ihre WhatsApp gesendeten Bestätigungscode ein',
      balance_awards: 'Preisguthaben',
      addprocoin: 'Aktivierungsguthaben hinzufügen',
      withdrawal_awards: 'Gewinnauszahlung',
      conversion_awards_games: 'Umwandlung von PREISBALANCE in SPIELBALANCE',
      last_draws: 'Neueste Ziehungen',
      today: 'Heute',
      yesterday: 'Gestern',
      tomorrow: 'Morgen',
      others: 'Andere',
      draws: 'Ziehungen',
      learn_more_text:
        'Wählen Sie zwischen {{choose}} Dutzenden \nund gewinnen Sie das {{rangeWin.min}}- bis {{rangeWin.max}}-fache des Einsatzbetrags, der {{win}} Dutzenden gezogener Gewinne entspricht.',
      or: '  oder',
      good_luck: 'Viel Glück!',
      add_balance_awards: 'Preisguthaben hinzufügen',
      deb_balance_awards: 'Abbuchung des Gewinnguthabens',
      key_pix: 'PIX-Schlüssel',
      choose_game: 'Wählen Sie ein Spiel',
      do_bet: 'Wetten',
      response_bets_lot: 'Antwort auf Lot-Wetten',
      lottery_not_found: 'Lotterie nicht gefunden',
      lottery_expired: 'Abgelaufene Lotterie',
      amount_invalid: 'Ungültiger Wert',
      balance_game_not_enough: 'Unzureichende Mittel',
      game_package_not_found: 'Spielpaket nicht gefunden',
      please_choose_other_tens: 'Bitte wählen Sie Dutzende weitere aus',
      continue_beting: 'Weiter wetten',
      balls_invalid: 'Ungültige Zahlen',
      max_bet: 'Maximaler Einsatzwert, abhängig von der Anzahl der Zehner',
      copy_paste: 'Mehrere Spiele',
      amount_copy_paste: 'Wert jeder Wette',
      list_copy_paste: 'Liste der gewünschten Wetten',
      total_valid: 'Insgesamt gültig',
      erase_my_account: 'Konto löschen',
      erase_alert_caution:
        'Dieser Vorgang kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie Ihr Konto löschen möchten? \nSchreiben Sie zur Bestätigung "lösche mein Konto".',
      success_delete_account: 'Konto gelöscht',
      age_major:
        'Bevor wir fortfahren, müssen wir bestätigen, dass Sie mindestens 18 Jahre alt sind. Gemäß den Gesetzen und Vorschriften ist Glücksspiel für Personen unter 18 Jahren verboten.\n\nBitte klicken Sie auf das Kontrollkästchen unten, um zu bestätigen, dass Sie mindestens 18 Jahre alt sind.',
      age_major_button: 'Ich bestätige, dass ich mindestens 18 Jahre alt bin',
      title_age_major: 'Altersbestätigung',
      about_us:
        'Wir bei {{company}} glauben, dass jeder die Chance haben sollte, große Träume zu haben. Deshalb bieten wir Ihnen die Möglichkeit, an den größten Lotterien rund um den Globus teilzunehmen, egal wo Sie sich befinden. \n\nWir sind stolz darauf, in Übereinstimmung mit den höchsten ethischen und rechtlichen Standards zu arbeiten.\nUnser Engagement für Transparenz, Sicherheit und Verantwortlichkeit gewährleistet das Vertrauen, ein faires und unterhaltsames Spielerlebnis zu bieten.',
      site_map: 'Seitenverzeichnis',
      kyc: 'Identitätsprüfung (KYC)',
      kyc_welcome: 'Um Ihre Identität zu überprüfen, laden Sie bitte die folgenden Bilder hoch:',
      kyc_msg:
        'Wenn die Identitätsüberprüfung genehmigt ist, erhalten Sie eine Bestätigungs-E-Mail und alle {{company}}-Funktionen werden freigegeben.',
      kyc_error: 'Beim Hochladen der Bilder ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',
      driving: 'Führerschein',
      identity: 'Identität',
      front: 'Vorderseite',
      front_msg: 'Foto der Vorderseite des Dokuments',
      back_doc: 'Vers',
      back_doc_msg: 'Foto der Rückseite des Dokuments',
      selfie: 'Selfie',
      selfie_msg: 'Selfie mit Dokument',
      kyc_pending: 'Ausstehende Bestätigung',
      kyc_rejected: 'Verifizierung abgelehnt',
      kyc_approved: 'Verifizierung bestanden',
      two_fa: 'Zwei-Faktor-Authentifizierung (2FA)',
      two_fa_msg:
        'Um sicherzustellen, dass Ihr Zugriff sicherer ist, aktivieren Sie bitte die Zwei-Faktor-Authentifizierung (2FA).',
      two_fa_step1: 'Lesen Sie den QR-Code mit Ihrer Authentifizierungs-App (Google Authenticator, Authy usw.).',
      two_fa_step2: 'Geben Sie den Bestätigungscode ein, den Ihnen die Authentifizierungs-App gesendet hat.',
      two_fa_activate: 'Aktivieren Sie die Zwei-Faktor-Authentifizierung (2FA)',
      two_fa_deactivate: 'Deaktivieren Sie die Zwei-Faktor-Authentifizierung (2FA)',
      two_fa_activated:
        'Ihre Zwei-Faktor-Authentifizierung (2FA) ist aktiviert, \n Sie verfügen jetzt über eine zusätzliche Sicherheitsebene.',
      two_fa_verify: 'Überprüfen Sie die Zwei-Faktor-Authentifizierung (2FA)',
      draw_due: 'Limit für Wetten',
      close_game: 'Knappes Spiel',
      draw_due_greater_than_draw_date: 'Das Wettlimit muss mindestens {{timeLimit}} Minuten vor der Ziehung liegen',
      draw_date_less_than_now:
        'Das Ziehungsdatum muss mindestens {{timeLimit}} Minuten nach dem aktuellen Datum liegen',
      simulation: 'Simulation',
      valuePay: 'Zu zahlender Betrag',
      quotas_paid_success: 'Zahlung erfolgreich',
      paids_today: 'Heute getätigte Zahlungen',
      no_paids_today: 'Heute wurden keine Zahlungen getätigt',
      perc: 'Prozentsatz',
      define_percent: 'Prozentsatz festlegen',
      success_update: 'Aktualisierung erfolgreich',
      define_percent_next_payment: 'Prozentsatz für das nächste Zahlungsbetrag festlegen',
      create: 'Erstellen',
      doclose: 'Schließen',
      lottery_updated: 'Lottery aktualisiert',
      lottery_created: 'Lottery erstellt',
      award_packages: 'Gewinn-Pakete',
      qty: 'Anzahl',
      multiplier: 'Faktor',
      maxValue: 'Maximalwert',
      limitRepeat: 'Wiederholungsgrenze',
      draw_day: 'Ziehungsdatum',
      hour: 'Uhr',
      minutes: 'Minuten',
      ytb_channel_id: 'Youtube-Kanal-ID',
      tutorial: 'Tutorial',
      video_url: 'Video-URL',
      tutorial_deleted: 'Tutorial gelöscht',
      tutorial_error_delete: 'Tutorial kann nicht gelöscht werden',
      tutorial_updated: 'Tutorial aktualisiert',
      tutorial_error_update: 'Tutorial kann nicht aktualisiert werden',
      language: 'Sprache',
      live_hours: 'Live-Stunden',
      exact: 'genau',
      partiallly: 'Teilweise',
      closed: 'Geschlossen',
      statistics: 'Statistiken',
      your_link2: 'Wetter-Link',
      deb_20_to_games: 'In das Spielguthaben übertragen',
      cred_20_to_games: 'In das Spielguthaben übertragen',
      transf_debit_games: 'Übertragung des Spielguthabens',
      transf_credit_games: 'In das Spielguthaben übertragen',
      file_too_large: 'Datei zu groß. Maximale Größe: {{max}}',
      kyc_warning: 'Führen Sie die Identitätsprüfung durch, um bis zu 100 $ pro Spiel zu setzen',
      msg_rules_footer:
        '1. Dieses Produkt ist für Benutzer über 18 Jahren bestimmt und dient ausschließlich Unterhaltungszwecken.\n 2. Diese Website enthält In-App-Käufe. \n 3. Die Tatsache, dass ein Spieler an einem Social-Wetten-Spiel teilnimmt oder gewinnt, bedeutet nicht, dass er in Zukunft bei Echtgeld-Wetten und verwandten Spielen gewinnen wird.',
      terms_conditions: 'Geschäftsbedingungen',
      aml_policy: 'AML-Richtlinie',
      kyc_policy: 'Richtlinie zur Identitätsprüfung',
      self_exclusion_policy: 'Selbstausschlussrichtlinie',
      responsible_gambling_policy: 'Richtlinie zum verantwortungsvollen Spielen',
      clause_18: 'Richtlinie zum Spielen bei Minderjährigen (Klausel ab 18 Jahren)',
      policies: 'Richtlinien',
      join_us: 'Tritt unserer Gemeinschaft bei',
      max_prize: 'Maximaler Preis: {{maxPrize}}',
      error_weak_password:
        'Ihr Passwort muss mindestens 8 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben, Zahlen und Sonderzeichen enthalten',
      register_error_age: 'Bitte geben Sie Ihre Altersangabe ein',
      months: 'Monate',
      raffels: 'Gewinnspiele',
      tickets: 'Tickets',
      ticket: 'Fahrkarte',
      contest: 'Wettbewerb',
      your_raffels: 'Ihre Gewinnspiele',
      your_tickets: 'Ihre Tickets',
      result_lottery: 'Resultat der Lotterie',
      buy_now: 'Jetzt kaufen',
      generate_link: 'Link generieren',
      mobile_provider: 'Mobile-Provider',
      mobile: 'Mobile',
      bank_transfer: 'Banküberweisung',
      accountNumber: 'Konto',
      ifscCode: 'IFSC',
      swiftCode: 'SWIFT-Code',
      bankName: 'Bank Name',
      documentNumber: 'Dokumentnummer',
      accountHolderName: 'Name des Kontoinhabers',
      accountAgencyNumber: 'Agenturnummer',
      accountTypeCode: 'Kontotypcode',
      payMode: 'Zahlungsart',
      transitNumber: 'Transitnummer',
      financialInstitutionNumber: 'Nummer des Finanzinstituts',
      bankBranchName: 'Agenturname',
      bankBranchCode: 'Agenturcode',
      click_to_open: 'Klicken Sie zum Oeffnen',
      send_proof: 'Senden Sie die Beweisdaten',
      proof_payment_sent: 'Beweisdaten gesendet',
      indicate_a_friend_and_win: 'Empfehlen Sie einen Freund und verdienen Sie mit jeder Aufladung',
      conversion_slim: 'Konvertierung',
      link: 'Link',
      recomendeds: 'Empfohlen',
      all_games: 'Alle Spiele',
      selected_country: 'Selected Country',
      games_of_today: 'Spiele vom Heute',
      you_win: 'Du hast gerade gewonnen!',
      times: 'mal',
      over_your_bet: 'über den Wert Ihrer Wette',
      accumulated: 'Gesamt',
      accumulated_points: 'Gesamtspunkte',
      start: 'Beginnen',
      end: 'Ende',
      new_raffle: 'Neue Verlosung',
      add_raffel_success: 'Neues Gewinnspiel erfolgreich erstellt',
      add_prize_success: 'Neuer Award erfolgreich erstellt',
      edit_prize_success: 'Auszeichnung erfolgreich bearbeitet',
      add_prize_img_success: 'Preisbild erfolgreich erstellt',
      add_order_tickets_success: 'Tickets erfolgreich hinzugefügt',
      edit_raffel_success: 'Gewinnspiel erfolgreich bearbeitet',
      error_orderPayment: 'Fehler bei der Zahlung',
      error_currentOrder: 'Fehler beim Aktualisieren der Bestellung',
      error_editedOrder: 'Fehler beim Bearbeiten der Bestellung',
      partially_paid: 'Teilweise bezahlt',
      add_paid: 'Bezahlte Mitgliedschaft',
      upgrade_paid: 'Kostenpflichtiges Upgrade',
      renew_paid: 'Kostenpflichtige Verlängerung',
      monthly_paid: 'Bezahlte monatliche Gebühr',
      mandala_paid: 'Bezahlte Matrix',
      bet_activated: 'Wette aktiviert',
      add_tickets_raffel: 'Tickets zur Verlosung hinzugefügt',
      order_not_canceled: 'Bestellung nicht storniert',
      cancelOrder: 'Bestellung storniert',
      price_consultant: 'Preise für Berater',
      price_consumer: 'Verbraucherpreis',
      maxTickets: 'Max. Tickets',
      edit_raffle: 'Verlosung bearbeiten',
      prizes: 'Auszeichnungen',
      new_prize: 'Neuer Preis',
      add_prize: 'Preis hinzufügen',
      edit_prize: 'Auszeichnung bearbeiten',
      prize_name: 'Preisname',
      conversion_to_games: 'Umstellung auf Spiele',
      conversion_to_lotterprocoin: 'Umwandlung in Aktivierungen',
      trans_debit_awards: 'Übertragung des Preisguthabens',
      conversion_awards_lotterprocoin: 'Umwandlung des PREISBALANCE in AKTIVIERUNGSBALANCE',
      pay_with_balance_awards: 'Übertragung des Preisguthabens',
      your_minor_side_is: 'Die Minor-Side ist {{side}}',
			promotion_title: 'Promotion LotterPro bringt Sie nach Curacao',
			picture_recomend: 'Rekomendation',
			picture_list: 'Liste',
			picture: 'Bild',
			local_hour: 'Lokale Zeit',
			timezone_hour: 'Zeitzone Zeit',
    },
  },
}
export { messages }
