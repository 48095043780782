import axios from './BaseService'

const GAMES_URL = `${process.env.REACT_APP_API_URL}/games/`

export async function getGames(token, page, limit, status, lottery) {
  const endPoint = `${GAMES_URL}listgames${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { status, lottery, limit }, { headers })
  return response.data
}

export async function getGame(id, token) {
  const endPoint = `${GAMES_URL}getgame/${id}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.get(endPoint, { headers })
  return response.data
}

export async function getBets(token, page, limit, searchUser, status) {
  const endPoint = `${GAMES_URL}listbets${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { status, limit, searchUser }, { headers })
  return response.data
}

export async function getBetsByLotterryId(token, lotteryId) {
  const endPoint = `${GAMES_URL}getbetsbylotterryid/${lotteryId}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.get(endPoint, { headers })
  return response.data
}

export async function sendCloseAndNewGame(data, token) {
  const endPoint = `${GAMES_URL}closeandnewgame`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, data, { headers })
  return response.data
}

export async function sendEditGame(data, token) {
  const endPoint = `${GAMES_URL}sendeditgame`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, data, { headers })
  return response.data
}
